$base-color: #dddddd4a;
$shine-color: #e8e8e863;
$animation-duration: 1.6s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
    background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
    background-size: 600px;
}

.engagement-skeletor-container {
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .top3-container {
        width: 100%;
        display: flex;
        margin-bottom: 2em;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 10px;

        .second {
            width: 90px;
            height: 90px;
            @include background-gradient;
            animation: shine-lines $animation-duration infinite linear;
            border-radius: 1000px;
        }
        .first {
            width: 110px;
            height: 110px;
            @include background-gradient;
            animation: shine-lines $animation-duration infinite linear;
            border-radius: 1000px;
        }
        .third {
            width: 90px;
            height: 90px;
            @include background-gradient;
            animation: shine-lines $animation-duration infinite linear;
            border-radius: 1000px;
        }
    }

    .post {
        height: 80px;
        width: 100%;

        .avatar {
            float: left;
            width: 52px;
            height: 52px;
            background-color: #dddddd4a;
            border-radius: 5000px;
            margin: 8px;

            @include background-gradient;
            animation: shine-avatar $animation-duration infinite linear;
        }
        .line-container {
            display: flex;
            flex-direction: column;
            .line {
                float: left;
                width: 100%;
                height: 16px;
                margin-top: 12px;
                border-radius: 7px;

                @include background-gradient;
                animation: shine-lines $animation-duration infinite linear;
            }
        }

        .avatar + .line {
            margin-top: 11px;
            width: 100px;
        }
        .line ~ .line {
            background-color: #dddddd4a;
        }
    }
    @keyframes shine-lines {
        0% {
            background-position: -100px;
        }

        40%,
        100% {
            background-position: 290px;
        }
    }
    @keyframes shine-avatar {
        0% {
            background-position: -100px + $avatar-offset;
        }
        40%,
        100% {
            background-position: 140px + $avatar-offset;
        }
    }
}
