// Override default variables before the import
$body-bg: #fff;
$liveto-red: #c4281a;
$soft-red: #ee8e88;
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
a,a:hover {
  color: currentColor;
  text-decoration: none;
}
body {
  p {
    margin: 0px;
  }
}
.liveto-button {
  cursor: pointer;
  border: 3px solid;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 50px;
  font-weight: bold;
  padding: 0.5em 1em;
  margin: 0.2em;
  font-weight: 700;
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

.btn-red {
  border-color: $liveto-red;
  background-color: $liveto-red;
  color: #ffffff;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: white;
    color: $liveto-red;
    .btn-arrow {
      left: 12px;
    }
  }
}

.btn-arrow {
  margin-left: 8px;
  left: 8px;
  position: relative;
  transition: left 0.5s ease;
}
