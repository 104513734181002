@import "../chat/invitation.scss";
@import "globals/sass/_mixin.scss";

.paywall-view,
.not-found-view {
    width: 100%;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    .glass-container {
        .liveto-logo {
            width: 250px;
        }
        display: flex;
        flex-direction: column;
        padding: 2rem;
        align-items: center;
        justify-content: space-evenly;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        background: rgba(255, 255, 255, 0.8);
        border-radius: 5px;
        width: 100%;
        max-width: 520px;
        margin: 0 10px;
        min-height: 300px;
        .informational {
            text-align: center;
            .not-started {
                font-size: 25px;
                border-radius: 5px;
                padding: 10px;
            }
            h1 {
                margin: 10px 10px 20px 10px;
            }
            p {
                margin: 10px 0 20px 0;
                .orders-link {
                    font-weight: bold;
                    margin-left: 5px;
                }
                .event-link {
                    font-weight: bold;
                    margin-left: 5px;
                }
            }
            .signout {
                padding: 5px 10px;
                margin: 5px;
                background: #d62b1e;
                color: #fff;
                border: none;
                border-radius: 5px;
                font-weight: bold;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
            }
        }
        .buttons {
            display: flex;
            gap: 0.5rem;
            & > * {
                flex: 0 0 calc(50% - 0.5rem);
            }
            .error-button {
                border: 1px solid $liveto-red;
                padding: 0.2rem 0.8rem;
                border-radius: 50vw;
                white-space: nowrap;
                background: $liveto-red;
                color: $liveto-white;
                &:hover {
                    background: $liveto-white;
                    color: $liveto-red;
                }
            }
        }
        .links {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}
.virtual-event-container {
    width: 100%;
    height: 100%;
    background-color: #fff;
    font-family: "arial";

    .fixed-buttons {
        position: fixed;
        display: flex;
        flex-direction: column;
        z-index: 2;
        gap: 1rem;
        &.mobile,
        &.tablet {
            bottom: 20px;
            right: 20px;
        }
        &.desktop {
            display: none;
        }
        .chat-ribbon {
            //position: fixed;
            display: flex;
            flex-direction: column;
            border: none;
            width: -moz-fit-content;
            width: fit-content;
            background: white;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            padding-right: 3px;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
        }
    }

    .virtual-event-static-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100dvh;
        background-image: url("../../../../static/img/liveto_light_background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .alert-shown {
        height: auto;
    }

    .virtual-event-view-alert {
        position: fixed;
        bottom: 10vh;
        margin: auto;
        width: 100%;
        z-index: 100;

        .notification-inner {
            width: 70%;
            z-index: 100;
        }
    }

    .alert-shown-push {
        transform: translate3d(0vw, 0, 0);
    }
    .alert-hidden-push {
        transform: translate3d(100vw, 0, 0);
    }

    .virtual-event-view-alert-push {
        position: fixed;
        bottom: 10vh;
        margin: auto;
        width: auto;
        max-width: 300px;
        z-index: 11;
        right: 0;
        transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);

        .notification-inner-push {
            width: 100%;
            background-color: beige;
            color: black;
            border-radius: unset;
            font-size: 12px;
            border-color: #ddd;
        }
    }

    .virtual-event-bg {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        transition:
            transform 0.3s ease-in-out,
            opacity 0.3s ease-in-out;
        opacity: 0;
        &.loaded {
            opacity: 1;
        }
    }

    .toggle-chat-visibility {
        position: fixed;
        right: 10px;
        bottom: 10px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: red;
        border: none;
        display: none;
    }
    .virtual-event-view {
        width: 100%;
        max-width: 1700px;
        height: 100%;
        min-height: 100dvh;
        margin: 0 auto;
        padding-top: 80px;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        &.mobile {
            padding-top: 50px;
        }

        .virtual-event {
            height: inherit;
            width: inherit;
            width: 100%;
            display: flex;
            flex-direction: row;

            .virtual-event-content-view {
                background: transparent;
            }
            .virtual-event-content {
                &.desktop {
                    max-width: 1120px;
                    padding: 0 10px 30px 10px;
                }
                &.tablet {
                    padding: 0 10px 10px 10px;
                }
                &.mobile {
                    padding: 0 0 60px 0;
                }
                height: 100%;
                width: 100%;
                background: transparent;
                position: relative;
                .loader-bg {
                    display: flex;
                    height: 500px;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin: 20px;
                }
                .unauthorized-content {
                    width: 100%;
                    height: calc(100dvh - 110px);
                    min-height: 300px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(255, 255, 255, 0.4);
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
                    padding: 10px 20px;
                    text-align: center;
                    h1 {
                        font-size: 100px;
                    }
                    p {
                        max-width: 320px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 10px;
        height: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        width: 0 !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

// Element wrappers
.text-element-wrapper {
    z-index: 2;
    overflow-wrap: break-word;
}
// Carousel shenanigans and overrides
.slick-list {
    height: 100% !important;
    div {
        height: 100% !important;
    }
}

.carousel-item-wrapper {
    width: 100%;
    height: 100%;
}

.custom-carousel-inner {
    height: 100%;
    width: 100%;
}

.drawer-navigator-drawer,
.drawer-navigator-root {
    top: 70px !important;
}
.drawer-navigator-header {
    padding: 1rem;
    margin: 0;
    h2 {
        color: $liveto-red;
        font-size: 24px;
    }
    &.dark,
    &.transparent-dark {
        h2 {
            color: #fff;
        }
        &.mobile {
            background: rgba(0, 0, 0, 1);
        }
    }
    &.dark {
        background: rgba(0, 0, 0, 1);
    }
}
.drawer-navigator-overlay {
    background-color: transparent;
}
.drawer-navigator-drawer {
    &.tablet {
        &.dark,
        &.transparent-dark {
            background-color: rgba(0, 0, 0, 0.8) !important;
        }
    }
    .drawer-navigator-header {
        margin-bottom: unset;
    }
    .virtual-event-navigator {
        border-radius: 0;
        &.mobile,
        &.tablet {
            padding: 1rem;
            position: relative;
            width: 100%;
            top: unset;
            .navigator-content {
                box-shadow: unset;
                position: relative;
                .navigator-link-text {
                    text-overflow: unset !important;
                }
                .scroll-content {
                    &::-webkit-scrollbar {
                        /* Hide scrollbar for Chrome, Safari and Opera */
                        display: none;
                    }
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */
                }
            }
            &.light {
                .swipe-to-close {
                    color: $liveto-red;
                }
            }
        }
        .swipe-to-close {
            border-radius: 50px;
            position: absolute;
            z-index: 2;
            left: 10px;
            top: calc(50% - 70px - 1rem);
            color: #fff;
            height: -moz-fit-content;
            height: fit-content;
            &:hover {
                filter: drop-shadow(2px 2px 5px rgba(0, 255, 0, 1));
            }
        }
    }
}

.drawer-chat-overlay,
.drawer-chat-drawer {
    background-color: transparent;
    box-shadow: unset;
}

.drawer-chat-drawer {
    padding: 0.5rem 0;
    &.mobile {
        padding: 0;
    }
}
