#cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0.9);
    z-index: 100000000;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    justify-content: space-evenly;
    font-size: 12px;
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .accept-cookies {
        background: rgba(0, 170, 0, 0.9);
        margin: 5px;
        color: #fff;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
    }
    .deny-cookies {
        background: transparent;
        border: none;
        color: #fff;
        font-weight: bold;
    }
}
.not-found-view {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .content {
        width: 100%;
        height: 100dvh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #d62b1e;
    }
}

.browser-modal {
    background: rgba(255, 255, 255, 1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .modal-header,
    .modal-footer {
        border: none;
    }

    .browser-modal-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 5px 0;
        p {
            text-align: center;
            margin: 0 0 10px 0;
        }
    }
    code {
        border: solid 1px #ddd;
        color: #000;
        background: #ddd;
        width: 100%;
        text-align: center;
        margin: 10px 0;
        padding: 5px 10px;
        font-weight: bold;
    }
    button {
        background: rgba(0, 170, 0, 0.9);
        padding: 5px 10px;
        border: solid 2px #ddd;
        border-radius: 5px;
        color: #fff;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
    }
}
