@import "react-circular-progressbar/dist/styles.css";
@import "globals/sass/_mixin.scss";

@-moz-document url-prefix() {
  .content-card {
    overflow-y: auto;
  }                  
}

#virtual-event-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 11;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all 0.4s ease-in-out;

    &.dark {
        background: rgba(0, 0, 0, 0.8);
        .event-name,
        .header-button,
        .header-attendees {
            color: #fff;
        }
    }
    &.transparent,
    &.transparent-dark {
        box-shadow: none;
        backdrop-filter: grayscale(1);
    }
    &.transparent {
        background: rgba(255, 255, 255, 0.2);

        .event-name,
        .header-button,
        .header-attendees {
            color: #000;
        }
    }
    &.transparent-dark {
        background: rgba(0, 0, 0, 0.5);

        .event-name,
        .header-button,
        .header-attendees {
            color: #fff;
        }
    }
    &.dark {
    }

    &.tablet,
    &.mobile {
        &.light {
            background: rgb(255, 255, 255);
        }
        &.transparent {
            background: rgba(255, 255, 255, 1);
        }
        &.transparent-dark,
        &.dark {
            background: rgba(0, 0, 0, 1);
        }
    }
    .header-button-text {
        margin: 0 10px;
        font-size: 15px;
    }
    .event-name {
        font-size: 20px;
        font-weight: bold;
        padding-left: 20px;
        &.dark {
            color: #fff;
        }
    }
    .event-image {
        display: none;
        width: 60px;
        height: 60px;
        margin: 5px 0 5px 5px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    #header-online-count {
        background: transparent;
        border: none;
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        outline: none;
        font-weight: bold;
        .dot {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            display: inline-block;
            margin: 0px 10px 0px 17px;
        }
    }

    .personal-calendar-icon-container {
        margin-left: 1rem;
        color: rgb(175, 175, 175);
        cursor: pointer;

        .personal-calendar-icon:hover {
            color: #d62b1e;
        }
    }

    .notification-bell {
        color: rgb(175, 175, 175);
        position: relative;
        cursor: pointer;
        .notification-amount {
            height: 8px;
            width: 8px;
            bottom: 0px;
            right: 0px;
            position: absolute;
            border-radius: 50%;
            background-color: greenyellow;
            border: solid 1px #000;
        }
        &.new {
            color: #d62b1e;
        }
        &:hover {
            color: #d62b1e;
        }
    }

    .user-options {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .user-dropdown {
            position: fixed;
            top: 72px;
            right: 2px;
            height: auto;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
            button {
                border: none;
                background: transparent;
                padding: 10px 15px;
                min-width: 150px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;
            }
        }

        .header-button {
            background: transparent;
            border: none;
            padding: 20px 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            outline: none;
            .calendar-button,
            .polls-button {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .message-notification {
                font-size: 13px;
                border-radius: 50%;
                font-weight: bold;
                margin: 0px 5px;
                padding: 0px;
            }
            .bouncing {
                animation: bounce 5s infinite;
            }

            .header-button-name {
                margin: 0;
            }
            .virtual-profile-icon {
                background-color: #fafafa;
                //border: solid 2px #ddd;
                border-radius: 50%;
                height: 50px;
                width: 50px;
                margin-left: 5px;
                color: #555;
                background-size: cover;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                &.guest-border {
                    border: solid 2px #ddd;
                }

                .engagement-level-icon {
                    position: absolute;
                    background-color: rgb(0, 128, 0);
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    text-align: center;

                    .engagement-level-amount {
                        margin: auto;
                        font-size: 12px;
                        color: #FFF;
                    }
                }
            }

            font-weight: bold;
        }
    }
}
@media screen and (max-width: 1000px) {
    #virtual-event-header {
        .event-image {
            display: flex;
        }
        .event-name {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    #virtual-event-header {
        .event-name {
            font-size: 12px;
        }
        .user-options {
            .header-button {
                padding: 20px 10px;
                .header-button-name {
                    display: none;
                }
                .virtual-profile-icon {
                }
            }
        }
        .header-button-text {
            display: none !important;
        }
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0px);
    }
    5% {
        transform: translateY(-5px);
    }
    10% {
        transform: translateY(0px);
    }
}

@media only screen and (max-width: 600px) {
    #virtual-event-header #header-online-count {
        display: none;
    }
    // React Datepicker -overrides for mobile UX
    .react-datepicker.calendar-settings {
        display: flex;
        flex-direction: column;
        align-items: center;
        .react-datepicker__navigation.react-datepicker__navigation--next.react-datepicker__navigation--next--with-time {
            right: 10px;
        }
        .react-datepicker__header {
            border-top-right-radius: 0.3rem;
        }
        .react-datepicker__time-container {
            border-top: 1px solid #aeaeae;
            border-left: none;
            border-radius: 0 0 0.3rem 0.3rem;
            width: calc(max(85px, 100%));
        }
        .react-datepicker__time-container
            .react-datepicker__time
            .react-datepicker__time-box {
            width: calc(max(85px, 100%));
        }
        .react-datepicker__time-container .react-datepicker__time {
            border-bottom-left-radius: 0.3rem;
        }
    }
}
@media only screen and (max-width: 370px) {
    .settings-inner-container {
        th {
            font-size: 12px;
        }
    }
}

.user-options-modal {
    overflow-y: hidden !important;
}