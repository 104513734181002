.call-view {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 10px;
    z-index: 1000000;
}

.ongoing-call-container {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 1111111;
}

.ongoing-call-controls {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.call-controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.extra-controls.left {
    position: absolute;
    left: 15px;
}
.extra-controls.right {
    position: absolute;
    right: 15px;
}

.ongoing-call-control-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    border: #fff;
    margin: 5px;
    position: relative;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.ongoing-call-control-button-label {
    font-size: 11px;
}

.ongoing-call-control-button.enabled {
    color: green;
}

.ongoing-call-control-button.hangup {
    width: 60px;
    height: 60px;
    color: white;
    border: solid 1px red;
    background-color: red;
}
.ongoing-call-control-button.disabled {
    color: red;
}

.ongoing-call-control-button:hover {
    cursor: pointer;
}
.ongoing-call-control-button:hover .tooltip-text {
    visibility: visible;
}
.tooltip-text {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    top: -30px;
    background-color: #fff;
    border: solid 1px #ddd;
    padding: 2px 5px;
    width: max-content;
    right: 0;
    border-radius: 5px;
    color: #555;
}
.my-screen-share-container {
    border: solid 2px green;
}
.my-video-stream {
}

.calling {
    font-weight: bold;
    margin: 10px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: green;
    flex-direction: column;
}
.calling-emotion {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.calling-text {
    padding: 10px 0 0 0;
}

.calling-icon {
    margin-right: 30px;
}

.call-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    z-index: 1000000000;
    display: flex;
    flex-direction: column;
    z-index: 100000;
    background: rgba(0, 0, 0, .5);

    .calling-text {
        color: #000;
    }
    .incoming-call,
    .call-pending,
    .ongoing-call,
    .rnd-override {
        background: rgba(255, 255, 255, 1);
        color: #000;
    }
    .incoming-call,
    .call-pending {
        box-shadow: 2px 2px 150px rgba(0, 0, 0, 0.8);
        margin: auto;
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        padding: 20px;
        z-index: 1;
        .user-is-busy-info {
          font-size: 20px;
          color: #c4281a;
          font-weight: 700;
        }
    }

    .ongoing-call {
        margin: 5px;
        padding: 5px 10px;
        border-radius: 10px;
        box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.8);
    }
    .rnd-override {
        padding: 10px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        border: solid 1px #ddd;
        color: #000;
    }
}

.call-container.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 0%;
    height: 0%;
}

.caller-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: solid 1px #ddd;
    font-size: 50px;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.caller-information {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.caller-name {
    font-size: 25px;
    font-weight: bold;
}
.caller-is-calling {
    text-align: center;
    padding: 10px 0px;
}
.caller-will-you-accept {
    font-weight: bold;
}
.incoming-call-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.incoming-call-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.incoming-call-button {
    background: transparent;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: 5px;
    color: #fff;
    border: none;
}

.incoming-call-button.accept {
    background-color: green;
}

.incoming-call-button.reject {
    background-color: red;
}

.media-devices {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.media-device-row {
    padding: 5px 0px;
}

.media-device-label {
    font-size: 12px;
}
.media-device-select {
    border: solid 1px #ddd;
    padding: 5px 10px;
    width: 100%;
}

.pending-call-cancel-button {
    background-color: red;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 20px 0px 5px 0px;
    color: #fff;
    border: none;
}

.caller-video-stream {
}

.screen-share-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 0%;
    height: 0%;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.screen-share-video {
    z-index: 1000000;
}
.rnd {
}

.web-cam-container.minimized {
    min-width: 100px;
}

.my-video-stream {
    display: flex;
    flex-direction: column;
}

.video-content {
    position: relative;
}

.web-cam-container.minimized {
    width: 100px;
}

.share-screen {
    padding: 1px;
}

.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: green;
    color: green;
    box-shadow: 9999px 0 0 -5px green;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: green;
    color: green;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px green;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px green;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px green;
    }
    30% {
        box-shadow: 9984px 0 0 2px green;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px green;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px green;
    }
    30% {
        box-shadow: 9999px 0 0 2px green;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px green;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px green;
    }
    30% {
        box-shadow: 10014px 0 0 2px green;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px green;
    }
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}
.caller-is-calling span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}
.caller-is-calling span:nth-child(2) {
    animation-delay: 0.2s;
}
.caller-is-calling span:nth-child(3) {
    animation-delay: 0.4s;
}

