@import "globals/sass/_mixin.scss";

.virtual-event-editor-tree {
    // Element/Component/Room/New Item icons
    z-index: 10000;
    position: fixed;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .footer {
        width: 100%;
        min-height: 70px;
        border-top: 1px solid #bbb;
        display: flex;
        gap: 1em;
        color: #404040;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        z-index: 10001;
        &:hover {
            cursor: pointer;
            .button-container {
                svg {
                    color: #c4281a;
                }
                color: #c4281a;
            }
        }
        .button-container {
            display: flex;
            gap: 1em;
            transition: color 0.2s ease-in-out;
            .templates-save-icon {
                color: $liveto-red;
            }
            svg {
                color: #c4281a;
            }
        }
    }
    .tree-handle {
        width: 100%;
        height: 20px;
        background: #fafafa;
        z-index: 1000;
        position: absolute;
        top: 0px;
        right: 0px;
        &:hover {
            cursor: grab;
        }
        .tree-handle-border {
            width: 98%;
            height: 2px;
            border-top: solid 2px #ddd;
            margin: 3px auto;
        }
    }
    .item-type-icon {
        color: gray;
        display: flex !important;
        margin: 0 0.5em;
        align-items: center;
        z-index: 1;
        &.new-item {
            color: #c4281a;
        }
        /* ~ * {
            position: relative I !important;
        } */
    }
    .delete-dialog-container {
        cursor: default !important;
        border: none;
        border-radius: 3px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        display: inline-flex;
        flex-direction: column;
        background-color: #fff;
        position: absolute;
        right: 3em;
        z-index: $z-top;
        overflow: hidden;
        // Because of react-tree's own styles
        *:not(.delete-button, input) {
            cursor: default !important;
        }
        input {
            cursor: text !important;
            user-select: text !important;
        }
    }
    .delete-dialog-label {
        background-color: $liveto-light-pink;
        color: $liveto-black;
        font-size: 0.75rem;
        padding: 0 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .delete-dialog-close {
            cursor: pointer !important;
            font-size: 1rem;
            color: $liveto-black;
            transition: color 0.2s ease;
            &:hover {
                color: #ddd;
            }
        }
    }
    .delete-dialog-text-container {
        padding: 0.5em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .delete-buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        width: 100%;
        & > * {
            flex: 0 0 calc(50% - 2.5rem);
        }
    }
    .rename-buttons-container {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        gap: 0.5rem;
    }

    .delete-button {
        text-align: center;
        background: #fff;
        color: $liveto-black;
        border: 1px solid $liveto-gray;
        border-radius: 5px;
        padding: 0.25em 1em;
        transition: all 0.2s ease;
        min-width: 70px;
        &:hover,
        &.active {
            background-color: $liveto-light-pink;
        }
    }
    &.dark {
        .delete-dialog-container {
            background-color: $liveto-dark-gray;
        }
        .delete-dialog-close {
            color: $liveto-white;
        }
        .delete-button {
            color: $liveto-white;
        }
        .footer {
            border-top: 1px solid rgb(85, 85, 85);
            color: #a1c0c1;
            &:hover {
                .button-container {
                    color: #00a3a8;
                }
            }
            .button-container {
                svg {
                    color: #00a3a8;
                }
            }
        }
        .delete-button {
            border: solid 2px #00a3a8;
            background: #00a3a8;
        }
        .delete-dialog-label {
            background-color: #00a3a8;
            color: white;
        }
        .tree-handle {
            background: #484848;
            .tree-handle-border {
                border-top: solid 2px rgb(100, 100, 100);
            }
        }
        .item-type-icon {
            color: #00a3a8;
            display: flex !important;
            margin: 0 0.5em;
            align-items: center;
            z-index: 1;
            &.new-item {
                color: green;
            }
        }
    }
}

.virtual-event-editor-tree > * {
    font-family: "poppins-regular", Arial, sans-serif !important;
    transition: color 0.3s ease-in, background 0.3s ease-in;
}

.add-modal-dialog {
    text-align: center;
    display: flex;
    justify-content: center;
    .addModalOverrides {
        //min-height: 50vh;
        height: auto;
        width: 550px;
        border-radius: 20px;
        background-color: #fafafa;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
        overflow: hidden;
        min-height: 450px;

        .add-modal-header {
            background-color: $liveto-light-pink;
            color: #000;
            font-weight: 700;
            padding: 0.5em 1em;
        }
        .add-modal-body {
            overflow: hidden;
            display: flex;
            width: 1100px;
            position: relative;
            transition: transform 0.5s ease-in-out;
            &.page-2 {
                transform: translateX(-50%);
            }
            .add-modal-inner-content {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 2em;
                gap: 1em;
                .nav-links {
                    display: flex;

                    flex-wrap: wrap;
                    gap: 1em;
                    & > * {
                        flex: 0 0 calc(25% - 1em);
                    }
                    .component-link-card {
                        background-color: transparent;
                        width: 100px;
                        height: 100px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;
                        border-radius: 5px;
                        transition: all 0.25s ease-in;
                        font-size: 12px;
                        padding-bottom: 0.5em;
                        &:hover {
                            box-shadow: $liveto-above-all;
                            z-index: 10;
                            cursor: pointer;
                        }
                    }
                }
                .add-modal-description {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                }
            }
        }
    }
}

.modal-header {
    padding: 0.5rem;
}
.modal-body {
    padding: 0px;
}
.modal-back-text {
    cursor: pointer;
    &:hover {
        font-weight: bold;
    }
}
.add-modal-heading {
    font-size: 20px;
    margin: 0px;
    color: rgb(214, 43, 30);
}

.add-modal-inner {
    hr {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
    }
    h4,
    h5 {
        margin-bottom: 0;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        padding: 0.2em;
    }
    i {
        color: #c4281a;
        margin-right: 0.2em;
        display: flex;
        justify-content: center;
    }
    p {
        padding: 0.2em;
    }
    overflow: scroll;
    padding: 1em;
    &::-webkit-scrollbar-thumb {
        background: #c4281a;
    }
    .add-modal-single-category {
        padding: 0.5em 0;
    }
    .category-info {
        display: flex;
        justify-content: flex-start;
        padding: 5px;
        img {
            margin: 0.5em 0.5em 0.5em 0;
        }
        p {
            font-size: 12px;
            font-style: italic;
        }
    }
    .add-modal-category-name {
        margin-top: 2em;
        border-bottom: solid 2px #ddd;
        font-size: 20px;
        font-weight: bold;
    }
    .add-modal-custom-component {
        cursor: pointer;
        border-radius: 5px;
        margin: 0.5em 0;
        padding: 0.2em 0.5em;
        position: relative;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 5px;
        border: solid 2px transparent;

        .component-names {
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            border-bottom: solid 1px #ddd;
            color: rgb(214, 43, 30);
        }

        &:hover {
            border: solid 2px #d62b1e;
        }
    }
    .add-modal-subcategory-name {
        color: gray;
        width: min-content;
        margin-left: 2em;
        display: block;
    }
}
.modal-component-list {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.modal-component-select-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    padding: 20px;
    border-bottom: solid 2px #ddd;
}
.modal-component-selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.modal-component-selector-icon {
    width: 150px;
    min-width: 150px;
    height: 150px;
    border: solid 1px #ddd;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    &.active {
        background-color: #ee8e88;
    }
}

.preview-select-label {
    margin: 0px;
    font-size: 12px;
}
.select-preset {
    width: 200px;
    text-align: right;
    border: solid 2px #ddd;
    border-radius: 5px;
    font-size: 13px;
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.create-component-button {
    background: #c4281a;
    color: #fff;
    border: solid 1px #c4281a;
    font-weight: bold;
    font-size: 12px;
    border-radius: 4px;
    padding: 3px 8px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    &:disabled {
        background-color: #ddd;
        border: solid 1px #ddd;
    }
}
.carousel-input {
    width: 200px;
    margin: 10px 0px;
}

.add-element-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.element-types-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.add-element-button {
    width: 200px;
    margin-top: 40px;
    background-color: #ee8e88;
    border: none;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0px 0px 3px #555;
}
.modal-preset-components {
    height: 100%;
    overflow: hidden;
    padding-top: 10px;
    &.hide {
        height: 0;
    }
}
.modal-preset-component-info {
    display: flex;
    flex-direction: column;
    padding: 1em;
    height: 100%;
}
.component-info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}
.preview-container {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    padding: 10px 15px;
    font-size: 12px;
    background-color: #fff;
    border-radius: 5px;
    border: solid 2px #ddd;
    cursor: default;
}
.modal-preset-preview {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.modal-preset-preview-icon {
    width: 120px;
    min-width: 120px;
    height: 120px;
    background-color: #fff;
    color: rgb(214, 43, 30);
    display: flex;
    justify-content: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    align-items: center;
    border-radius: 10px;
    margin: 0px 10px;
}
.modal-preset-preview-text {
    h3 {
        font-size: 17px;
        color: #c4281a;
        font-weight: bold;
    }
    padding: 0px 10px;
    width: 100%;
}
.preview-text {
    font-size: 13px;
    color: #555;
    &.element {
        font-style: italic;
        max-width: 500px;
    }
}

.modal-presets {
    border-top: solid 2px #ddd;
    display: flex;
    flex-direction: row;
}

.item-types-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
}

.single-item-container {
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-shadow: rgba(0, 0, 0, 0.5);
    &.column {
        flex-direction: column;
        align-items: center;
    }
}

.single-item-text-container {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
}

.create-component-modal-items {
    display: flex;
    justify-content: space-around;
}

.item-type {
    background-color: #fff;
    border-radius: 8px;
    width: 60px;
    min-width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    border: solid 1px #ddd;
    cursor: pointer;
    color: #c4281a;

    &.active {
        border: solid 1px green;
        box-shadow: 2px 2px 5px green;
    }
}

.create-button {
    width: 100%;
    height: 3em;
    border: none;
    margin: 2em 0;
    &:hover {
        background-color: #ee8e88;
    }
}
.component-node-wrapper {
    width: 100%;
    &:hover {
        .element-label-buttons {
            display: flex;
            gap: 0.1rem;
        }
    }
}
.element-node-wrapper {
    width: 100%;
    &:hover {
        .element-label-buttons {
            display: inline-flex;
        }
    }
}
.element-label-buttons {
    display: none;
    flex-direction: row;
    position: absolute;
    right: 1em;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
}

.element-name {
    font-size: 0.75rem;
    padding: 0px 5px;
}
