$liveto-red: #c4281a;

.widget-container {
    z-index: 2;
    border-radius: 5px;
    position: fixed;
    top: 110px;
    right: 310px;
    width: 0px;
    height: 0px;
    .langselect-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
            width: 90%;
            z-index: 100;
        }
    }
    .widget-explorer {
        width: 300px;
        margin: 1em 0em;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 10px 20px;
        border-radius: 2px;
        box-shadow: 2px 2px 10px #131313;
        #rename-container {
            width: 100%;
            padding-top: 15px;
            .rename-input {
                border: solid 2px #ddd;
                padding: 2px 10px;
            }
            .save-rename {
                background: rgb(139, 196, 0);
                border: solid 3px rgb(139, 196, 0);
                color: #fff;
            }
        }
        .toggle-rename {
            position: absolute;
            top: 20px;
            right: 5px;
            background: transparent;
            border: none;
        }
        .component-name {
            font-size: 17px;
            font-weight: bold;
            margin-top: 15px;
            color: #c4281a;
        }
        button.open-widget-button {
            margin: 4px;
            background-color: #fafafa;
            border: none;
            border: solid 1px #fafafa;
            padding: 2px;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
            &.active {
                background-color: #c4281a;
                border: solid 1px #c4281a;
                color: #fff;
            }
        }
        .widget-explorer-content {
        }
        .widget-explorer-properties {
            padding: 20px 5px;
        }
        .widget-explorer-content {
        }
        .widget-explorer-heading {
            font-size: 13px;
            font-weight: bold;
        }
    }
    nav {
        padding: 5px;
        background-color: #fff;
        flex-wrap: nowrap;
        &.nav-tabs .nav-link {
            border: none;
            color: #555;
            border-radius: 5px;

            &.active {
                border: none;
                background-color: green;
                color: #fff !important;
            }
            .tab-content-selector {
                padding: 5px;
            }
        }

        & > * {
            flex-basis: 100%;
            text-align: center;
        }
        a {
            display: flex;
            justify-content: space-evenly;
            padding: 0.2em 0 !important;
            &.active {
                color: #c4281a !important;
            }
        }
    }

    .widget-canvas {
        position: fixed;
        width: 0;
        height: 0;
        top: 110px;
        right: 380px;
    }
    .widget {
        border-radius: 5px;
        background-color: #fff;
        padding: 0;
        width: 300px;
        z-index: 11;
        border-radius: 2px;
        box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
        label {
            display: block;
        }
    }
    .widget-tabs {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .widget-tab-button {
        background: transparent;
        width: 100%;
        border: none;
        color: #555;
        font-weight: bold;
        border-bottom: solid 2px #ddd;
        font-size: 12px;
        &.active {
            border-bottom: solid 2px #c4281a;
            color: #c4281a;
        }
    }
    .widget-close {
        width: 20px;
        height: 20px;
        background: transparent;
        z-index: 1000;
        position: absolute;
        top: 20px;
        right: 5px;
        :hover {
            cursor: pointer;
        }
    }
    .widget-handle {
        width: 100%;
        height: 20px;
        background: #fafafa;
        z-index: 1000;
        position: absolute;
        top: 0px;
        right: 0px;
        &:hover {
            cursor: grab;
        }
        .widget-handle-border {
            width: 98%;
            height: 2px;
            border-top: solid 2px #ddd;
            margin: 3px auto;
        }
    }
    .widget-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1em;
        cursor: pointer;
    }
    .widget-heading-h2{
        width: 100%;
        padding: 10px;
        font-size: 15px;
        font-weight: bold;
        margin-top: 20px;
    }
    .widget-heading-h3 {
        position: relative;
        align-self: flex-start;
        padding: 0.2em 0.8em;
        font-size: 15px;
        font-weight: bold;
        margin-top: 0.4em;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: calc(100% - 0.6em);
            height: 2px;
            background-image: linear-gradient(25deg, transparent, $liveto-red);
        }
    }
    .widget-badge {
        border-radius: 8px;
        border: solid 2px $liveto-red;
        padding: 0.2em 0.8em;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .widget-content-container {
        width: 100%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: none;
        border-bottom: none;
    }
    .widget-heading h5 {
        color: #555;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
    }
    .sketch-picker {
        box-shadow: none !important;
        width: 250px !important;
        box-sizing: border-box !important;
        background: #fdfdfd;
        margin: 0 auto;
    }

    .widget-expander-button {
        background: transparent;
        border: none;
        color: #555;
        outline: none;
        i {
            display: flex;
        }
    }

    .widget-noborder-box {
        cursor: pointer;
        margin: 0.2em;
        width: 35px;
        height: 35px;
        background-color: #fff;
        overflow: hidden;
        position: relative;
        .line1 {
            width: 30px;
            height: 30px;
            border-bottom: 3px solid #555;
            transform: rotate(45deg) translateY(-13px);
            position: absolute;
        }
        .line2 {
            width: 30px;
            height: 30px;
            border-top: 3px solid #555;
            transform: rotate(-45deg) translateY(14px);
            position: absolute;
        }
    }
    .image-url-input {
        width: 100%;
        text-align: right;
        border: solid 2px #ddd;
        border-radius: 5px;
        font-size: 13px;
        background-color: #fafafa;
    }
    .image-width-input {
        width: 50px;
        text-align: right;
        border: solid 2px #ddd;
        border-radius: 5px;
        font-size: 13px;
        background-color: #fafafa;
    }
    .image-select-gallery-button {
        background: green;
        color: #fff;
        border: solid 1px #ddd;
        font-weight: bold;
        font-size: 12px;
        border-radius: 4px;
        padding: 3px 8px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    }
    .image-remove-button {
        background: red;
        color: #fff;
        border: solid 1px #ddd;
        font-weight: bold;
        font-size: 12px;
        border-radius: 4px;
        padding: 3px 8px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    }
    .imageurl-widget {
        padding: 0.5em 0;
        .image-width-controls {
            display: flex;
            flex-direction: column;
            align-items: center;
            input[type="number"] {
                width: 52px;
            }
            input[type="range"] {
                width: 100%;
            }
        }
        .image-widget-preview {
            background-color: #ddd;
            background-position: center;
            width: 100%;
            height: 300px;
            background-size: contain;
            background-repeat: no-repeat;
            border: 1px solid black;
            border-radius: 5px;
        }
    }
    .rename-widget {
        display: flex;
        padding: 10px;
        align-items: flex-end;
        background-color: #fff;
        border-radius: 0px;
        .rename-widget-input-container {
            width: 100%;
            flex-grow: 1;
            .rename-widget-label {
                margin: 0px;
                font-weight: bold;
            }
            .widget-input {
                width: 100%;
                display: block;
                font-weight: bold;
                font-size: 17px;
                border: none;
                background-color: white;
                pointer-events: none;
                padding: 5px 10px;
                border: solid 2px #ddd;
                background-color: #fff;
                &.active {
                    font-weight: bold;
                    pointer-events: initial;
                    border-bottom: solid 2px green;
                    outline: none;
                }
            }
        }
        span {
            padding: 10px;
            flex-shrink: 1;
            cursor: pointer;
            &:hover {
                i {
                    svg {
                        fill: black;
                    }
                }
            }
            i {
                svg {
                    fill: gray;
                }
                &.edit {
                    svg {
                        fill: black;
                    }
                }
            }
        }
    }
    .widget-label {
        font-weight: bold;
        margin: 5px 0px;
        font-size: 12px;
    }
    .widget-select {
        max-width: 100%;
        padding: .5em 1em;
        margin: 0;
        border: 1px solid #fff;
        box-shadow: 0 1px 0 1px rgba(0,0,0,.05);
        border-radius: .5em;
        &:focus {
            border-color: $liveto-red;
            box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
            box-shadow: 0 0 0 3px -moz-mac-focusring;
            color: #222; 
            outline: none;
        }
        &:hover {
            border-color: $liveto-red;
        }
    }
    
    .widget-border-style-select {
        cursor: pointer;
        margin: 0.2em;
        width: 35px;
        height: 35px;
        &.active {
            background-color: rgba(4, 124, 24, 0.5);
        }
    }

    .border-input {
        width: 50px;
        text-align: right;
        border: solid 2px #ddd;
        border-radius: 5px;
        font-size: 13px;
        background-color: #fafafa;
    }
    .border-preset-button {
        background: #fafafa;
        border: solid 1px #ddd;
        font-weight: bold;
        font-size: 12px;
        border-radius: 4px;
        padding: 3px 8px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
        &.active {
            background: #c4281a;
            color: #fff;
            border: solid 1px #c4281a;
        }
    }
    .widget-link-container {
        background-color: #fff;
        width: 300px;
        padding: 15px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: none;
        border-bottom: none;
    }

    .widget-link-select {
        width: 100%;
        padding: 5px 10px;
        border-radius: 5px;
        border: solid 2px #555;
    }
    .widget-link-input {
        width: 100%;
        padding: 5px 10px;
        border-radius: 5px;
        border: solid 2px #555;
    }

    .widget-justify-content-container {
        background-color: #fff;
        width: 300px;
        padding: 15px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-top: none;
        border-bottom: none;
    }
    .widget-justify-button {
        background-color: transparent;
        border: solid 2px transparent;
        margin: 5px;
        color: #555;
        font-weight: bold;
        border-radius: 5px;
        &.active {
            border: solid 2px green;
            color: green;
        }
        &:focus {
            outline: none;
        }
    }
    .widget-flex-direction-container {
        background-color: #fff;
        width: 300px;
        padding: 15px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: none;
        border-bottom: none;
    }
    .widget-flex-direction-button {
        background: #fafafa;
        border: none;
        margin: 10px 5px;
        border-radius: 5px;
        box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);

        &:focus {
            outline: none;
        }
        &.active {
            background-color: #c4281a;
            color: #fff;
            border-radius: 5px;
        }
    }

    .widget-padding-container {
        background-color: #fff;
        width: 300px;
        padding: 30px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: none;
    }
    .widget-row {
        padding: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        &.small {
            font-size: 0.8rem;
        }
        &.column {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
    .widget-select {
        width: 100%;
        text-align: right;
        border: solid 2px #ddd;
        border-radius: 5px;
        font-size: 13px;
        background-color: #fafafa;
    }
    .widget-padding {
        display: grid;
        width: 280px;
        height: 280px;
        border: solid 2px #ddd;
        border-radius: 5px;
        background-color: #fafafa;
        grid-template-rows: 70px 140px 70px;
        grid-template-columns: 70px 140px 70px;
        grid-template-areas:
            "top top top"
            "left center right"
            "bottom bottom bottom";
    }
    .padding-select-all-button {
        background: #fafafa;
        border: solid 1px #ddd;
        font-weight: bold;
        font-size: 12px;
        border-radius: 4px;
        padding: 3px 8px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

        &.active {
            background-color: #c4281a;
            color: #fff;
        }
    }
    .widget-code {
        padding: 2px 5px;
        border: solid 1px #555;
        border-radius: 5px;
        background-color: #ddd;
    }
    .padding-input {
        width: 30px;
        padding: 3px;
        border-radius: 5px;
        border: solid 1px #555;
        text-align: right;
        font-size: 13px;
        margin-right: 3px;

        &:disabled {
            border: solid 1px #ddd;
        }
        &.hidden {
            display: none;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
        -moz-appearance: textfield; /* Firefox */
    }
    .widget-padding-top {
        grid-area: top;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin: 5px;
    }
    .widget-padding-left {
        grid-area: left;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 5px;
    }
    .widget-padding-center {
        grid-area: center;
        border: solid 1px #555;
        background-color: #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .widget-padding-right {
        grid-area: right;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 5px;
    }
    .widget-padding-bottom {
        grid-area: bottom;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: 5px;
    }
    .widget-background-image-properties {
        border: 1px solid gray;
        padding: 0.5em;
        margin-top: 0.5em;
        border-radius: 5px;
        div {
            display: flex;
            flex-flow: row wrap;
            border-bottom: 1px solid gray;
        }
    }
    .widget-background-inputfield {
        width: 100%;
        padding: 5px 10px;
        border: solid 2px #ddd;
        border-radius: 5px;
        background-color: #fafafa;
    }
    .widget-background-buttons {
        display: flex;
    }
    .widget-background-open-gallery {
        margin: 10px;
        border: none;
        padding: 10px;
        background-color: #c4281a;
        color: #fff;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 10px;
        width: 100%;
    }
    .widget-background-remove-image {
        margin: 10px;
        border: none;
        padding: 10px;
        background-color: rgb(209, 162, 7);
        color: #fff;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 10px;
        border: solid 2px #fff;
    }
}