$liveto-red: #c4281a;

.engagement-leaderboard-container {
    height: 100%;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &.tablet,
    &.mobile {
        &.transparent-dark,
        &.dark {
            .engagement-leaderboard-wrapper {
                color: #ddd;
                .me-in-leaderboard {
                    .leaderboard-icon {
                        background-color: rgba(0, 0, 0, 0.3);
                    }
                }
                .my-position {
                    color: #000;
                }
            }
        }
    }

    &.desktop {
        &.transparent-dark,
        &.dark {
            .engagement-leaderboard-wrapper {
                .leaderboard-additional-info-company {
                    color: #ddd;
                }
                .my-position {
                    color: #000;
                }
                .me-in-leaderboard {
                    .leaderboard-icon {
                        background-color: rgba(0, 0, 0, 0.3);
                    }
                }

                color: #fff;
            }
        }
    }

    .engagement-leaderboard-wrapper {
        margin-left: auto;
        margin-right: auto;
        color: black;
        width: 100%;
        height: 100%;

        .engagement-leaderboard-title {
            width: 100%;
            text-align: center;
            font-size: 1.5em;
            font-weight: 700;
            margin-bottom: 0.5rem;
        }

        .leaderboard-xp {
            width: 100%;
        }

        .leaderboard-top3-container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 0 5px;
            gap: 5px;
            align-items: flex-start;

            .leaderboard-top3-position-container {
                text-align: center;
                width: 33.33%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;

                .leaderboard-additional-info-name {
                    margin-top: 5px;
                    font-size: 12px;
                    line-height: 14px;
                }
                .leaderboard-additional-info-company {
                    font-size: 10px;
                }
                .leaderboard-xp {
                    font-size: 14px;
                }

                &.first {
                    width: 40%;
                }

                &.second {
                    margin-top: 20px;
                    width: 30%;
                }

                &.third {
                    margin-top: 20px;
                    width: 30%;
                }

                &.second,
                &.third,
                &.first {
                    padding: 5px;
                    border-radius: 10px;
                }
            }
        }

        .engagement-icon {
            cursor: pointer;
        }

        .engagement-moderator-menu {
            .moderator-menu-header {
                width: 100%;
                padding: 0.5em;
                display: flex;
                align-items: center;
                gap: 0.5em;
                .moderator-user-preview-image {
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: solid 2px #ddd;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-size: cover;
                    background-position: center;
                }
                .moderator-user-name {
                    font-size: 12px;
                    text-align: left;
                }
            }

            .menu-content {
                padding: 0.5em;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                gap: 0.5em;
                align-items: center;
                width: 100%;
                .button-row {
                    display: flex;
                    gap: 0.5em;
                    width: 100%;
                    .engagement-ban-button {
                        background: $liveto-red;
                        border: 1px solid $liveto-red;
                        border-radius: 5px;
                        color: #fff;
                        width: 100%;
                        &:hover {
                            background: #fff;
                            color: $liveto-red;
                            border-color: $liveto-red;
                        }
                    }
                }

                p {
                    font-size: 12px;
                }
            }
        }

        .icon-first-wrapper {
            width: 110px;
            height: 110px;
            padding: 4px;
            border-radius: 1000px;
            background: -webkit-linear-gradient(left top, #ee7752, #e73c7e, gold, #23d5ab);
            background-size: 400% 400%;
            animation: gradient 3s ease infinite;

            @keyframes gradient {
                0% {
                    background-position: 0% 50%;
                }
                50% {
                    background-position: 100% 50%;
                }
                100% {
                    background-position: 0% 50%;
                }
            }
        }

        .leaderboard-person-icon {
            height: 90px;
            width: 90px;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto;
            background-size: cover;
            align-items: center;
            justify-content: center;
            display: flex;
            position: relative;
            font-size: 20px;
            background-color: rgba(255, 255, 255, 0.5);
            color: #000;

            &.icon-first {
                width: 100%;
                height: 100%;
            }

            &:not(.icon-first) {
                border: 2px solid #ddd;
            }
        }

        .leaderboard-position-icon {
            background-color: #555;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            display: flex;
            text-align: center;

            .position {
                margin: auto;
                font-size: 12px;
                color: white;
            }

            &.top3 {
                position: absolute;
                right: -3px;
                bottom: -3px;
            }

            &.top4to10 {
                position: relative;
            }
        }

        .leaderboard-rest-container {
            padding: 0 10px;
            margin-top: 1%;
            font-size: 12px;

            .leaderboard-rest-container-headers {
                display: flex;
                width: 100%;
                padding: 0px 10px;
                .hashtag {
                }
                .user {
                    width: 100%;
                    padding-left: 1.5em;
                }
            }

            .three-dots {
                width: 100%;
                text-align: center;
                font-size: 20;
                font-weight: 700;
                &.desktop {
                    &.dark {
                        color: #fff;
                    }
                }
                &.tablet,
                &.mobile {
                    &.dark,
                    &.transparent-dark {
                        color: #fff;
                    }
                }
            }

            .leaderboard-row {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 2px;
                margin: 3px 0px;
                border-radius: 50px;
                padding-right: 0.8rem;

                &.me-in-leaderboard {
                    background-color: #ddd;
                }

                &:not(.me-in-leaderboard) {
                    .leaderboard-icon {
                        cursor: pointer;
                    }
                }

                .leaderboard-icon {
                    width: 50px;
                    height: 50px;
                    border: 1px solid #ddd;
                    border-radius: 1000px;
                    background-size: cover;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    position: relative;
                    font-size: 20px;
                    background-color: rgba(255, 255, 255, 0.5);
                    color: #000;
                }
                .leaderboard-name-xp-position {
                    display: flex;
                    justify-content: space-between;
                    flex-grow: 4;
                    padding-left: 0.8rem;
                    .leaderboard-name-xp {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .user-name {
                            font-weight: 700;
                            display: flex;
                            .engagement-stealth-indicator {
                                margin: 0 1em;
                                padding: 0 0.5em;
                                background-color: $liveto-red;
                                border-radius: 50px;
                                color: #fff;
                            }
                        }
                    }

                    .leaderboard-position {
                        font-weight: 700;
                        min-height: 100%;
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .leaderboard-top4to10-container {
                //margin-top: 1rem;
            }

            .leaderboard-my-position {
                width: 100%;
                background-color: #ddd;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                padding: 0.5rem 0.8rem;
                margin-top: 0.5rem;
                .position-row {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    font-weight: 700;
                    .position-text {
                    }
                    .position {
                    }
                }

                .xp-row {
                }
            }
        }

        @media only screen and (max-width: 1475px) and (min-width: 1000px) {
            .icon-first-wrapper {
                height: 90px;
                width: 90px;
            }

            .leaderboard-person-icon {
                height: 70px;
                width: 70px;

                &.icon-first {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
