@import "globals/sass/_mixin.scss";

.warning-modal {
    .warning-card {
        background: #FFF;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        .warning-card-header {
            display: flex;
            justify-content: space-between;
            padding: 1rem;
            border-bottom: 1px solid $liveto-medium-light-gray;
            .warning-card-title {
                font-weight: 700;
                color: $liveto-red;
                font-size: $size-250;
            }
            .close-button {
                cursor: pointer;
                height: 100%;
                display: flex;
                align-items: center;
                border: unset;
                background: transparent;
            }
        }
        .warning-card-body {
            padding: 1rem;
        }
        .warning-card-footer {
            display: flex;
            padding: 0.5rem 1rem;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid $liveto-medium-light-gray;
            .info {
                color: $liveto-green;
                font-weight: 700;
                &.error {
                    color: $liveto-red;
                }
            }
            .buttons {
                display: flex;
                gap: 0.5rem;
            }
        }

    }
}