@import "globals/sass/_mixin.scss";

$maxHeight: calc(100dvh - 100px);
$maxHeightInner: calc($maxHeight - 58px - 4rem);

#user-profile {
  .heading {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.225);
    z-index: 1;
  }

  .settings-container {
    display: flex;
    background: $liveto-white;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    margin-top: 10px;
    max-height: $maxHeight;
    height: $maxHeight;
    .profile-settings-container {
      display: flex;
      flex-direction: column;
      .profile {
        padding: 1rem;
        overflow-y: overlay;
        max-height: $maxHeightInner;
      }
    }

    .settings-nav {
      padding: 20px;
      border-right: 1px solid #bbb;
      .profile,
      .calendar,
      .notifications {
        margin-bottom: 0.5em;
        padding: 0.2em 1em;
        &:hover {
          background: $liveto-red;
          color: #fff;
          cursor: pointer;
          border-radius: 5px;
        }
        &.active {
          background: $liveto-red;
          color: #fff;
          cursor: pointer;
          border-radius: 5px;
        }
      }
      > * {
        word-break: keep-all;
      }
    }

    .content {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;

      .add-new-slot-header {
        font-weight: 700;
        margin-top: 1em;
      }

      .content-card {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: #000;
        //padding: 20px;
        min-width: 850px;
        height: 700px;
        //overflow-y: overlay;

        .content-button {
          background: $liveto-red;
          color: #fff;
          border: unset;
          border-radius: 5px;
          padding: 0.3em 1em;
          min-width: 100px;
          &:disabled,
          &[disabled] {
            background: #ddd;
          }
        }
        .settings-inner-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding-bottom: 1rem;
          height: 100%;
          max-height: $maxHeightInner;
          overflow-y: overlay;
          .heading-row {
            margin: 0;
          }
          .settings-info {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            padding: 1rem;
            .excl-mark {
              background-color: $liveto-red;
              color: $liveto-white;
              width: 1.25rem;
              height: 1.25rem;
              border-radius: 50vw;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .footer {
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
            align-items: center;
            .button-row {
              margin: 0;
            }
            .success-message {
              .error {
                color: $liveto-red;
              }
              color: $liveto-green;
              font-weight: 700;
            }
          }
        }

        .settings-user-groups-container {
          .user-groups-container {
            display: flex;
            flex-direction: column;
            gap: 0.5em;
            .user-groups-my-group-row {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              .name-button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 50%;
              }
              .group-description {
                font-size: 12px;
              }
            }
          }
        }

        .heading-row,
        .footer {
          padding: 1rem;
          h5 {
            margin: 0;
          }
        }
        .footer {
          border-top: solid 2px #ddd;
        }

        .heading-row {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          border-bottom: solid 2px #ddd;
        }

        .subheading-row {
          padding-top: 20px;
          h5 {
            font-size: 16px;
            font-weight: bold;
          }
        }

        .input-row-wrapper {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 0 1rem 1rem 1rem;
          .add-available-slot-button {
            border-radius: 50px;
            width: 30px;
            height: 30px;
            background-color: $liveto-red;
            color: #fff;
            border: unset;
            font-size: 20px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              transform: scale(1.1);
              cursor: pointer;
            }
          }
          .settings-calendar-timeslot-form {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .add-new-slot-header {
              margin: 0;
            }
            .slot-datepicker-container {
              margin: 0;
              .time-row {
                display: flex;
                width: 100%;
                align-items: center;
                .info-label {
                  white-space: nowrap;
                }
              }
            }
          }
        }

        .button-row {
          display: flex;
          justify-content: flex-end;
          button {
            border: none;
            background: $liveto-red;
            padding: 5px 10px;
            border-radius: 5px;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
            color: #fff;
            &:disabled {
              background: #ddd;
              box-shadow: none;
            }
          }
        }

        .input-row-column-wrapper {
          table {
            width: 100%;
            tr:nth-child(even):not(.check-all) {
              background-color: $liveto-light-gray;
            }
            td,
            th {
              padding: 5px;
              .label {
                display: flex;
                flex-direction: column;
                .setting-tooltip {
                  font-size: 12px;
                }
              }
            }
            .check-all {
              border-top: 1px solid $liveto-gray;
            }
          }
        }
        .slot-datepicker-container {
          margin-top: 1em;
          display: flex;
          flex-direction: column;
          gap: 1em;
          .warning-msg {
            color: $liveto-red;
            font-weight: 700;
          }
        }

        .input-row {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: flex-start;
          align-items: center;
          padding: 2px 0;
          font-size: 14px;
          .time-row {
            display: flex;
            flex-direction: row;
            flex-grow: 4;
            .time {
              width: auto;
              margin-right: 2em;
            }
            p {
              font-size: 14px;
              padding: 5px 0;
            }
          }
          .info-label {
            padding: 5px 0;
            width: 200px;
            font-size: 14px;
          }
          .edit-input,
          .edit-textarea,
          .edit-select {
            margin: 0px 5px;
            padding: 5px 5px;
            border-radius: 5px;
            border: solid 2px #ddd;
            background: #fdfdfd;
          }
          .edit-input {
            width: 150px;
          }
          .edit-select {
            width: 150px;
          }
          .edit-textarea {
            width: 310px;
          }
        }

        .profile {
          width: 100%;
          display: flex;
          flex-direction: row;
          .profile-image-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            .profile-image-container {
              width: 250px;
              min-width: 250px;
              height: 250px;
              border: solid 2px #ddd;
              background-color: #dfdfdf;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .profile-image {
              width: 200px;
              height: 200px;
              border-radius: 50%;
              background-color: #fff;
              background-size: cover;
              background-repeat: no-repeat;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 80px;
              color: #131313;
              border: solid 2px #ddd;
            }
            .image-upload-button {
              background: transparent;
              border: solid 2px #ddd;
              border-radius: 5px;
              font-weight: bold;
              padding: 5px 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 5px 0;
            }
          }

          .profile-info {
            padding: 0px 20px;
            width: 100%;

            .profile-subheading-row {
              padding-top: 20px;
              h5 {
                font-size: 18px;
                font-weight: bold;
              }
            }
            .profile-row {
              display: flex;
              flex-direction: row;
              width: 100%;
              justify-content: flex-start;
              align-items: center;
              padding: 2px 0;
              font-size: 14px;
              .profile-info-label {
                padding: 5px 0;
                width: 200px;
                font-size: 14px;
              }
              .profile-edit-input,
              .profile-edit-textarea,
              .profile-edit-select {
                margin: 0px 5px;
                padding: 5px 5px;
                border-radius: 5px;
                border: solid 2px #ddd;
                background: #fdfdfd;
              }
              .profile-edit-input {
                width: 150px;
              }
              .profile-edit-select {
                width: 150px;
              }
              .profile-edit-textarea {
                width: 310px;
              }
            }
          }

          .profile-social {
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            .profile-linkedin {
              width: -moz-fit-content;
              width: fit-content;
              height: -moz-fit-content;
              height: fit-content;
              background-color: #fff;
              border-radius: 5px;
            }
            .profile-twitter {
              width: -moz-fit-content;
              width: fit-content;
              height: -moz-fit-content;
              height: fit-content;
              background-color: #fff;
              border-radius: 5px;
              padding: 2px;
            }
          }
        }
        .settings {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          height: 100%;
          padding: 10px;

          .header-toggle {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 10px 5px;
            font-weight: bold;
            .toggle-switch {
              margin: 0px 10px;
            }
          }
        }
      }
    }
  }
  @include responsive(phone) {
    #user-profile-body {
      padding: 0;
      overflow-y: unset;
      height: calc(100dvh - 70px);
      .modal-content-section {
        height: 100%;
      }
    }
    .settings-container {
      border-radius: 0 !important;
      max-height: unset !important;
      margin-top: 0px;
      height: 100%;
      .content {
        width: 90% !important;

        height: 100dvh;
        .content-card {
          height: auto;
          padding-bottom: 5rem;
          .profile-settings-container .profile {
            max-height: calc(100dvh - 70px - 126px);
          }
          .settings-inner-container .text-center th {
            word-break: keep-all;
          }
        }
      }
      .subheading-row {
        padding-top: 0 !important;
      }
      .input-row {
        justify-content: space-between !important;
        font-size: 12px !important;
        .content-button {
          min-width: auto !important;
        }
        p {
          font-size: 12px !important;
        }
        .time {
          margin: 0 !important;
        }
        .time-row {
          flex-grow: unset !important;
        }
      }
      .settings-user-groups-container {
        .user-groups-container {
          font-size: 14px !important;
          width: 100%;
          .user-groups-my-group-row {
            .name-button {
              justify-content: space-between;
              width: 100% !important;
            }
            .group-description {
              display: none;
            }
          }
        }
      }
    }
  }
  @include responsive(laptop) {
    #user-profile-body {
      padding: 0;
      overflow-y: unset;
      height: calc(100dvh - 70px);
      .modal-content-section {
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 860px) {
  #user-profile {
    #user-profile-body {
      padding: 0;
      overflow-y: unset;
      height: calc(100dvh - 70px);
      .modal-content-section {
        height: 100%;
      }
    }
    .settings-container {
      min-width: 100vw;
      .settings-nav-mobile {
        width: 40px;
        min-width: 40px;
        align-items: center;
        overflow-x: hidden;
        .nav-link {
          display: flex;
          justify-content: center;
          &.active {
            background: $liveto-red;
            color: #fff;
          }
        }
        border-right: 1px solid #ddd;
      }
      .content {
        .content-card {
          min-width: 100%;

          .input-row {
            .info-label {
              width: 130px;
            }
            &.datepicker {
              flex-direction: column;
              align-items: flex-start;

              .time {
                width: 100px;
              }
              input {
                width: 100%;
              }
            }
          }

          .profile {
            flex-direction: column;
            .profile-info {
              padding: 5px;
              .profile-row {
                align-items: flex-start;
                flex-direction: column;
                .profile-edit-input,
                .profile-edit-textarea,
                .profile-edit-select {
                  width: 100%;
                  margin: 0 0 10px 0;
                  padding: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
