#performers-list {
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    width: 70%;
    max-width: 600px;
    .performerlist-accordion {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .mantine-Accordion-item {
            border-bottom: unset;
        }
        .mantine-Accordion-control {
            background-color: #f8f9fa;
            border-radius: 5px;
        }
    }
    .performer-card {
        background: #fff;
        padding: 10px;
        margin: 5px;
        display: flex;
        flex-direction: row;
        max-width: 600px;
        .performer-image {
            width: 200px;
            min-width: 200px;
            height: 200px;
            background-color: #ddd;
            border: solid 1px #ddd;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
        .performer-information {
            padding: 5px 10px;
            .performer-link {
                word-break: break-all;
            }
            .performer-name {
                font-weight: bold;
                font-size: 20px;
            }
            .performer-title {
                font-weight: bold;
                font-size: 15px;
                padding: 2px 0;
            }
            .performer-description {
                font-style: italic;
                font-size: 13px;
                margin: 10px 0 0 0;
            }
        }
    }
}
@media screen and (max-width: 600px) {
    #performers-list {
        max-width: 100%;
        width: 100%;
        padding: 0 0.5rem;
        .performer-card {
            flex-direction: column;
            align-items: center;
            text-align: center;
            .performer-image {
                width: 320px;
                height: 320px;
            }
        }
    }
}
