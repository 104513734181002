@import "globals/sass/_mixin.scss";
$liveto-red-transparent: rgba(196, 40, 26, 0.8);
$liveto-above-all:
    11px 5px 34px rgba(0, 0, 0, 0.12),
    -10px 24px 60px rgba(0, 0, 0, 0.12);
:root {
    --tag-background: red;
}

@keyframes slide {
    0% {
        background-position: -500px -500px;
    }
    100% {
        background-position: 0 0;
    }
}
@keyframes poop {
    0% {
        background-position: 0 -500px;
    }
    100% {
        background-position: 0 0px;
    }
}
@keyframes clap {
    0% {
        background-position: -500px 10px;
    }
    100% {
        background-position: 0 10px;
    }
}
@keyframes heart {
    0% {
        background-position: 0px 500px;
    }
    10% {
        background-position-x: 20px;
    }
    20% {
        background-position-x: -60px;
    }
    30% {
        background-position-x: 30px;
    }
    40% {
        background-position-x: -40px;
    }
    50% {
        background-position-x: 20px;
    }
    60% {
        background-position-x: -50px;
    }
    70% {
        background-position-x: 10px;
    }
    80% {
        background-position-x: -60px;
    }

    100% {
        background-position: 0 0;
    }
}
.join-room-modal {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    &.dark {
        background: rgba(0, 0, 0, 0.8);
    }

    .heading {
        font-size: 20px;
        margin: 0;
    }
    .content {
        padding: 20px;
        .join-room-label {
            margin: 0;
        }
        .room-password-input {
            display: flex;
            flex-direction: row;
            align-items: center;

            .room-join-input {
                width: 200px;
                border: solid 1px #ddd;
                height: 25px;
                border-radius: 2px;
                padding: 2px 5px;
                font-size: 12px;
                background: rgba(255, 255, 255, 0.7);
            }
            .room-join-button {
                background: transparent;
                border: none;
                font-weight: bold;
            }
        }
    }
}

#liveto-chat {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    height: calc(100dvh - 90px);
    width: 380px;
    min-width: 380px;
    position: sticky;
    top: 80px;
    border-radius: 1rem;
    transition: all 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    overflow-x: hidden;
    .chat-message-timeout-progressbar-wrapper {
        width: 100%;
        height: 3px;
        position: relative;
        transform: scaleX(1);

        background: $liveto-red;
        opacity: 0;
        transform-origin: left;
        &.scaled {
            transform: scaleX(0);
            opacity: 1;
            transition: transform var(--message-cooldown) linear;
        }
    }
    .chat-heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px 5px;
        text-align: center;
        h2 {
            font-size: 17px;
            font-weight: bold;
            margin: 0px;
        }
        p {
            font-size: 15px;
            font-style: italic;
        }
        label {
            display: flex;
            gap: 0.5rem;
        }
    }
    &.dark {
        .reply-message-container {
            border-color: $liveto-text-gray !important;
        }
    }
    &.dark,
    &.transparent-dark {
        .profile-icon {
            background-color: rgba(255, 255, 255, 0.65);
            color: #000;
        }
        .send-message {
            .send-input {
                color: $liveto-white;
            }
        }
    }
    &.desktop {
        right: 0;
        &.transparent-dark {
            background: rgba(0, 0, 0, 0.5);
        }
    }
    &.tablet {
        top: unset;
        margin: 0;
        width: 97%;
        &.transparent,
        &.light {
            background: rgb(255, 255, 255);
            .swipe-to-close {
                color: $liveto-red;
            }
        }
        .swipe-to-close {
            border-radius: 50px;
            position: absolute;
            z-index: 2;
            right: 10px;
            top: 50%;
            color: #fff;
            height: -moz-fit-content;
            height: fit-content;
            &:hover {
                filter: drop-shadow(2px 2px 5px rgba(0, 255, 0, 1));
            }
        }
        &.transparent {
            background: rgba(255, 255, 255, 1);
            border: none;
        }
        &.transparent-dark,
        &.dark {
            background: rgba(0, 0, 0, 0.8) !important;
        }
    }
    &.light {
        .moderator-message-container {
            border-color: #bbb !important;
        }
    }
    &.mobile {
        width: 100%;
        min-width: 320px;
        height: calc(100dvh - 70px);
        margin-top: 70px;
        border-radius: 0;
        .inbox .user-profile {
            .profile-info-container {
                padding: 0.4em;
                .profile-info {
                    padding: 0 5px;
                    .profile-icon {
                        width: 40px;
                        min-width: 40px;
                        max-height: 40px;
                        font-size: 1em;
                        .status-indicator {
                            width: 8px;
                            height: 8px;
                        }
                    }
                    .profile-data {
                        .profile-name {
                            font-size: 1rem;
                        }
                        .profile-title {
                            font-size: 0.8rem;
                        }
                        .profile-company {
                            font-size: 0.6rem;
                        }
                    }
                }
                .profile-description {
                    font-size: 0.6rem;
                    padding: 6px 8px;
                }
                .profile-socials {
                    padding: 6px;
                }
            }
            .send {
                .send-message {
                    padding: 2px;
                    height: 5.5em;
                }
            }
            .close-button {
                top: 10px;
                right: 10px;
            }
        }

        .chat-heading {
            padding: 8px;
        }
        .chat-tabs {
            margin-bottom: 0;
            .chat-tab-button {
                min-height: 36px;
            }
        }

        &.transparent,
        &.light {
            background: rgb(255, 255, 255);
            .swipe-to-close {
                color: $liveto-red;
            }
        }
        &.dark,
        &.transparent-dark {
            .attendees-filter-button {
                color: #fff !important;
            }
            .filter-user-group-tag {
                border-color: #bbb !important;
            }
        }
        .swipe-to-close {
            border-radius: 50px;
            position: absolute;
            z-index: 2;
            right: 4px;
            top: 50%;
            color: #fff;
            height: -moz-fit-content;
            height: fit-content;
            &:hover {
                filter: drop-shadow(2px 2px 5px rgba(0, 255, 0, 1));
            }
        }
        &.transparent {
            background: rgba(255, 255, 255, 1);
            border: none;
        }
        &.show {
            //right: 0px;
        }
    }
    .send-message {
        padding: 5px 10px 3px 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1;
        position: relative;
        &.expanded {
            padding-top: 0;
        }
        .moderator-message-container,
        .anonymous-message-container {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem 1rem;
            h3 {
                font-size: 1rem;

                margin: 0;
                font-weight: 700;
            }
        }
        .moderator-message-container {
            background-color: $liveto-red;
            color: #fff;
            border-left: 5px solid #fff;
        }
        .anonymous-message-container {
            background-color: #000;
            color: #ddd;
            border-left: 5px solid #ddd;
        }
        .restricted {
            position: absolute;
            inset: 0;
            z-index: 1;
            background: rgba(#fff, 0.95);
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: solid 1px #ccc;
        }
        .reply-message-container {
            width: 100%;
            background-color: rgb(235, 235, 235);
            border-left: 5px solid black;
            padding: 0.5rem;
            font-size: 12px;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .message-container {
                display: flex;
                flex-direction: column;
                .sender {
                    font-weight: 700;
                }
                .message {
                    word-break: break-word;
                }
            }
            .unquote-button {
                cursor: pointer;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
        .send-input {
            width: 100%;
            background: transparent;
            border: solid 2px #ddd;
            padding: 5px 15px;
            resize: none;
            border-radius: 20px;
            font-size: 16px;
            &:active,
            &:focus {
                outline: none;
            }
            &::-webkit-scrollbar {
                width: 4px;
            }
            &.quoted {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-top: none;
            }
        }
        @keyframes roundtime {
            to {
                /* More performant than animating `width` */
                transform: scaleX(0);
            }
        }

        .send-message-actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0px;
            &.flex-end {
                justify-content: flex-end;
            }
            .togglers {
                width: 100%;
                display: flex;
                height: 100%;
                align-items: center;
                gap: 1rem;
                padding: 0 0.5rem;

                label {
                    display: flex;
                    flex-direction: row;
                    margin: 0px;
                    font-size: 13px;
                    height: 100%;
                    align-items: center;
                    span {
                        .mantine-Tooltip-root {
                            color: $liveto-black;
                        }
                    }

                    input {
                        padding: 2px;
                        margin: 3px;
                    }
                }
            }
            .settings-button {
                background: transparent;
                border: none;
                padding: 0;
            }
            .button-containder {
                display: flex;
                gap: 0.5rem;
                .char-counter {
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                }
                .send-message-button {
                    background: $liveto-pink;
                    border: none;
                    border-radius: 50vw;
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
                    font-weight: bold;
                }
            }
        }
    }

    .chat-tabs {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 0px 5px;
        margin-bottom: 10px;
        .chat-tab-button {
            background: transparent;
            border: none;
            padding: 0 2px;
            border-bottom: solid 2px #fff;
            width: 100%;
            font-size: 10px;
            font-weight: 100;
            min-height: 50px;
            &.active {
                border-bottom: solid 2px #d62b1e;
                color: #d62b1e;
            }
            &:disabled {
                color: #ddd !important;
                border-bottom: transparent !important;
            }
        }
    }
    .chat-reconnect-screen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.9);
        gap: 10px;
        &.dark {
            background: rgba(0, 0, 0, 0.5);
        }
        z-index: 2;
    }
    .chat-loading-screen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .inbox {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 4px;
        }
        padding: 10px;
        .inbox-heading {
            font-size: 20px;
            font-weight: bold;
            color: #000;
        }
        .user-profile {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 100%;
            .close-button {
                position: absolute;
                top: 0px;
                right: 5px;
                padding: 0;
                border: none;
                background: transparent;
            }

            .chat-history {
                height: 100%;
                border-top: solid 2px #ddd;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 4px;
                }
                ::-webkit-scrollbar-track {
                    width: 1px;
                }
                .date-divider {
                    padding: 10px 25px;
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .date-divider-content {
                        font-weight: bold;
                        font-size: 12px;
                        padding: 5px 0px;
                        display: flex;
                        justify-content: center;
                    }
                    .date-divider-border {
                        width: 100%;
                        border-bottom: solid 1px #555;
                    }
                }
                .message {
                    display: flex;
                    width: 100%;
                    padding: 5px;
                    flex-direction: column;
                    .sender {
                        padding: 5px 0 0 0;
                        font-size: 10px;
                        text-align: left;
                        display: flex;
                        justify-content: space-between;
                    }
                    &.my-message {
                        align-items: flex-end;
                        .message-content {
                            background: rgba(0, 255, 255, 0.5);
                            color: #000;
                        }
                    }
                    &.other-message {
                        align-items: flex-start;
                        .message-content {
                            background: rgba(255, 0, 0, 0.6);
                            color: #fafafa;
                        }
                    }
                    .message-content {
                        width: -moz-fit-content;
                        width: fit-content;
                        max-width: 80%;
                        min-width: 30%;
                        border-radius: 2px;
                        padding: 5px 10px;
                        font-size: 14px;
                        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
                        word-break: break-word;
                        .timestamp {
                            font-size: 9px;
                            text-align: right;
                        }
                        .quoted-msg-container {
                            font-size: 12px;
                            .quoted-removed {
                                color: $liveto-red;
                            }
                        }
                    }
                }

                .no-private-messages {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    padding: 20px;
                    text-align: center;
                    h1 {
                        font-size: 15px;
                        font-weight: bold;
                    }
                    i {
                        font-size: 13px;
                    }
                }
            }
            .profile-info {
                padding: 0 10px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .profile-icon {
                    width: 70px;
                    min-width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border: solid 2px #ddd;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.5em;

                    .status-indicator {
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        background-color: #007d00;
                    }

                    &.offline {
                        filter: grayscale(100%);
                        .status-indicator {
                            background-color: transparent;
                            border: 3px solid rgb(128, 128, 128);
                        }
                    }
                }
                .profile-data {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 0 10px;
                    .profile-name {
                        font-size: 17px;
                        font-weight: bold;
                        margin: 0px;
                    }
                    .profile-title {
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0px;
                    }
                    .profile-company {
                        font-size: 13px;
                        margin: 0px;
                    }
                }
            }
            .profile-socials {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                padding: 10px 10px 20px 10px;
                .link {
                    font-size: 12px;
                    border: unset;
                    background-color: transparent;
                    .twitter-icon {
                        color: #00acee;
                        margin-right: 2px;
                    }
                    .linkedin-icon {
                        color: #0e76a8;
                        margin-right: 2px;
                    }
                    .website-icon {
                        color: #d62b1e;
                        margin-right: 2px;
                    }
                    .interact-icon {
                        color: $liveto-red;
                        cursor: pointer;
                        &:hover {
                            color: black;
                        }
                    }
                    &.disabled {
                        .interact-icon {
                            color: rgb(163, 163, 163);
                            cursor: unset;
                        }
                    }
                }
            }
            .profile-description {
                text-align: center;
                font-style: italic;
                font-size: 12px;
                padding: 5px 10px 10px 10px;
            }
            .call-button {
                background: #fff;
                border: none;
                padding: 5px 10px;
                margin: 5px 0px;
                border-radius: 5px;
                color: #d62b1e;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
                min-height: fit-content;
                &:hover {
                    color: #fff;
                    background: #d62b1e;
                }
                &:disabled {
                    display: none;
                }
            }
        }

        .close-inbox {
            background: transparent;
            border: none;
            padding: 0px;
            position: absolute;
            top: 2px;
            right: 5px;
        }
        .inbox-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 0px 15px 0px;
            &.space-between {
                justify-content: space-between;
            }
            &.no-padding {
                padding: 0px;
            }
            &:hover {
                cursor: pointer;
            }
            .preview-icon {
                width: 40px;
                min-width: 40px;
                height: 40px;
                border-radius: 50%;
                background-size: contain;
                background-color: rgba(255, 255, 255, 0.5);
                border: solid 2px #ddd;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                background-size: cover;
            }
            .preview-message-content {
                display: flex;
                flex-direction: column;
                width: calc(100% - 40px);
                padding: 0px 5px;
                justify-content: flex-start;
                .sender-name {
                    font-size: 15px;
                    font-weight: bold;
                    margin: 0px;
                }
                .sender-message-stripped {
                    font-size: 12px;
                    margin: 0px;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .notification-dot {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: rgba(0, 255, 0, 1);
            }
        }
    }

    .chat-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        .message-moderator-menu {
            position: absolute;
            left: 30px !important;
            margin-top: -80px;
            border-radius: 0px;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
            font-size: 12px;
            padding: 0px;

            .moderator-user-preview {
                display: flex;
                flex-direction: row;
                padding: 4px 4px 0px 4px;
                .moderator-user-preview-image {
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: solid 2px #ddd;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-size: cover;
                    background-position: center;
                }
                .moderator-user-preview-info {
                    padding: 0 5px;
                }
            }
            .muting-user {
                .mute-options {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 2px;
                    .mute-option {
                        background: transparent;
                        border: solid 2px #ddd;
                        border-radius: 5px;
                        color: #555;
                    }
                }
            }
            .react-contexify__item.muting {
                &:hover {
                    .react-contexify__item__content {
                        background-color: transparent;
                        color: #555;
                    }
                }
            }
        }
        .scroll-to-bottom-button {
            position: absolute;
            right: -50px;
            bottom: 120px;
            width: 35px;
            height: 35px;
            border-radius: 5px;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
            background-color: rgba(255, 255, 255, 1);
            border: none;
            visibility: hidden;
            transition: right 250ms ease-in-out;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            &.show {
                right: 10px;
                visibility: visible;
            }
        }

        .chatfeed-show-new-messages {
            background-color: $liveto-red;
            color: #fff;
            position: absolute;
            bottom: 122px;
            right: 50%;
            width: fit-content;
            z-index: 1;
            color: #fff;
            border-radius: 100px;
            border: 1px solid $liveto-red;
            padding: 5px 20px;
            font-size: 12px;
            opacity: 0;
            transform: translate(50%, 30%);
            transition:
                opacity 300ms ease-in,
                transform 300ms ease-in;
            pointer-events: none;
            &.show {
                pointer-events: initial;
                opacity: 1;
                transform: translate(50%, 0%);
            }
        }

        flex: 1;
        .chat-history {
            padding: 5px;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            position: relative;
            width: 100%;
            height: 100%;
            &::-webkit-scrollbar {
                width: 0px;
                display: none;
            }
            ::-webkit-scrollbar-track {
                width: 1px;
            }
            .fetch-more-messages {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .chatfeed-load-more-messages-button {
                    background-color: $liveto-red;
                    color: #fff;
                    border-radius: 100px;
                    border: 1px solid $liveto-red;
                    padding: 5px 20px;
                    font-size: 12px;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        border: 1px solid $liveto-red;
                        color: $liveto-red;
                        background-color: #fff;
                    }
                }
            }

            .chat-react-button {
                font-size: 16px;
            }
            .chat-message,
            .chat-message-continued {
                display: flex;
                flex-direction: row;
                min-height: fit-content;
            }

            .chat-message-continued {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px 10px 0px 10px;
                + .message-moderator-menu {
                    margin-top: 1.5em;
                }
            }

            .chat-message-continued:hover .message-timestamp {
                visibility: visible;
            }
            .chat-message {
                padding: 15px 10px 0;
                &:hover {
                    .message-info-timestamp {
                        display: flex !important;
                    }
                }
            }

            .message-timestamp,
            .moderator-icon,
            .message-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                min-width: 40px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 25px;
                font-weight: bold;
                margin-right: 10px;
                max-height: 40px;
            }
            .message-timestamp {
                font-size: 12px;
                visibility: hidden;
            }
            .moderator-icon {
                font-size: 20px;
                color: #000;
            }
            .message-icon {
                font-size: 15px;
                color: #000;
                border: solid 1px #ddd;
                margin-right: 10px;
                background-color: rgba(255, 255, 255, 0.5);
                .crown {
                    position: absolute;
                    top: -20px;
                }
            }
            @keyframes fadeIn {
                to {
                    opacity: 1;
                }
            }
            .message-content {
                flex-grow: 1;
                color: #000;
                &.held {
                    .message-wrapper {
                        .message-content-message {
                            color: $liveto-medium-gray;
                        }
                        .held-msg-info {
                            font-size: 11px;
                            color: $liveto-red;
                        }
                    }
                }
                &.quoted {
                    .message-wrapper {
                        padding: 0.5rem;
                        border-radius: 10px;
                        margin-bottom: 8px;
                        background: rgba(150, 150, 150, 0.2);
                        .message-content-message {
                            margin-bottom: 0;
                        }
                        &.reactions {
                            padding-bottom: 0;
                        }
                        .quoted-removed {
                            color: $liveto-red;
                        }
                    }
                }

                .quoted-msg-container {
                    font-size: 12px;
                    background-color: #fff;
                    border-left: 5px solid $liveto-dark-gray;
                    border-radius: 10px;
                    padding: 0.5rem;
                    border-bottom-right-radius: 0;
                    .sender {
                        font-weight: 700;
                    }
                    .message {
                        word-break: break-word;
                        .quoted-removed {
                            color: $liveto-red;
                        }
                    }
                }
                .chat-message-buttons {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    right: 0;
                    top: 0px;
                    font-weight: bold;
                    padding: 0;
                    color: red;
                    z-index: 10;
                    opacity: 0;
                    animation: fadeIn 350ms ease forwards;
                    gap: 0.5em;
                    .reaction-button {
                        background: rgb(249, 249, 249);
                        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
                        border-radius: 50px;
                        border: solid 1px #ddd;
                        z-index: 10;
                        display: flex;
                        height: 1.8em;
                        width: 1.8em;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        &.emojis {
                            .reactions-button-icon {
                                transform: rotate(0deg) scale(1);
                                transition: transform 200ms linear;
                                &.close-reactions {
                                    transform: rotate(180deg) scale(1);
                                }
                            }
                        }
                    }
                }
                .reaction-menu {
                    @keyframes popup {
                        from {
                            transform: scale(0);
                        }
                        to {
                            transform: scale(1);
                        }
                    }
                    position: absolute;
                    right: 1em;
                    bottom: 1em;
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(38px, 1fr));
                    grid-gap: 0.2px;
                    max-width: 210px;
                    padding: 0.2em;
                    margin-bottom: 0.4em;
                    border: solid 1px #ddd;
                    border-radius: 5px;
                    background: rgb(249, 249, 249);
                    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
                    animation: popup 250ms linear;
                    transform-origin: bottom right;
                    &.open-down {
                        bottom: auto;
                        top: 1em;
                        transform-origin: top right;
                    }
                    & > span {
                        flex: 1 1 auto;
                        max-width: 42px;
                        cursor: pointer;
                        background-color: transparent;
                        padding: 4px;
                        & > span {
                            position: relative;
                            & > img {
                                -webkit-backface-visibility: hidden;
                                -ms-transform: translateZ(0); /* IE 9 */
                                -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
                                transform: translateZ(0);
                                transform: scale(0.8);
                                transition: transform 250ms ease;
                                width: 32px !important;
                                height: 32px !important;
                                margin: 0 !important;
                                vertical-align: 0;
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: radial-gradient(rgba(0, 0, 0, 0.15) 0, transparent 90%);
                                opacity: 0;
                                transition: opacity 250ms ease;
                            }
                        }
                        &:hover {
                            background-color: rgba(100, 226, 226, 0.2);
                            & > span {
                                &::before {
                                    opacity: 0.8;
                                }
                                & > img {
                                    transform: scale(1);
                                }
                            }
                        }
                    }

                    .open-reactions-button {
                        background: transparent;
                        border-radius: 50%;
                    }
                }
            }

            .message-content-moderator {
                flex-grow: 1;
                color: #4c4c4c;
                flex-grow: 1;
                background: rgba(255, 255, 255, 0.4);
                padding: 10px;
                border-radius: 5px;
                box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.5);
                .quoted-msg-container {
                    font-size: 12px;
                    background-color: #ddd;
                    border-left: 5px solid #000;
                    border-radius: 10px;
                    padding: 0.5rem;
                    border-bottom-right-radius: 0;
                    .sender {
                        font-weight: 700;
                    }
                    .quoted-removed {
                        color: $liveto-red;
                    }
                }
                .quoted {
                    margin-left: 1rem;
                    margin-top: 0;
                    margin-bottom: 0.5rem;
                    padding: 0.2rem 0.5rem;
                }
            }

            .message-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .message-content-name {
                font-size: 16px;
                font-weight: bold;
                word-break: break-word;
            }

            .message-info-timestamp {
                font-size: 12px;
                display: none;
            }
            .message-content-message {
                font-size: 13px;
                word-break: break-word;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                background-color: transparent;
                transition: background-color 200ms linear;
                margin-top: 4px;
                &.hilight {
                    background-color: rgba(0, 0, 0, 0.2);
                }
                &.quoted {
                    margin-left: 1rem;
                    margin-top: 0;
                    margin-bottom: 0.5rem;
                    padding: 0.2rem 0.5rem;
                    border-top: unset;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
                .open-reactions-icon {
                    font-size: 20px;
                    margin: 0px;
                    padding: 0px;
                }
                &.boosted {
                    margin: 2px 0;
                    color: #000;
                    font-size: 15px !important;
                    font-weight: bold;
                    padding: 3px 5px;
                    background: rgba(255, 255, 255, 0.5);
                    border-radius: 5px;
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
                    &.heart {
                        background-size: 100px;
                        background-image: url("../../../../static/img/heart_background.png");
                        animation: heart 30s ease-in-out infinite;
                    }
                    &.rofl {
                        background-size: 300px;
                        background-image: url("../../../../static/img/laughcry_background.png");
                        animation: slide 30s linear infinite;
                    }
                    &.poop {
                        background-size: 200px;
                        background-image: url("../../../../static/img/poop_background.png");
                        animation: poop 30s linear infinite;
                    }
                    &.clap {
                        &:after {
                            position: absolute;
                            content: "";
                            width: 100%;
                            height: 100%;
                            top: 0%;
                            left: 0%;
                            background-size: 100px;
                            background-image: url("../../../../static/img/clap_faster.gif");
                            opacity: 0.5;
                            z-index: -1;
                        }
                    }
                }
                .open-reactions {
                    position: absolute;
                    top: 0px;
                    right: 0;
                    width: -moz-fit-content;
                    width: fit-content;
                    background: rgba(255, 255, 255, 0);
                    border-radius: 10px;
                    font-size: 11px;
                    padding: 0px;

                    .emoji-reactions {
                        background-color: rgba(255, 255, 255, 1);
                        padding: 5px 5px;
                        border-radius: 10px;
                        z-index: 2;
                    }
                }
            }
            .message-content-message-moderator {
                font-size: 13px;
                word-break: break-word;
                font-weight: bold;
            }

            .liveto-chat-emoji {
                font-size: 30px;
            }
            .liveto-chat-emoji-moderator {
                font-weight: bold;
            }

            .moderator-reason-p {
                margin: 0;
                font-weight: bold;
            }

            .message-link {
                font-weight: bold;
                color: #d62b1e;
                text-decoration: none;
            }
            @keyframes bounce {
                0% {
                    transform: scale(0.4);
                }
                40% {
                    transform: scale(1.2);
                }
                100% {
                    transform: scale(1);
                }
            }
            .message-reactions-emoji {
                animation-name: bounce;
                animation-duration: 400ms;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
            }
            .message-link.no-click {
                pointer-events: none;
            }
            .message-reactions {
                display: flex;
                flex-direction: row;
                padding: 2px 0 2px 0px;
                flex-wrap: wrap;
            }
            .reaction-pill {
                background: rgba(255, 255, 255, 0.2);
                width: min-content;
                border-radius: 10px;
                white-space: nowrap;
                padding: 0px 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 20px;
                margin: 2px;
                &.reacted {
                    background-color: rgba(100, 226, 226, 0.2);
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(100, 226, 226, 0.3);
                    }
                }
                .reaction-amount {
                    font-size: 10px;
                    animation: smallBounce 200ms ease;
                }
            }
        }
    }
    @keyframes smallBounce {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }

    .attendees-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 10px;
        overflow-y: auto;
        .attendees-no-search-results {
            text-align: center;
            margin-top: 1em;
            font-weight: 700;
        }
        &::-webkit-scrollbar {
            width: 4px;
        }
        &.mobile {
            .attendees-filter {
                margin-top: 0.5em;
                .attendees-filter-button {
                    display: flex;
                }
                .attendees-find-container {
                    transform: scaleY(0);
                    transition:
                        transform 0.1s linear,
                        opacity 0.1s linear;
                    transform-origin: top;
                    height: 0;
                    opacity: 0;
                    &.expanded {
                        transform: scaleY(1);
                        opacity: 1;
                        height: 100%;
                    }
                }
            }
        }
        .attendees-filter {
            .attendees-filter-button {
                border: 1px solid #bbb;
                background: transparent;
                color: #000;
                padding: 0.1em 0.5em;
                border-radius: 5px;
                margin: 0;
                display: none;
                font-weight: 700;
            }
            .attendees-find-container {
                display: flex;
                flex-direction: column;
                margin: 10px 0px;
                gap: 0.5em;

                .filter-group {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    .filter-by-user-group-container {
                        font-size: 12px;
                        display: flex;
                        gap: 0.5em;
                        flex-direction: row;
                        flex-wrap: wrap;
                        .filter-user-group-tag {
                            background: #fff;
                            padding: 0.1em 0.5em;
                            border-radius: 100px;
                            color: #000;
                            border: 1px solid #000;
                            cursor: pointer;
                            &:hover {
                                transform: scale(1.05);
                            }
                            &.selected {
                                background: var(--filter-tag-bg);
                                color: var(--filter-tag-color);
                                box-shadow: inset 0 0 0 1px #000;
                            }
                        }
                    }
                    .attendees-find-label {
                        margin: 0;
                        font-size: 0.75rem;
                    }
                }
            }
        }

        .attendees-heading {
            font-size: 20px;
            font-weight: bold;
        }
        .attendee {
            display: flex;
            flex-direction: row;
            padding: 10px 0px;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .attendee-user-group-tags {
                display: flex;
                font-size: 10px;
                gap: 0.5em;
                flex-wrap: wrap;
                min-width: 30%;
                justify-content: center;
                .group-tag {
                    padding: 0.1em 0.5em;
                    color: var(--tag-color);
                    border-radius: 50px;
                    background: var(--tag-background);
                    white-space: nowrap;
                }
            }

            .attendee-icon-wrapper {
                position: relative;
                .attendee-icon {
                    width: 50px;
                    min-width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-size: contain;
                    background-position: center;
                    background-color: rgba(255, 255, 255, 0.5);
                    border: solid 2px #ddd;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                    background-repeat: no-repeat;
                    background-size: cover;
                    &.offline {
                        filter: grayscale(100%);
                    }
                }
                .online-offline-icon {
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    right: 0px;
                    bottom: 0px;
                    border-radius: 10px;

                    &.online {
                        background-color: rgb(0, 125, 0);
                    }
                    &.offline {
                        background-color: transparent;
                        border: 3px solid rgb(128, 128, 128);
                    }
                }
            }

            .attendee-info {
                padding: 0px 10px;
                width: 100%;
                display: flex;
                flex-direction: column;

                .attendee-name {
                    font-size: 15px;
                    font-weight: bold;
                }
                .attendee-title {
                    font-size: 12px;
                    font-style: italic;
                }
                .attendee-company {
                    font-size: 12px;
                }
            }
            .attendee-actions {
                width: 100px;
                min-width: 100px;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                .open-profile-button {
                    background: transparent;
                    border: none;
                }
            }
        }
    }
    .rooms-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 10px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 4px;
        }
        .rooms-heading {
            font-weight: bold;
            font-size: 20px;
        }
        .rooms-find-label {
            margin: 10px 0 0 0;
            font-size: 12px;
        }
        .rooms-find {
            border: solid 2px #ddd;
            padding: 5px 10px;
            margin: 0 0 10px 0px;
            background: rgba(255, 255, 255, 0.4);
            color: #555;
            &::placeholder {
                color: #ddd;
            }
        }
        .rooms-sub-heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-size: 20px;
        }
        .room {
            display: flex;
            flex-direction: row;
            padding: 10px 0px;
            align-items: center;
            &:hover {
                cursor: pointer;
            }
            .room-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 50px;
                width: 50px;
                height: 50px;
                border-radius: 5%;
                border: solid 1px #ddd;
                background: rgba(0, 0, 0, 0.2);
                &.active {
                    background: rgba(0, 200, 0, 0.9);
                }
            }
            .room-info {
                padding: 0px 10px;
                .room-name {
                    font-weight: bold;
                    font-size: 15px;
                }
                .room-description {
                    font-size: 13px;
                    font-style: italic;
                }
                .room-status {
                    font-size: 10px;
                    font-style: italic;
                }
                .room-password-input {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .room-join-input {
                        width: 100px;
                        border: solid 1px #ddd;
                        height: 25px;
                        border-radius: 2px;
                        padding: 2px 5px;
                        font-size: 12px;
                        background: rgba(255, 255, 255, 0.7);
                    }
                    .room-join-button {
                        background: transparent;
                        border: none;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    &.minimized {
        width: 0px;
        min-width: 0px;
    }

    &.transparent,
    &.transparent-dark {
        background: transparent;
        box-shadow: none;
    }
    &.transparent {
        .chat-content .chat-history {
            .message-content-message-moderator {
                color: rgb(54, 54, 54);
            }
            .message-content-message {
                &.hilight {
                    background-color: rgba(255, 255, 255, 0.3);
                }
            }
        }
        border: solid 1px #000;
        .chat-tabs::after {
            background-color: #fff;
        }
        .chat-tab-button {
            color: #000;
            border-bottom: solid 2px #000;
        }
        .chat-content {
            .chat-heading {
                color: #000;
            }
            .chat-history {
                .chat-message,
                .chat-message-continued {
                    .message-content {
                        color: #000;
                    }
                    .message-content-moderator {
                        color: #000;
                    }
                }
            }
        }
        .rooms-content {
            .rooms-heading,
            .room-name,
            .room-description,
            .room-status,
            .rooms-sub-heading {
                color: #000;
            }
            .room-join-button {
                color: #000;
            }
        }
        .attendees-content {
            .attendees-heading {
                color: #000;
            }
            .attendees-find-container .attendees-find-label {
                color: #000;
            }
            .attendee {
                .attendee-info {
                    color: #000;
                }
            }
        }
        .inbox {
            .inbox-heading {
                color: #000;
            }
            .inbox-row {
                color: #000;
            }
            .user-profile {
                color: #000;
                .close-button {
                    color: #000;
                }
                .profile-socials {
                    .link {
                        color: #555;
                    }
                }
            }
        }
        .settings-button,
        .send-message-button {
            color: #000;
        }
        .send-message {
            .send-input::placeholder {
                color: #555;
            }
        }
    }
    &.dark {
        background: rgba(0, 0, 0, 0.8);
        .quoted-msg-container {
            background-color: rgba(255, 255, 255, 0.4) !important;
            border-color: $liveto-gray !important;
        }
    }
    &.transparent-dark {
        border: solid 1px #fff;
        .chat-content {
            .chat-history {
                .chat-message,
                .chat-message-continued {
                    .message-content {
                        &.quoted {
                            .message-wrapper {
                                background: rgba(255, 255, 255, 0.2);
                                .quoted-msg-container {
                                    background-color: rgba(0, 0, 0, 0.3);
                                    border-color: $liveto-black;
                                    .sender {
                                        color: $liveto-white;
                                    }
                                    color: #ddd;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.dark,
    &.transparent-dark {
        .chat-content .chat-history {
            .message-content-message-moderator {
                color: black;
            }
            .message-content-message {
                &.hilight {
                    background-color: rgba(255, 255, 255, 0.2);
                }
            }
        }
        .chat-tabs {
            &::after {
                background-color: #d62b1e;
            }
            .chat-tab-button {
                position: relative;
                color: #ddd;
                border-bottom: solid 2px #ddd;
                &.active {
                    color: $liveto-red;
                    border-color: $liveto-red;
                }
            }
        }
        .inbox {
            padding: 10px;
            .inbox-heading {
                color: #fff;
            }
            .inbox-row {
                color: #fff;
                .preview-icon {
                    color: #000;
                }
            }
            .user-profile {
                .close-button {
                    color: #fff;
                }
                .profile-data {
                    color: #fff;
                }
                .profile-description {
                    color: #fff;
                }
                .chat-history {
                    .message .sender {
                        color: #fff;
                    }
                    .date-divider .date-divider-content {
                        color: #fff;
                    }
                    .no-private-messages {
                        color: #fff;
                    }
                }
                .profile-socials {
                    .link {
                        color: #fff;
                    }
                }
            }
            .no-private-messages {
                color: #fff;
            }
        }
        .rooms-content {
            .rooms-heading,
            .room-name,
            .room-description,
            .room-status,
            .rooms-sub-heading,
            .rooms-find-label,
            .room-join-button {
                color: #fff;
            }
        }
        .attendees-heading {
            color: #fff;
        }
        .attendees-content {
            .attendee-title,
            .attendee-name,
            .attendee-company,
            .attendees-find-label,
            .attendees-find {
                color: #fff;
            }
        }
        .message-body {
            color: #fff;
        }
        .chat-heading {
            h2,
            p {
                color: #fff;
            }
        }

        .message-content-name {
            color: #ddd;
        }
        .message-content-message {
            color: #fff;
            position: relative;
        }
        .chat-history .message-link {
            color: rgb(238, 142, 136);
        }
        .chat-content .chat-history .message-info-timestamp {
            color: #fff;
        }
        .send-message-button,
        .settings-button,
        .togglers {
            color: #fff;
        }
        .send-input::placeholder {
            color: #ddd;
        }
    }
    &.mobile,
    &.tablet {
        &.transparent,
        &.transparent-dark {
            border: none;
        }
        &.dark,
        &.transparent-dark {
            background: rgba(0, 0, 0, 1);
        }
        &.transparent {
            .chat-tabs::after {
                background-color: #d62b1e;
            }
        }
    }
    .message-moderator-menu {
        margin-top: 1.5em;
        border-radius: 0px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        font-size: 12px;
        padding: 0px;
        max-width: 370px; // chat width - padding
        .moderator-user-preview {
            display: flex;
            flex-direction: row;
            padding: 4px 4px 0px 4px;
            .moderator-user-preview-image {
                width: 40px;
                min-width: 40px;
                height: 40px;
                border-radius: 50%;
                border: solid 2px #ddd;
                display: flex;
                justify-content: center;
                align-items: center;
                background-size: cover;
                background-position: center;
            }
            .moderator-user-preview-info {
                padding: 0 5px;
            }
        }
        .muting-user {
            .mute-options {
                display: flex;
                flex-wrap: wrap;
                gap: 2px;
                .mute-option {
                    background: transparent;
                    border: solid 2px #ddd;
                    border-radius: 5px;
                    color: #555;
                }
            }
        }
        .react-contexify__item.muting {
            &:hover {
                .react-contexify__item__content {
                    background-color: transparent;
                    color: #555;
                }
            }
        }
    }
}

@media only screen and (max-width: 1475px) and (min-width: 1000px) {
    #liveto-chat {
        width: 360px;
        min-width: 360px;
        position: sticky;
        transition: 0.2s right ease-in-out;
        &.show {
            right: 0px;
        }
        height: calc(100dvh - 90px);
        max-height: 100%;
        .chat-content .chat-history .message-icon {
            display: none;
        }
        .chat-content .chat-history .message-timestamp {
            display: none;
        }
    }
}

.attendee-usergroups-popover {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.5em;
    border-radius: 10px;
    box-shadow: $liveto-above-all;
    border: unset;
    .arrow::before {
        border-bottom-color: transparent;
    }
    .group-tag {
        padding: 0.2em 0.6em;
        color: var(--tag-color);
        border-radius: 50vw;
        background: var(--tag-background);
        white-space: nowrap;
        text-align: center;
        font-size: 10px;
    }
}
