.virtual-event-navigator {
    display: flex;
    background: transparent;
    left: 0;
    z-index: 1;
    border-radius: 1rem;

    &.desktop {
        width: 200px;
        min-width: 200px;
        position: sticky;
        top: 80px;
        padding-left: 10px;
        height: calc(100dvh - 90px);
        flex-direction: column;

        .navigator-content {
            &.transparent-dark {
                background: rgba(0, 0, 0, 0.5);
            }
        }
    }
    &.tablet {
        width: 100px;
        min-width: 100px;
        position: fixed;
        top: 70px;
        flex-direction: column;
        .navigator-logo-container {
            display: none;
        }
        .navigator-content {
            max-height: unset;
            border-radius: 0;
            max-height: calc(100dvh - 160px);
            overflow-y: auto;
            .scroll-content {
                .navigator-link {
                    .navigator-link-text {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 90px;
                    }
                }
            }
            &.transparent,
            &.transparent-dark {
                border: none;
            }
            &.light {
                background: rgb(255, 255, 255);
            }
        }
    }
    &.mobile {
        width: 100%;
        position: sticky;
        top: 80px;
        padding-left: 10px;
        flex-direction: column;
        &.dark,
        &.transparent-dark {
            background: rgba(0, 0, 0, 1);
        }
        .navigator-content {
            border-radius: 0;
            border: none !important;
            max-height: calc(100svh - 90px);
            overflow-y: auto;
            .scroll-content {
                .navigator-link {
                    .navigator-link-text {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 90px;
                    }
                }
            }
            &.dark,
            &.transparent-dark {
                .scroll-content {
                    .navigator-link {
                        div {
                            color: #fff !important;
                        }
                        svg {
                            fill: #fff !important;
                        }
                        .navigator-link-text {
                            color: #fff;
                        }
                    }
                }
            }
        }
        .navigator-logo-container {
            display: none;
        }
    }

    .navigator-content {
        width: 100%;
        border-radius: 1rem;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: all 0.4s ease-in-out;

        .scroll-content {
            .navigator-link {
                color: #000;
            }
        }

        &.dark,
        &.transparent-dark {
            background: rgba(0, 0, 0, 0.8);
        }
        &.transparent {
            background: transparent;
            box-shadow: none;
        }
        &.transparent-dark {
            border: solid 1px #fff;
        }
        &.transparent {
            border: solid 1px #000;
        }

        &.transparent-dark,
        &.dark {
            .scroll-content {
                .navigator-link {
                    color: #fff;
                }
            }
        }
    }

    .navigator-logo {
        width: 100%;
        padding-bottom: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 85%;
        border-radius: 2px;
    }

    .scroll-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 4px;
        }
    }

    .navigator-link {
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 5px 5px 5px 10px;
        margin: 5px 0px;
        width: 100%;
        border: solid 2px transparent;
        &.active {
            .navigator-link-text {
                text-decoration: underline;
                border-radius: 5px;
            }
        }
    }
    .navigator-link-text {
        width: -moz-fit-content;
        width: fit-content;
        padding: 0px 5px;
        margin: 0px;
        text-align: center;
        font-size: 12px;
    }
}

@media screen and (max-width: 1475px) {
    .virtual-event-navigator {
        width: 100px;
        min-width: 100px;
    }
}

@media screen and (max-width: 1300px) {
    .virtual-event-navigator.desktop {
        width: 100px;
        min-width: 100px;
    }
}

/* @media screen and (max-width: 600px) {
    .virtual-event-navigator {
        position: fixed;
        bottom: 0px;
        right: 0px;
        left: 0px;
        top: unset;
        height: 60px;
        padding: 0px;
        border-right: none;
        display: flex;
        width: 100vw;
        .navigator-content {
            .nav-link {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
            }
        }

        .scroll-content {
            flex-direction: row;
            overflow-x: auto;
            width: unset;
        }

        .hideMobile {
            display: none;
        }
        .navigator-link {
            padding: 2px;
        }
        .navigator-link-text {
            font-size: 12px;
            color: #000;
            text-overflow: ellipsis;
            overflow: hidden;
            overflow-wrap: break-word;
            max-width: 100px;
        }
    }
} */

::-webkit-scrollbar {
    width: 10px;
    height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
