.chat-filter-input {
    border: solid 2px #ddd;
    padding: 5px 10px;
    background: rgba(255, 255, 255, 0.4);
    color: #555;
    width: 100%;
    &::placeholder {
        color: #ddd;
    }
}
.editor-menu-input {
    width: 40px;
    font-weight: 700;
    border: unset !important;
    text-align: right;
    padding-right: 0.5em;
    &:focus {
      outline: none !important;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
    &.image {
      width: auto;
    }
  }