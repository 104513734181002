.virtual-event-carousel-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.virtual-event-component-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.virtual-event-component-overlay {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
.carousel-item-wrapper {
    width: 100%;
    height: 100%;
}

//Simple card

.simple-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height: 100%;
    width: 100%;
    padding: 1em;
}
.simple-card-header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1em;
    width: 100%;
    p {
        font-size: 24px;
        font-weight: 700;
    }
}
.simple-card-bottom-overlay {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.simple-card-button {
    display: inline-flex;
    padding: 8px 16px;
    font-size: 18px;
    align-items: center;
    width: auto;
    text-align: center;
    margin: auto;
    color: black;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
    margin: 0.5em;
    $button-color-transition-speed: 180ms;
    transition: 
        background-color $button-color-transition-speed ease-in-out, 
        color $button-color-transition-speed ease-in-out, 
        border-color $button-color-transition-speed ease-in-out;
    i {
        margin-left: 0.4em;
        transition: transform 250ms ease-in-out 0s;
        transform: translateX(0);
    }
    &:hover {
        transform: scale(1.05);
    }
    &:active {
        i {
            transform: translateX(0.6em);
        }
    }
}

// Listing card
.listing-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgb(240, 240, 240) 0px 5px 40px 0px;
    position: relative;
    border-radius: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-color: #fff;
    border: solid 1px #ddd;
    height: 100%;
    width: 100%;
    &:after {
        content: "";
        pointer-events: none;
        background: linear-gradient(
            180deg,
            rgba(17, 17, 17, 0.88) 0%,
            rgba(15, 15, 15, 0.542735) 7.17%,
            rgba(3, 3, 3, 0.0970896) 19.43%,
            rgba(0, 0, 0, 0) 26.62%
        );
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        top: 0;
        left: 0;
    }
}
.listing-header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1em;
}
.listing-description {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.0776113px;
    color: white;
    margin-left: 1em;
    margin-bottom: 0;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 1));
    z-index: 1;
}
.listing-logo {
    background-repeat: no-repeat;
    background-color: white;
    background-position: center;
    background-size: 60px;
    width: 70px;
    min-width: 70px;
    height: 70px;
    border-radius: 12px;
    border: none;
    z-index: 1;
}
.listing-bottom-overlay {
    min-height: 22%;
    display: flex;
    flex-direction: row;
    align-items: center;
    a,
    a:hover {
        text-decoration: none;
        color: inherit;
        margin: auto;
    }
    // background-color: rgba(0, 0, 0, 0.4);
    // border-radius: 0px 0px 12px 12px;
}
.listing-title {
    color: white;
    width: 60%;
    padding: 1em;
    line-height: 22px;
    letter-spacing: 0.5px;
    margin: auto;
}
.listing-button, .simple-card-button {
    display: inline-flex;
    padding: 0.4em 0.8em;
    align-items: center;
    width: auto;
    text-align: center;
    margin: auto;
    color: black;
    background-color: white;
    border-color: black;
    border: 2px solid;
    border-radius: 10px;
    cursor: pointer;
    white-space: nowrap;
    margin: 0.5em;
    $button-color-transition-speed: 180ms;
    transition: background-color $button-color-transition-speed ease-in-out,
        color $button-color-transition-speed ease-in-out, border-color $button-color-transition-speed ease-in-out;
    i {
        margin-left: 0.4em;
        transition: transform 250ms ease-in-out 0s;
        transform: translateX(0);
    }
    &:hover {
        color: white;
        background-color: black !important;
        border-color: white;
        i {
            transform: translateX(0.2em);
        }
    }
    &:active {
        i {
            transform: translateX(0.6em);
        }
    }
}

// For editor as media size stuff doesn't work in editor
.react-grid-layout {
    &.editor {
        &.mobile {
            .listing-card-multi-container {
                flex-direction: column !important;
            }
        }
    }
}

// Listing card multi

.listing-card-multi-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .listing-card-multi-left {
        overflow: hidden;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        flex-basis: 50%;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        &:before {
            content: "";
            z-index: -1;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(
                180deg,
                rgba(17, 17, 17, 0.88) 0%,
                rgba(15, 15, 15, 0.542735) 7.17%,
                rgba(3, 3, 3, 0.0970896) 19.43%,
                rgba(0, 0, 0, 0) 26.62%
            );
        }
        .listing-multi-title {
            margin: 1em;
            filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.6));
            color: #ffffff;
            z-index: 1;
            p {
                font: inherit;
                filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.6));
                color: inherit;
            }
        }
    }
    .listing-card-multi-right {
        flex-basis: 50%;
        background-color: white;
        border-bottom-left-radius: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        .listing-multi-description {
            text-align: left;
            margin: 1em;
        }
        .listing-multi-buttons {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            flex-wrap: wrap;
            overflow: hidden;
            a,
            a:hover {
                text-decoration: none;
            }
        }
    }
}
@media (min-width: 768px) {
    .listing-card-multi-container {
        flex-direction: row;
        .listing-card-multi-left {
            border-top-left-radius: 10px;
        }
        .listing-card-multi-right {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}

.preview-modal-inner-content {
    &.mobile {
        .listing-card-multi-container {
            flex-direction: column;
        }
    }
}
