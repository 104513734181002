.header-modal {
    &-modal,
    .mantine-Modal-modal {
        background: transparent;
        border: none;
        padding: 0;
        margin-left: unset;
    }
    &-inner,
    .mantine-Modal-inner {
        padding: 0;
        overflow-y: unset;
    }
    &-header,
    .mantine-Modal-header {
        background: #fff;
        //width: 100%;
        height: 70px;
        padding: 0 20px;
        &.mobile {
            margin-bottom: 0;
            z-index: 10;
        }
    }

    &-title,
    .mantine-Modal-title {
        font-size: 24px;
        color: #d62b1e;
        font-weight: bold;
    }
    &-body,
    .mantine-Modal-body {
        max-height: 100dvh;
        overflow-y: scroll;
        &.mobile {
            padding: 0.5rem 0;
        }
    }
    .mantine-Paper-root {
        box-shadow: unset !important;
    }
    .modal-content-section {
        display: flex;
        justify-content: center;
    }
    .hide-header {
        display: none !important;
    }
}
.header-modal-dialog {
    max-width: unset;
    margin: 0;
}
.header-modal-backdrop {
    background: rgba(0, 0, 0, 0.9);
    opacity: unset !important;
}
