@import "globals/sass/_mixin.scss";

// Layout
// Toggle animation for the background image/color and layout grid buttons
@mixin toggler($length, $id, $offset, $dir) {
    animation-direction: $dir;
    animation-name: toggle-#{$id};
    transform: translateX(0) scaleX(1);
    @keyframes toggle-#{$id} {
        0% {
            transform: translateX(0);
            opacity: 0;
        }
        10% {
            opacity: 0.5;
        }
        80% {
            transform: translateX(calc(#{$length} - #{$offset}));
        }
        100% {
            transform: translateX($length);
            opacity: 1;
        }
    }
}

// Generates the background grid as the elements ::before (columns) and ::after (rows) pseudo elements
@mixin calc_grid($cols, $width, $step) {
    $gutter: $step;
    $offset: calc(#{$gutter} * (#{$cols} / 2));
    $color: hsla(204, 80%, 72%, 0.4);
    $repeating-width: calc((#{$width} / #{$cols}) - (2 * #{$gutter}));

    &::before {
        background-image: repeating-linear-gradient(
            to right,
            transparent,
            transparent $gutter,
            $color $gutter,
            $color $repeating-width,
            transparent $repeating-width,
            transparent calc(#{$repeating-width} + #{$gutter})
        );
        width: calc(#{$width} - (2 * #{$offset}));
        top: -2px;
        left: $offset;
        background-size: calc(#{$width} - (2 * #{$offset})) 100%;
    }
    &::after {
        background-image: repeating-linear-gradient(
            to bottom,
            transparent,
            transparent 0px 19px,
            $color 19px,
            $color 20px
        );
        width: $width;
        top: 0;
        left: 0;
        background-size: 100% 100%;
    }
    &::before,
    &::after {
        content: "";
        position: absolute;
        pointer-events: none;
        height: 100%;
        z-index: 3;
        background-position: 0 0;
    }
}

.unauthorized {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    h1 {
      margin: 0;
    }
    .buttons {
      display: flex;
      gap: 0.5rem;
      .error-button {
        border: 1px solid $liveto-red;
        background-color: $liveto-red;
        color: #FFF;
        border-radius: 50vw;
        padding: 0.2rem 0.5rem;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        &:hover {
          background-color: #FFF;
          color: $liveto-red;
        }
      }
    }
}
.modalbg {
    opacity: 0.8 !important;
}
.save-template-modal {
    background: rgba(255, 255, 255, 1);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    overflow: hidden;
    .save-template-modal-header {
        padding: 0.5rem 1rem;
        background-color: $liveto-light-pink;
        width: 100%;
        color: $liveto-black;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        .close-button {
            background: transparent;
            border: none;
            font-weight: bold;
            z-index: 2;
        }
    }
    .save-tempate-modal-body,
    .save-tempate-modal-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        &.border-bottom {
            border-bottom: solid 1px #ddd;
        }
        .status-message {
            font-weight: 700;
            color: $liveto-green;
        }
    }
    .save-button {
        margin: 0px;
        padding: 5px 10px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        border-radius: 50vw;
        border: none;
        font-size: 14px;
    }
    .modal-heading {
        font-size: 20px;
        margin: 0px;
        padding: 0px 10px;
    }
    .button-close {
        background: transparent;
        color: red;
        border: none;
    }
    .modal-label {
        font-weight: bold;
        font-size: 16px;
        margin: 0 10px;
    }
    .modal-input {
        border: solid 1px #ddd;
        border-radius: 5px;
        padding: 5px 10px;
        width: 200px;
    }
}

.save-modal {
    background: rgba(255, 255, 255, 0);
    border: none;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .heading {
        font-size: 20px;
        font-weight: bold;
    }
    .message-options {
        display: flex;
        flex-direction: column;

        .option {
            border: none;
            color: #555;
            background: #fff;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
            padding: 5px 10px;
            margin: 10px 0 0 0;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            position: relative;
            border-radius: 16px;
            a {
                width: 100%;
                display: flex;
                justify-content: space-between;
                z-index: 2;
                color: inherit;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
            .option-overlay {
                position: absolute;
                top: 0;
                left: 50%;
                bottom: 0;
                width: 0%;
                transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
                border-bottom: solid 2px #555;
                border-radius: 16px;
                &.success {
                    background: rgba(0, 255, 0, 0.7);
                }
                &.failure {
                    background: rgba(255, 0, 0, 0.7);
                }
            }
            &:hover {
                .option-content {
                    z-index: 2;
                }
                &.success {
                    .option-overlay {
                        width: 100%;
                        left: 0;
                    }
                }
                &.failure {
                    .option-overlay {
                        width: 100%;
                        left: 0;
                    }
                }
            }
            &.transparent {
                &.center {
                    justify-content: center;
                }
                background: transparent;
                color: #fff;
                cursor: pointer;
            }
        }
    }

    .save-changes-button {
        background-color: #d62b1e;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
        border: solid 2px #fff;
        border-radius: 5px;
        padding: 5px 10px;
        color: #fff;
    }
    .message {
        padding: 30px;
        font-weight: bold;
        font-size: 30px;
        color: #fff;
        width: 300px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
        border-radius: 16px;
        p {
            font-weight: normal;
        }
        &.success {
            background: rgba(0, 255, 0, 0.5);
        }
        &.failed {
            background: rgba(255, 0, 0, 0.5);
            flex-direction: column;
        }
        &.loading {
            background: rgba(255, 255, 255, 0.5);
        }
    }
}

.virtual-event-editor-not-supported {
    height: 100%;
    width: 100%;
    color: $liveto-red;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    padding: 1rem;
    padding-top: 40vh;
}

.virtual-event-editor-view-layout {
    height: 100dvh;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
    overflow-y: auto;

    .grey-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.5;
        z-index: 10;
    }
    .virtual-event-editor-view-canvas {
        height: 100dvh;
        width: 100%;
        display: grid;
        grid-template-rows: 56px 1fr;
        grid-template-areas:
            "header header header"
            "nav-left content nav-right";
        &.desktop {
            grid-template-columns: 1fr 1156px 1fr;
        }
        &.tablet {
            grid-template-columns: 1fr 772px 1fr;
        }
        &.mobile {
            grid-template-columns: 1fr 388px 1fr;
        }
    }
    .editor-nav {
        grid-area: nav-left;
        position: relative;
    }
    .white-space {
        grid-area: nav-right;
        background: transparent;
        position: relative;
        pointer-events: none;
    }
    .header {
        grid-area: header;
        position: relative;
        .heading-template {
            height: 70px;
            width: 100%;
            background-color: #fff;
        }
    }
    .virtual-event-editor-view-canvas-content {
        display: flex;
        flex-direction: row;
        background-color: #fff;
        overflow-x: hidden;
        display: grid;
        grid-template-rows: 1fr;
        &.desktop {
            width: 100%;
            height: 100%;
            grid-template-columns: 200px 1fr;
            grid-template-areas: "navigator content";
        }
        &.tablet {
            width: 1024px;
            height: 1366px;
            transform: scale(0.6);
            grid-template-columns: 200px 1fr;
            grid-template-areas: "navigator content";
        }
        &.mobile {
            width: 720px;
            height: 1280px;
            transform: scale(0.5);
            grid-template-columns: 1fr;
            grid-template-areas: "content";
        }
    }

    .virtual-event-content {
        grid-area: content;
        height: 100%;
        width: 100%;
        max-width: 1156px;
        background: transparent;
        border-top: dashed 2px yellow;
        border-left: dashed 2px yellow;
        border-right: dashed 2px yellow;
        position: relative;
        &:before {
            pointer-events: none;
            content: " ";
            position: absolute;
            z-index: 0;
            top: -3px;
            left: -3px;
            border: 2px dashed#000;
            border-bottom: none;
            right: -3px;
            bottom: 0px;
        }
    }

    #virtual-event-editor {
        position: fixed;
        left: 0px;
        top: 100px;
        height: calc(100dvh - 130px);
        width: 350px;
        overflow-y: auto;
        display: flex;
        z-index: 11;
        flex-direction: column;
        background: transparent;
        padding: 10px;
    }
    .virtual-event-edit-overlay {
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        z-index: 1;
    }

    @keyframes pulse {
        to {
            box-shadow: 0px 0px 5px red;
        }
    }

    .virtual-editor-component-inactive {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 3;
    }

    .active-component-deselect-button {
        position: absolute;
        right: 1rem;
        top: 1rem;
        border: 1px solid $liveto-black;
        border-radius: 50vw;
        transition: all 0.3s ease-in-out;
        padding: 0.25rem;
        background-color: $liveto-white;
        z-index: 3;
        i {
            max-height: 16px;
            display: flex !important;
            justify-content: center;
            align-items: center;
        }
        &:hover {
            border-color: $liveto-white;
            color: $liveto-white;
            background-color: $liveto-black;
        }
    }

    .virtual-event-component {
        height: -moz-fit-content;
        height: fit-content;
        overflow: hidden;
        position: relative;
        z-index: 1;
        &.active {
            box-shadow: 1px 1px 10px #e4e4e4;
        }
        &.hilight {
            box-shadow: 0px 0px 7px red;
            animation: pulse 2s alternate infinite;
        }
        &.transform {
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                width: 2px;
                height: 100dvh;
                border-left: dashed 1px #ddd;
            }
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                width: 100vw;
                border-bottom: dashed 1px #ddd;
                height: 2px;
            }
        }
    }

    .element-hilight-wrapper {
        outline: 0 solid rgba(255, 255, 255, 0.7);
        transition: outline 0s;
        &.hilight {
            outline: 70em solid rgba(255, 255, 255, 0.7);
            transition: outline 0.7s ease-in;
            z-index: 10 !important;
        }
    }

    .virtual-event-editor-heading {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 110px;
        display: flex;
        flex-direction: column;
        z-index: 12;
    }
    .heading-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 60px;
        padding: 5px;
        z-index: 1;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
    }
    .subheading-row {
        height: 50px;
        background-color: #e4e4e4;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .editor-column {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        z-index: 5;
        .editor-heading-button {
            background: transparent;
            border: none;
            margin: 0 5px;
            color: #484848;
            font-weight: bold;
            display: flex;
            align-items: center;
            cursor: pointer;
            p {
                margin: 0;
                padding: 0px 5px;
            }
        }
        label {
            margin: 0px 10px;
            font-weight: bold;
        }
    }
    .editor-row.border-bottom {
        border-bottom: solid 2px #e4e4e4;
    }

    .tree-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px;
    }
    .tree-heading h6 {
        font-weight: bold;
        margin: 0;
    }
    .minimize-tree-button {
        background-color: transparent;
        border: none;
        outline: none;
    }
    .minimize-tree-button:focus {
        outline: none;
    }
    .editor-grid-lock {
        background-color: transparent;
        border: none;
        outline: none;
    }
    .editor-grid-lock:focus {
        outline: none;
    }

    .layout-changer-button {
        background-color: transparent;
        border: none;
        border-radius: 5px;
        outline: none;
        padding: 5px 7px;
        margin-left: 5px;
        &.active {
            background-color: #8bc400;
        }
        &:focus {
            outline: none;
        }
    }
}
.virtual-event-templates {
    box-shadow: none;
    width: 100%;
    max-height: 100%;
    height: 100%;
    background-color: #fff;
    z-index: $z-mid;
    transform: translateX(100%);
    transition: transform 0.2s ease-in-out, color 0.3s ease-in, background-color 0.3s ease-in;
    display: flex;
    flex-direction: column;
    padding: $size-100;
    overflow-y: auto;
    padding: 0;
    justify-content: space-between;
    &.active {
        transform: translateX(0%);
    }
    .navright-header {
        min-height: 45px;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;
        color: $liveto-red;
        border-bottom: 1px solid #ddd;
    }
    .template-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: overlay;
        align-self: stretch;
        &::-webkit-scrollbar {
            width: 3px;
        }
        .template-dropdown {
            color: $liveto-black;
            width: 100%;
            display: flex;
            flex-direction: column;
            font-weight: 700;
            font-size: 14px;
            &.show {
                .template-list {
                    transform: scaleY(1);
                    height: auto;
                    opacity: 1;
                }
                .title {
                    color: $liveto-red;
                }
            }

            .title {
                width: 100%;
                display: flex;
                justify-content: space-between;
                &:not(.disabled):hover {
                    cursor: pointer;
                    color: $liveto-red;
                }
                &.disabled {
                    color: $liveto-gray;
                }
            }
            .template-list {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 0.5em;
                transform: scaleY(0);
                height: 0;
                transition: all 0.1s ease-in-out;
                transform-origin: top;
                opacity: 0;
                padding: 0.5em 0;
                .template {
                    font-weight: normal;
                    -webkit-font-smoothing: antialiased;
                    .template-card {
                        width: 100%;
                        height: 150px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $liveto-medium-light-gray;
                        border-radius: 15px;
                        transition: all 0.15s ease-in-out;
                        overflow: hidden;
                        position: relative;
                        &:hover {
                            box-shadow: $liveto-above-all;
                            z-index: 100;
                            cursor: pointer;
                        }
                        .template-preview-image {
                            height: 100%;
                            width: 100%;
                        }
                        .template-buttons {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            padding: 1em;
                            display: flex;
                            justify-content: space-between;
                            .select-template-btn {
                                border: unset;
                                background-color: $liveto-red;
                                color: #fff;
                                border-radius: $pill-border-radius;
                                padding: $pill-padding;
                                transition: transform 0.2s ease-in-out;
                                &:hover {
                                    transform: scale(1.1);
                                }
                            }
                        }
                        .template-animation-container {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
    .footer {
        min-height: 70px;
        border-top: 1px solid #bbb;
        color: $liveto-black;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .button-container {
            display: flex;
            gap: 1em;
            transition: color 0.2s ease-in-out;
            &:hover {
                cursor: pointer;
                color: $liveto-red;
            }
            .templates-save-icon {
                color: $liveto-red;
            }
        }
    }
}
.virtual-event-editor-tree {
    position: absolute;
    max-height: 100%;
    background-color: #fbfbfb;
    &.dark {
        background-color: $liveto-dark-gray;
    }
    transition: transform 0.25s ease-in;
    &.history,
    &.templates {
        transform: translateX(-100%);
    }
    &.sitemap {
        transform: translateX(100%);
    }
    &.components {
        transform: translateX(0%);
    }
}
.editor-loading-screen {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 1921) {
    .virtual-event-editor-view {
        border-left: solid 5px #131313;
        border-right: solid 5px #131313;
    }
}

.react-resizable-handle {
    z-index: 3;
    &.react-resizable-handle-se {
        bottom: -10px !important;
        right: -10px !important;

        &:after {
            width: 5px !important;
            height: 5px !important;
            border-style: dashed;
            border-top: none;
            border-left: none;
            border-color: yellow !important;
            border-width: 2px;
            filter: drop-shadow(2px 4px 6px black);
        }
    }
    &.react-resizable-handle-s {
        bottom: -10px !important;
        transform: rotate(0deg) !important;
        margin-left: 0px !important;
        &:after {
            width: 30px !important;
            height: 1px !important;
            border-color: yellow !important;
            border-style: dashed;
            border-width: 1px;
            filter: drop-shadow(2px 2px 5px black);
        }
    }
    &.react-resizable-handle-e {
        right: -10px !important;
        transform: rotate(0deg) !important;
        margin-top: 0px !important;

        &:after {
            width: 2px !important;
            height: 30px !important;
            border-color: yellow !important;
            border-style: dashed;
            border-width: 1px;
            filter: drop-shadow(2px 2px 5px black);
        }
    }
}

.editor-tutorial {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 20;
    .tutorial-modal {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: solid #e4e4e4 2px;
        padding: 5em;
        background-color: white;
        display: flex;
        flex-direction: column;
        h4 {
            width: 60%;
            text-align: center;
            margin: 0 auto 1em auto;
        }
        .modal-buttons {
            display: flex;
            justify-content: space-evenly;
            button {
                text-align: center;
                border: 2px solid #c4281a;
                padding: 0.25em 1em;
                outline: 1px solid #c4281a;
                outline-offset: -2px;
                background-color: white;
                transition: outline-offset 0.1s ease, background-color 0.2s ease;
                &:hover {
                    outline-offset: 3px;
                    filter: drop-shadow(2px 2px 6px rgba(148, 148, 148, 1));
                }
                &:active {
                    outline-offset: -8px;
                    background-color: white;
                }
            }
        }
    }
}

.tutorial-small-modal {
    position: absolute;
    z-index: 100;
    background-color: white;
    width: 20%;
    padding: 2em;
    border: solid #e4e4e4 2px;
    display: flex;
    flex-direction: column;
    .skip-tutorial {
        position: absolute;
        right: 0.4em;
        top: 0.4em;
        color: black;
        cursor: pointer;
    }
}

.tutorial-active {
    z-index: 10000 !important;
}

/* 
    EDITOR NEW LAYOUT START
*/

.virtual-event-editor-view-layout {
    height: 100dvh;
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: 56px 1fr;
    transition: background-color 0.3s ease-in;
    &.desktop,
    &.tablet,
    &.mobile {
        grid-template-columns: calc(min(340px, 20%)) auto calc(min(340px, 20%));
    }
    grid-template-areas:
        "toolbar toolbar toolbar"
        "nav-left content content";
    justify-items: end;
}

// Header main toolbar

//.virtual-event-editor-view .virtual-event-editor-heading
.virtual-event-main-toolbar {
    grid-area: toolbar;
    position: fixed;
    inset: 0;
    max-height: 56px;
    display: grid;
    align-items: center;
    grid-template-columns: [title]calc(min(380px, 30%)) [widgets]1fr [actions]calc(min(400px, 30%));
    grid-template-areas: "title widgets actions";
    color: $liveto-black;
    background-color: $liveto-light-pink;
    transition: color 0.3s ease-in, background-color 0.3s ease-in;
    box-shadow: $main-layout-shadow;
    z-index: $z-top;
    &.dark {
        background-color: #484848;
        color: #a1c0c1;
        border-color: #545454;
    }
}

.main-title {
    font-size: $fs-500;
    color: $liveto-red;
    transition: color 0.3s ease-in;
    margin: 0;
    line-height: 1.3;
    text-align: center;
    grid-area: title;
}
.virtual-event-toolbar-widgets {
    grid-area: widgets;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}
.toolbar-widget-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 100px;
    cursor: pointer;
    .widget-wrapper {
        justify-content: center;
        align-items: center;
        display: flex;
        gap: 0.3em;
        padding: 0.25em 0.75em;
        svg {
            fill: $liveto-text-gray;
        }
    }
    &:not(.switch, .active):hover {
        color: $liveto-black !important;
        background-color: #fff;
        .widget-wrapper {
            svg {
                fill: $liveto-text-gray !important;
            }
        }
    }
    .toolbar-single-widget-name {
        display: flex;
        font-size: 12px;
        white-space: nowrap;
    }
    &.active {
        background-color: #333333;
        color: $liveto-light-gray;
        &:hover {
            .widget-wrapper {
                color: #fff !important;
                svg {
                    fill: $liveto-light-gray !important;
                }
            }
        }
        svg {
            fill: $liveto-light-gray;
        }
    }

    .tool-container {
        display: flex;
        gap: 0.5em;
        position: absolute;
        top: calc(100% + 15px);
        background-color: #f6f6f6;
        color: #3f3f3f;
        padding: 0.5em 0.75em;
        border-radius: 10px;
        box-shadow: $liveto-above-all;
        transform-origin: top;
        cursor: default;
        transition: background-color 3s ease-in, color 3s ease-in;
        transition-delay: 3s;
        &.link {
            .tool-inner-container {
                gap: 0.5rem;
                .tools {
                    gap: 0.125rem;
                    .editor-menu-input {
                        width: 100%;
                        border: 1px solid #bbb !important;
                    }
                    .widget-select {
                        border: 1px solid #bbb !important;
                    }
                    .buttons {
                        display: flex;
                        flex-direction: row;
                        gap: 0.5rem;
                        flex-wrap: nowrap;
                    }
                }
            }
        }
        &.carousel {
            .tool-inner-container {
                gap: 1em;
                .tools {
                    .tool {
                        padding: 0;
                        justify-content: space-between;
                        gap: 0.5em;
                    }
                }
            }
        }
        &.separator {
            .tool-inner-container {
                gap: 1em;
                .tools {
                    .tool {
                        padding: 0;
                        gap: 0.5em;
                        .editor-menu-input {
                            flex-grow: 4;
                        }
                    }
                    .editor-menu-button {
                        width: 100%;
                    }
                    .buttons {
                        & > * {
                            flex: 0 0 calc(25% - 0.375em);
                        }
                        &.orientation {
                            & > * {
                                flex: 0 0 calc(50% - 0.25em);
                            }
                        }
                    }
                    .chrome-picker {
                        background-color: transparent !important;
                        box-shadow: unset !important;
                    }
                }
            }
        }
        &.url {
            min-width: 200px;
            .tool-inner-container {
                width: 100%;
                .tools {
                    width: 100%;
                    .tool {
                        padding: 0;
                        gap: 0.5em;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        .editor-menu-input {
                            flex-grow: 4;
                        }
                    }
                }
            }
        }
        &.videoSettings {
            .tool-inner-container {
                gap: 1em;
                .tools {
                    .tool {
                        padding: 0;
                        gap: 0.5em;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }
        }
        &.meet,
        &.stream {
            .tool-inner-container {
                gap: 1em;
                .tools {
                    .tool {
                        padding: 0;
                        gap: 0.5em;
                    }
                }
            }
        }
        &.poll {
            .tool-inner-container {
                gap: 1em;
                .tools {
                    .tool {
                        padding: 0;
                        justify-content: space-between;
                        gap: 1em;
                        .widget-select {
                            flex-grow: 2;
                        }
                    }
                    .poll-options {
                        .poll-options-container {
                            display: flex;
                            gap: 0.5em;
                            flex-wrap: wrap;
                            .poll-option {
                                padding: 0.05em 0.375em;
                                background-color: #fff;
                                border: 1px solid #bbb;
                                border-radius: 5px;
                            }
                        }
                    }
                    .time {
                        display: flex;
                        gap: 0.5em;
                        width: 100%;
                        justify-content: space-between;
                    }
                }
            }
        }
        &.image {
            .tool-inner-container {
                gap: 0.5em;
                .tools {
                    .tool {
                        padding: 0;
                        gap: 0.5em;
                        .editor-menu-input {
                            flex-grow: 4;
                        }
                    }
                    .editor-menu-button {
                        width: 100%;
                    }
                    .buttons {
                        & > * {
                            flex: 0 0 calc(25% - 0.375em);
                        }
                    }
                }
            }
        }
        &.overlay {
            max-width: 260px;
            .tool-inner-container {
                gap: 0.5em;
                .tools {
                    display: flex;
                    flex-direction: column;

                    .tool {
                        padding: 0;
                        display: flex;
                        gap: 0.5em;
                    }
                }
            }
        }

        &.background {
            max-width: 260px;
            .tool-inner-container {
                .tools {
                    gap: 0.5em;
                    display: flex;
                    flex-direction: column;
                    .tool {
                        justify-content: space-between;
                        padding: 0;
                    }
                    .buttons {
                        width: 100%;
                        display: flex;
                        gap: 0.5em;
                        flex-wrap: wrap;
                    }
                }
            }
        }
        &.padding,
        &.boxShadow,
        &.filter {
            .tool-inner-container {
                flex-direction: row;
                background: #fff;
                border-radius: 5px;
                border: 1px solid #bbb;
            }
        }
        &.text {
            .tool-inner-container {
                .tox {
                    border: none;
                    .tox-statusbar {
                        display: none;
                    }
                    .tox-editor-container {
                        .tox-editor-header {
                            background-color: transparent;
                            .tox-toolbar {
                                background: transparent;
                                .tox-toolbar__group {
                                    border: none;
                                    justify-content: space-between;
                                    .tox-tbtn,
                                    .tox-split-button {
                                        svg {
                                            fill: $liveto-text-gray !important;
                                        }
                                        &.tox-tbtn--enabled {
                                            svg {
                                                fill: $liveto-red !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .tox-sidebar-wrap {
                            border: 1px solid #bbb;
                        }
                    }
                }
            }
        }

        &.border {
            max-width: 260px;
            .tool-inner-container {
                gap: 1em;
                .tools {
                    display: flex;
                    flex-direction: column;
                    gap: 0.25em;

                    .buttons {
                        flex-wrap: wrap;
                        display: flex;
                        gap: 0.5em;
                        &.border-width {
                            & > * {
                                flex: 0 0 calc(20% - 0.4em);
                            }
                        }
                        &.border-style {
                            & > * {
                                flex: 0 0 calc(100% / 9 - 0.5em);
                                aspect-ratio: 1;
                            }
                            .widget-border-style-select {
                                cursor: pointer;
                            }
                            .widget-noborder-box {
                                cursor: pointer;
                                //margin: 0.2em;
                                width: 20px;
                                height: 20px;
                                overflow: hidden;
                                position: relative;
                                .line1 {
                                    width: 20px;
                                    height: 20px;
                                    border-bottom: 3px solid #555;
                                    transform: rotate(45deg) translateY(-8px);
                                    position: absolute;
                                }
                                .line2 {
                                    width: 20px;
                                    height: 20px;
                                    border-top: 3px solid #555;
                                    transform: rotate(-45deg) translateY(9px);
                                    position: absolute;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.alignment {
            max-width: 372px;
            .tool-inner-container {
                padding-top: 0.8em;
                label {
                    font-weight: 700;
                }
                .tools {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 0.5em;
                    .tool {
                        flex-direction: column;
                        border: unset;
                        background-color: transparent;
                        padding: 0;
                        align-items: flex-start;
                        font-size: 9px;
                        .simple-button {
                            padding: 0;
                        }
                    }
                }
            }
        }
        .alignment-container {
            display: flex;
            flex-direction: column;
            gap: 0.5em;
        }
        .tool-inner-container {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            justify-content: space-between;

            .sketch-picker {
                width: auto !important;
                padding: 0 !important;
                box-shadow: unset !important;
                background-color: transparent !important;
            }

            .tools {
                display: flex;
                flex-direction: column;
                gap: 0.5em;
                .buttons {
                    width: 100%;
                    display: flex;
                    gap: 0.5em;
                    flex-wrap: wrap;
                }
            }

            .tool {
                display: flex;
                padding: 0.1em 0.5em;
                .value {
                    display: flex;
                    .padding-input {
                        width: 40px;
                        font-weight: 700;
                        border: unset !important;
                        text-align: right;
                        padding-right: 0.5em;
                        &:focus {
                            outline: none !important;
                        }
                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        &[type="number"] {
                            -moz-appearance: textfield;
                        }
                    }
                }
                .simple-button {
                    border: unset;
                    border-radius: 5px;
                    background: transparent;
                    color: #bbb;
                    .flex-rect-main {
                        stroke: $liveto-gray;
                        fill: $liveto-gray;
                        fill-opacity: 0.2;
                    }
                    .flex-rect-small {
                        stroke: $liveto-medium-gray;
                        fill: $liveto-gray;
                    }
                    .flex-num {
                        fill: $liveto-text-gray;
                    }
                    svg {
                        fill: #bbb;
                    }
                    &.active {
                        color: #3f3f3f;
                        svg {
                            fill: #3f3f3f;
                        }
                        .flex-rect-main {
                            fill: $liveto-medium-gray;
                            fill-opacity: 1;
                        }
                        .flex-rect-small {
                            fill: #fff;
                        }
                    }
                }
            }

            .padding + .padding {
                border-left: 1px solid #bbb;
            }
        }
        &::before {
            //arrow
            content: "";
            position: absolute;
            top: -10px;
            left: 50%;
            border-style: solid;
            border-width: 10px 10px 0;
            border-color: #f6f6f6 transparent;
            display: block;
            width: 0;
            z-index: 1;
            transform: rotateX(180deg) translateX(-50%);
        }
    }
    cursor: pointer;
    &:hover {
        color: $liveto-light-gray;
    }
}
.virtual-event-toolbar-actions {
    grid-area: actions;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .buttons {
        display: flex;
        height: 100%;
        a {
            height: 100%;
        }
        .editor-header-link {
            &.action {
                font-weight: 700;
                color: $liveto-red;
                font-size: 14px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0;
            }
        }
    }

    .darkmode-checkbox-container {
        padding: 0.1rem 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .darkmode-checkbox {
            border: none;
        }
    }
    .language-selector {
        padding: 0.1em 0.375em;
        border-radius: 10px;
    }
    .save-button {
        position: relative;
        &.active {
            cursor: pointer;
            border-radius: $pill-border-radius;
            background-color: #fff;
        }
        .toolbar-save-dropdown {
            display: flex;
            flex-direction: column;
            gap: 0.5em;
            right: 0;
            position: absolute;
            top: calc(100% + 20px);
            background-color: #f6f6f6;
            color: #3f3f3f;
            padding: 0.5em 0.75em;
            border-radius: 5px;
            box-shadow: $liveto-above-all;
            transform-origin: top;
            cursor: default;
            .save-button {
                white-space: nowrap;
                border: unset;
                background-color: transparent;
                padding: 0.2rem 0.6rem;
                border-radius: 5px;
                width: 100%;
                &:hover {
                    background-color: #ddd;
                }
            }
        }
    }
}
.editor-nav {
    grid-area: nav-left;
    position: relative;
}

.header {
    grid-area: toolbar;
    position: relative;
    .heading-template {
        height: 56px;
        width: 100%;
        background-color: #fff;
    }
}

// EditorContent / Layout the next div is the container of the grid helper and the actual grid
.virtual-event-editor-view-layout {
    &.dark {
        .virtual-event-main-toolbar {
            background-color: $liveto-dark-gray;
            color: $liveto-light-blue;
            .main-title {
                color: $liveto-teal;
            }
            .virtual-event-toolbar-actions {
                .save-button {
                    &.active {
                        color: $liveto-black;
                    }
                    .toolbar-save-dropdown {
                        background-color: $liveto-dark-gray;
                        .save-button {
                            color: $liveto-light-blue;
                            &:hover {
                                background-color: $liveto-text-gray;
                            }
                        }
                    }
                }
            }
            .virtual-event-toolbar-widgets {
                .toolbar-widget-container {
                    .widget-wrapper {
                        svg {
                            fill: $liveto-light-blue;
                        }
                    }
                    .tool-container {
                        background-color: $liveto-dark-gray;
                        color: $liveto-light-blue;
                        border-color: $liveto-gray;
                        &.padding,
                        &.boxShadow {
                            .tool-inner-container {
                                background-color: $liveto-text-gray;
                            }
                        }
                        &.alignment {
                            .tool-inner-container {
                                .tool {
                                    .flex-rect-main {
                                        stroke: $liveto-gray;
                                        stroke-opacity: 0.5;
                                    }
                                }
                            }
                        }
                        .tool-inner-container {
                            background-color: transparent;
                            color: $liveto-light-blue;
                            .tools {
                                .buttons,
                                .tool {
                                    .editor-menu-button {
                                        color: $liveto-light-blue;
                                        background-color: $liveto-text-gray;
                                        border-color: $liveto-medium-gray;
                                        &:not(.active):hover {
                                            background-color: $liveto-medium-gray;
                                            color: $liveto-text-gray;
                                        }
                                        &.active {
                                            color: $liveto-teal;
                                        }
                                    }
                                    &.border-style {
                                        .widget-noborder-box {
                                            .line1,
                                            .line2 {
                                                border-color: $liveto-medium-gray;
                                            }
                                        }
                                    }
                                    .widget-select {
                                        background-color: $liveto-text-gray;
                                        color: $liveto-light-blue;
                                    }
                                }
                                .editor-menu-input {
                                    background-color: $liveto-text-gray;
                                    color: $liveto-light-blue;
                                }
                                .sketch-picker {
                                    input {
                                        background-color: $liveto-text-gray;
                                        color: $liveto-light-blue;
                                        border: unset !important;
                                        box-shadow: unset !important;
                                    }
                                    label {
                                        color: $liveto-light-blue !important;
                                    }
                                    .flexbox-fix {
                                        border: unset !important;
                                    }
                                }
                            }
                            .tool {
                                .simple-button {
                                    color: $liveto-light-blue;
                                    svg {
                                        fill: $liveto-dark-gray;
                                    }
                                    &.active {
                                        color: $liveto-teal;
                                        svg {
                                            fill: $liveto-light-blue;
                                        }
                                    }
                                }
                                .padding-input {
                                    background-color: $liveto-text-gray;
                                    color: $liveto-light-blue;
                                }
                            }
                        }
                        &::before {
                            border-color: $liveto-dark-gray transparent;
                        }
                    }
                }
            }
        }
    }
    &.desktop {
        #editor-layout > div:first-child {
            max-width: 1156px;
            margin: 0 auto 0 1rem;
            position: relative;
            min-height: 100%;
            border: dotted 2px #222222;
        }
    }
    &.tablet {
        #editor-layout > div:first-child {
            max-width: 772px;
            margin: 0 auto;
            position: relative;
            min-height: 100%;
            border: dotted 2px #222222;
        }
    }
    &.mobile {
        #editor-layout > div:first-child {
            max-width: 388px;
            margin: 0 auto;
            position: relative;
            min-height: 100%;
            border: dotted 2px #222222;
        }
    }
}

#editor-layout {
    grid-area: content;
    grid-column: 2 / -1;
    overflow-x: scroll;
    width: 100%;
    padding-top: 1rem;
    transition: padding-right 0.3s linear;
}

// Grid overlay
.editor-grid {
    width: 100%;
    min-height: 100%;
    position: absolute;
    z-index: 3;
    pointer-events: none;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    display: flex;
    justify-content: center;
    &.active {
        opacity: 1;
        &.desktop {
            @include calc_grid($--desktop-columns, $--desktop-max_width, $--desktop-step);
        }
        &.tablet {
            @include calc_grid($--tablet-columns, $--tablet-max_width, $--tablet-step);
        }
        &.mobile {
            @include calc_grid($--mobile-columns, $--mobile-max_width, $--mobile-step);
        }
    }
}
// Left navigation panel
.editor-navigation-left {
    position: relative;
    grid-area: nav-left;
    border-radius: 16px;
    margin-top: 1rem;
    margin-left: 1.5rem;
    margin-right: auto;
    width: 94%;
    height: 96%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: $liveto-white;
    box-shadow: $main-layout-shadow;
    &.dark {
        .virtual-event-templates {
            background-color: $liveto-dark-gray;
            .navright-header {
                color: $liveto-teal;
                border-color: $liveto-text-gray;
            }
            .template-row {
                .template-dropdown {
                    color: $liveto-light-blue;
                    &.show {
                        .title {
                            color: $liveto-teal;
                        }
                    }
                    .title {
                        &.disabled {
                            color: $liveto-text-gray;
                        }
                        &:not(.disabled):hover {
                            color: $liveto-teal;
                        }
                    }
                    .template-list {
                        .template {
                            .template-card {
                                background-color: $liveto-text-gray;
                            }
                        }
                    }
                }
            }
            .footer {
                border-color: $liveto-text-gray;
                color: $liveto-light-blue;
                .templates-save-icon {
                    color: $liveto-teal;
                }
                .button-container {
                    &:hover {
                        color: $liveto-teal;
                    }
                }
            }
        }
        background-color: $liveto-text-gray;
        .editor-navigation-left-links {
            background-color: $liveto-dark-gray;
            border-color: rgb(85, 85, 85);
            .navleft-link {
                background-color: $liveto-dark-gray;
                color: $liveto-light-blue;
                &.active {
                    color: $liveto-teal;
                    border-bottom: 1px solid $liveto-teal;
                }
                &:hover {
                    color: $liveto-teal;
                }
            }
        }
        .editor-history {
            color: $liveto-light-blue;
            .history-label {
                &.active {
                    color: $liveto-teal;
                }
            }
        }
    }
    .editor-navigation-left-links {
        border-bottom: 2px solid #f1f1f185;
        display: flex;
        width: 100%;
        & > * {
            flex: 1 0 25%;
        }

        .navleft-link {
            cursor: pointer;
            padding: 0.75em 0;
            text-align: center;
            color: $liveto-black;
            font-size: 12px;
            font-weight: 700;
            transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border 0.2s ease-in-out;
            &.active {
                color: $liveto-red;
                border-bottom: 1px solid $liveto-red;
            }
            &:hover {
                color: $liveto-red;
            }
        }
    }

    .virtual-event-editor-tree {
        z-index: $z-mid;
        position: absolute;
        overflow: hidden;
        inset: 0;
        top: 38px;
        max-height: 100%;
        width: 100%;
        .tree-content {
            height: 100%;
            overflow: auto;
            position: relative;
            &::-webkit-scrollbar-thumb {
                background: $liveto-red;
            }
            &::-webkit-scrollbar {
                width: 3px;
            }
            & > div {
                // The forked tree dependency root node
                height: 100%;
                overflow: unset;
            }
        }
    }
    .editor-history {
        transition: transform 0.25s ease-in;
        font-size: 14px;
        padding: 1rem;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        position: absolute;
        top: 45px;
        width: 100%;
        &.templates {
            transform: translateX(-100%);
        }
        &.history {
            transform: translateX(0%);
        }
        &.sitemap,
        &.components {
            transform: translateX(100%);
        }
        .history-label {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            &:hover {
                transform: scale(1.01);
            }
            &.active {
                color: $liveto-red;
            }
            .history-time {
                font-weight: normal;
            }
        }
    }
    .editor-sitemap {
        z-index: $z-mid;
        position: sticky;
        position: absolute;
        inset: 0;
        top: 38px;
        overflow-y: auto;
        width: 100%;
        animation-timing-function: ease-in;
        animation-duration: 0.25s;
        animation-fill-mode: forwards;
        transition: transform 0.25s ease-in;
        transform: translateX(-100%);

        &.active {
            transform: translateX(0%);
        }
        &.dark {
            background: $liveto-dark-gray;
            color: $liveto-teal;
            .sitemap-room-item .sitemap-link {
                color: $liveto-light-blue;
                svg {
                    path {
                        fill: $liveto-light-blue !important;
                        color: $liveto-light-blue !important;
                    }
                }
                &.active {
                    color: $liveto-teal;
                    svg {
                        path {
                            fill: $liveto-teal !important;
                            color: $liveto-teal !important;
                        }
                    }
                }
            }
            .sitemap-room-item:hover .sitemap-link {
                color: #00d2d9;
                background-color: #5e5e5e;
            }
        }
    }
    .sitemap-room-item {
        .sitemap-link {
            display: flex;
            padding: 0.5rem 1.5rem;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
            color: $liveto-text-gray;
            &.active {
                color: $liveto-red;
                svg {
                    path {
                        fill: $liveto-red !important;
                        color: $liveto-red !important;
                    }
                }
            }
        }
        &:hover {
            .sitemap-link {
                background-color: $liveto-light-gray;
            }
        }
    }
}

.template-image {
    max-width: 100%;
    width: 100%;
    object-fit: contain;
}
.single-category-list {
    animation-name: templateListContentsCollapse;
    animation-duration: 0.2s;
    animation-direction: normal;
    animation-timing-function: linear;
    &.show {
        animation-name: templateListContentsExpand;
    }
}
.template-container {
    transform-origin: top;
    animation-name: templateListCollapse;
    animation-duration: 0.2s;
    animation-direction: normal;
    animation-timing-function: linear;
    &.show {
        animation-name: templateListExpand;
    }
    margin: 0.5em 0;
    padding: 0.3em;
    border: solid 2px lightgray;
    &.selected {
        border: solid 2px green;
    }
}
// Bottom Toolbar

.editor-bottom-toolbar {
    position: fixed;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in;
    right: 2rem;
    overflow: hidden;
    border-radius: 50vmin;
    background-color: #c4c4c4;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.6;
    z-index: $z-top;
    &.active {
        opacity: 1;
    }
}

.toggle-button-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 180ms ease, opacity 240ms ease;
    gap: 0;
}

.toggle-editlock {
    width: 100%;
}

.toggle-button {
    padding: 6px 12px;
    position: relative;
    border: none;
    transition: background-color 180ms ease, opacity 240ms ease;
    background-color: $liveto-gray;
    opacity: 0.85;
    display: flex;
    justify-content: center;
    width: 100%;
    &:first-child {
        padding-top: 1.5rem;
    }
    &:last-child {
        padding-bottom: 1.5rem;
    }
    svg {
        fill: $liveto-black;
    }
    &:hover {
        background-color: $liveto-light-gray;
        i,
        svg {
            transform: scale(1.125);
        }
    }
    &.active {
        color: $liveto-red;
        svg {
            fill: $liveto-red;
            path {
                fill: $liveto-red;
            }
        }
    }
    // click animation for the icons
    &:active {
        & > i {
            transform: scale(0.95);
        }
    }
    // Icons
    & > i {
        margin: auto;
        transform: scale(1);
        transition: color 150ms ease-in-out, transform 180ms ease, background-color 180ms ease,
            opacity 200ms ease-in-out;
    }
}

.editor-bottom-toolbar.dark .toggle-button {
    &.active i {
        color: $liveto-teal;
    }
}
// Divider between changeview buttons and showgrid/bg
.bottom-divider {
    margin: 0.25rem 0.5rem;
    width: 3em;
    border-radius: 50vmin;
    height: 3px;
    background: #ffffff7d;
}
// the red disabled line
.toggle-disabled-line {
    position: absolute;
    bottom: 0.4rem;
    left: 2rem;
    width: 2px;
    height: 2.5rem;

    transform-origin: left;
    background-color: $liveto-pink;
    transform: rotate(45deg) scaleX(0);
    transition: transform 70ms ease-in-out;
    &.active {
        transform: rotate(45deg) scaleX(1);
    }
    &.grid {
        bottom: 1.1rem;
    }
}
// Used for React side toggle state, 1.5x bigger than the actual buttons for leeway
.hover-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform-origin: center;
    width: 100%;
    height: 100%;
}

.toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: content-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    border: 2px solid $liveto-dark-gray;
    box-shadow: inset -2px 2px 4px 1px black;
    overflow: hidden;
    border-radius: 20px;
    &.dark {
        border: 2px solid #00575a;
    }
    &-checkbox {
        display: none;
    }
    &-label {
        display: block;
        cursor: pointer;
        margin: 0;
        position: relative;
        i {
            position: absolute;
        }
    }
    &-inner {
        display: block;
        width: 200%;
        margin-left: 0%;
        transform: translateX(-50%);
        transition: transform 0.3s ease-in;

        i:nth-child(1) {
            top: 5px;
            left: 10px;
            right: unset;
            color: #5a5a5a96;
            &:after {
                background-image: none;
            }
        }
        i:nth-child(2) {
            top: 5px;
            right: 10px;
            color: white;
            left: unset;
            &:after {
                background-image: radial-gradient(white, transparent 40% 100%);
            }
        }
        i:after {
            content: "";
            position: absolute;
            inset: 0;
        }
        &:before,
        &:after {
            box-shadow: inset 2px 2px 6px 0px #0000007a;
            border-radius: 18px;
            display: block;
            float: left;
            width: 50%;
            height: 34px;
            padding: 0;
            content: "";
            text-transform: uppercase;
            padding-left: 10px;
            line-height: 34px;
            font-size: 14px;
            color: white;
            font-weight: bold;
            box-sizing: border-box;
        }
        &:before {
            content: "";
            text-transform: uppercase;
            padding-left: 10px;
            background-color: $liveto-teal;
            color: $liveto-white;
        }
    }
    &-disabled {
        background-color: #ddd;
        cursor: not-allowed;
        &:before {
            background-color: #ddd;
            cursor: not-allowed;
        }
    }
    &-inner:after {
        content: "";
        text-transform: uppercase;
        padding-right: 10px;
        background-color: $liveto-red;
        color: #fff;
        text-align: right;
    }
    &-switch {
        display: block;
        width: 24px;
        height: 24px;
        margin: 5px;
        background-color: $liveto-pink;
        box-shadow: -1px 1px 1px 0px #ffffff40, 0px 2px 3px 0px #101010e0;
        position: absolute;
        top: 0;
        bottom: 0;
        transform: translateX(15%);
        border-color: $liveto-dark-gray; //$liveto-white;
        border-bottom-width: 1px;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;
        &:hover {
            background-color: $liveto-text-gray;
        }
    }
    &-checkbox:checked + &-label {
        .toggle-switch-inner {
            transform: translateX(0%);
        }
        .toggle-switch-switch {
            background-color: #00575a; //$liveto-dark-gray;

            transform: translateX(165%);
            &:hover {
                background-color: $liveto-text-gray;
            }
        }
    }
}

.add-modal-dialog {
    text-align: center;
    display: flex;
    justify-content: center;
    .addModalOverrides {
        //min-height: 50vh;
        height: auto;
        width: 550px;
        border-radius: 20px;
        background-color: #fafafa;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
        overflow: hidden;
        min-height: 450px;

        .add-modal-header {
            background-color: $liveto-light-pink;
            color: #000;
            font-weight: 700;
            padding: 0.5em 1em;
        }
        .add-modal-body {
            overflow: hidden;
            display: flex;
            width: 1100px;
            position: relative;
            transition: transform 0.5s ease-in-out;
            &.page-2 {
                transform: translateX(-50%);
            }
            .add-modal-inner-content {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 2em;
                gap: 1em;
                .nav-links {
                    display: flex;

                    flex-wrap: wrap;
                    gap: 1em;
                    & > * {
                        flex: 0 0 calc(25% - 1em);
                    }
                    .component-link-card {
                        background-color: transparent;
                        width: 100px;
                        height: 100px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;
                        border-radius: 5px;
                        transition: all 0.25s ease-in;
                        font-size: 12px;
                        padding-bottom: 0.5em;
                        &:hover {
                            box-shadow: $liveto-above-all;
                            z-index: 10;
                            cursor: pointer;
                        }
                    }
                }
                .add-modal-description {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                }
                .settings-page {
                    display: flex;
                    flex-direction: column;
                    gap: 1em;
                    align-items: center;
                    .icon-wrapper {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;
                    }
                    .selector {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 0.5em;
                        .modal-back-button {
                            justify-content: center;
                            border-radius: 10px;
                            margin-top: 1em;
                            &:hover {
                                box-shadow: $liveto-above-all;
                                cursor: pointer;
                            }
                            &:active {
                                box-shadow: inset $liveto-above-all;
                            }
                            &:disabled {
                                color: #bbb;
                            }
                        }
                    }
                }
            }
        }
    }
}

// PREVIEW MODAL
@media screen and (max-height: 800px) {
    .preview-modal-inner-content {
        &.mobile {
            width: 320px !important;
            height: 655px !important;
            .virtual-event-header {
                height: 50px !important;
                .event-image {
                    height: 40px !important;
                }
                &.mobile {
                    .header-button {
                        padding: 10px 5px !important;
                        svg {
                            width: 20px !important;
                        }
                    }
                    .virtual-profile-icon {
                        height: 35px !important;
                        width: 35px !important;
                    }
                }
            }
        }
    }
}
.preview-modal-backdrop {
    &.show {
        opacity: 0.8;
    }
}
.preview-modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .preview-modal-dialog {
        margin: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .preview-modal-content {
            width: 100%;
            height: 100%;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            border: unset;
            &.mobile,
            &.tablet {
                width: auto;
                .preview-content-container {
                    position: relative;
                    .black-box {
                        position: sticky;
                        top: 0;
                        height: 50px;
                        width: 100%;
                        background-color: rgb(0, 0, 0);
                        z-index: $z-top;
                    }
                }
            }

            .preview-view-selector {
                position: fixed;
                left: 1.5rem;
                top: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: auto;
                background-color: rgba(255, 255, 255, 0.5);
                padding: 0.8rem;
                border-radius: 15px;
                gap: 0.5rem;
                z-index: $z-top;

                svg {
                    cursor: pointer;
                    fill: $liveto-black;
                    &.active {
                        fill: $liveto-red;
                        path {
                            fill: $liveto-red;
                        }
                    }
                    &:hover {
                        transform: scale(1.1);
                    }
                    path {
                        fill: $liveto-black;
                    }
                }
            }
            .preview-content-container {
                background-color: var(--preview-bg-color);
                background-position: var(--preview-bg-position);
                background-repeat: var(--preview-bg-repeat);
                background-image: var(--preview-bg-image);
                background-size: var(--preview-bg-size);
                display: flex;
                justify-content: center;
                width: auto;
                width: calc(100% - 2rem);
                height: calc(100% - 2rem);
                position: relative;
                display: unset;
                overflow: hidden;
                *,
                * > * {
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */
                }

                .virtual-event-header {
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 70px;
                    background: rgba(255, 255, 255, 0.8);
                    z-index: 11;
                    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px 0px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    transition: all 0.4s ease-in-out;
                    &.mobile {
                        width: 100%;
                        top: 50px;
                        padding: 0 1rem;
                        .event-image {
                            display: flex;
                        }
                    }
                    &.tablet {
                        .event-image {
                            display: flex;
                        }
                    }

                    .header-button-text {
                        margin: 0 10px;
                        font-size: 15px;
                    }
                    .event-name {
                        font-size: 20px;
                        font-weight: bold;
                        padding-left: 20px;
                    }
                    .event-image {
                        display: none;
                        width: 60px;
                        height: 60px;
                        margin: 5px 0 5px 5px;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    #header-online-count {
                        background: transparent;
                        border: none;
                        padding: 20px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        outline: none;
                        font-weight: bold;
                        .dot {
                            height: 12px;
                            width: 12px;
                            border-radius: 50%;
                            display: inline-block;
                            margin: 0px 10px 0px 17px;
                        }
                    }

                    .personal-calendar-icon-container {
                        margin-left: 1rem;
                        color: rgb(175, 175, 175);
                        cursor: pointer;

                        .personal-calendar-icon:hover {
                            color: #d62b1e;
                        }
                    }

                    .notification-bell {
                        color: rgb(175, 175, 175);
                        position: relative;
                        cursor: pointer;
                        .notification-amount {
                            height: 8px;
                            width: 8px;
                            bottom: 0px;
                            right: 0px;
                            position: absolute;
                            border-radius: 50%;
                            background-color: greenyellow;
                            border: solid 1px #000;
                        }
                        &:hover {
                            color: #d62b1e;
                        }
                    }

                    .user-options {
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .header-button {
                            background: transparent;
                            border: none;
                            padding: 20px 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            outline: none;
                            .calendar-button,
                            .polls-button {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                            }
                            .message-notification {
                                font-size: 13px;
                                border-radius: 50%;
                                font-weight: bold;
                                margin: 0px 5px;
                                padding: 0px;
                            }

                            .header-button-name {
                                margin: 0;
                            }
                            .virtual-profile-icon {
                                background-color: #fafafa;
                                //border: solid 2px #ddd;
                                border-radius: 50%;
                                height: 50px;
                                width: 50px;
                                margin-left: 5px;
                                color: #555;
                                background-size: cover;
                                background-repeat: no-repeat;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;

                                &.guest-border {
                                    border: solid 2px #ddd;
                                }
                            }

                            font-weight: bold;
                        }
                    }
                }
                .preview-modal-inner-content {
                    &.desktop {
                        display: flex;
                        height: 100%;
                        .virtual-event {
                            padding-left: 10px;
                        }
                    }
                    &.mobile {
                        width: 360px;
                        height: 722px;
                        position: relative;
                        overflow-y: auto;
                        .preview-mobile-container {
                            position: fixed;
                            z-index: $z-top;
                            pointer-events: none;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    &.tablet {
                        width: 780px;
                        height: 100%;
                        position: relative;
                        .preview-mobile-container {
                            position: fixed;
                            z-index: $z-top;
                            pointer-events: none;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            height: 832px;
                            width: 600px;
                            overflow: hidden;
                            .virtual-event-navigator {
                                position: absolute;
                                margin-top: 4.5%;
                                bottom: 1rem;
                                margin-left: 5%;
                                background: rgba(255, 255, 255, 0.8);
                                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
                                height: auto;
                            }
                            .tablet-frame {
                                position: fixed;
                                z-index: $z-top;
                                pointer-events: none;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                    .virtual-event-view {
                        width: 100%;
                        max-width: 1700px;
                        height: 100%;
                        min-height: 100dvh;
                        margin: 0 auto;
                        display: flex;
                        align-items: flex-start;
                        flex-direction: row;
                        &.desktop {
                            padding-right: 10px;
                        }
                        &.mobile {
                            padding-top: 50px;
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                        .virtual-event {
                            width: 100%;
                            height: inherit;
                            display: flex;
                            flex-direction: row;
                            position: relative;
                            .virtual-event-content {
                                overflow-y: auto;
                                &::-webkit-scrollbar {
                                    display: none;
                                }
                                -ms-overflow-style: none; /* IE and Edge */
                                scrollbar-width: none; /* Firefox */
                                position: relative;
                                .preview-overlay {
                                    position: absolute;
                                    width: 100%;
                                    z-index: $z-top;
                                }
                                &.desktop {
                                    max-width: 1120px;
                                    padding: 0 10px 30px 10px;
                                    height: 90%;
                                    .preview-overlay {
                                        height: 100vw;
                                    }
                                }
                                &.mobile {
                                    padding: 0 0 60px 0;
                                }
                                &.tablet {
                                    padding: 0 10px;
                                    height: 90%;
                                }
                                &.mobile {
                                    overflow-y: unset;
                                    .preview-overlay {
                                        height: 110vw;
                                    }
                                }
                                height: 100%;
                                width: 100%;
                                background: transparent;
                                position: relative;
                            }
                            .virtual-event-navigator {
                                display: flex;
                                left: 0;
                                z-index: 1;

                                &.desktop {
                                    width: 200px;
                                    min-width: 200px;
                                    position: -webkit-sticky;
                                    position: sticky;
                                    top: 80px;
                                    height: calc(100dvh - 90px - 4rem);
                                    flex-direction: column;
                                    border-radius: 5px;
                                    background: rgba(255, 255, 255, 0.8);
                                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

                                    .navigator-content {
                                        &.transparent-dark {
                                            background: rgba(0, 0, 0, 0.5);
                                        }
                                    }
                                }
                                &.tablet {
                                    width: 100px;
                                    min-width: 100px;
                                    position: -webkit-sticky;
                                    position: sticky;
                                    top: 70px;
                                    height: calc(100dvh - 70px);
                                    flex-direction: column;
                                    background: rgba(255, 255, 255, 0.8);
                                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
                                    .navigator-logo-container {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .liveto-chat {
                            &::-webkit-scrollbar {
                                display: none;
                            }
                            -ms-overflow-style: none; /* IE and Edge */
                            scrollbar-width: none; /* Firefox */
                            height: calc(100dvh - 90px - 4rem);
                            width: 380px;
                            min-width: 380px;
                            position: sticky;
                            top: 80px;
                            border-radius: 2px;
                            transition: all 0.4s ease-in-out;
                            display: flex;
                            flex-direction: column;
                            background: rgba(255, 255, 255, 0.8);
                            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
                            z-index: 10;
                            overflow-x: hidden;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .virtual-event-main-toolbar {
        grid-template-columns: [title] calc(min(380px, 10%)) [widgets] 1fr [actions] calc(min(400px, 25%));
        .main-title {
            font-size: 20px;
        }
        .virtual-event-toolbar-widgets,
        .virtual-event-toolbar-actions {
            gap: 0.5rem;
        }
    }
}
