@font-face {
  font-family: "Roboto";
  src: url("https://storage.googleapis.com/liveto-api/static/webfonts/roboto-webfont/roboto-regular-webfont.woff2");
  src: url("https://storage.googleapis.com/liveto-api/static/webfonts/roboto-webfont/roboto-regular-webfont.woff")
    format("woff");
}
body {
  font-family: "Roboto";
  font-style: normal;
  height: 100%;
}
p {
  font-size: 17px;
}

h1 {
  font-size: 48px;
}
.h3-red {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  /* or 103% */

  letter-spacing: -0.0776113px;

  /* Liveto punainen */

  color: #c4281a;
}
a {
  color: #d62b1e;
}
.search-bar-container {
  min-height: 54px;
  max-height: 54px;
}
.search-bar-dropdown-container {
  margin-top: 0px;
  border-right: 2px solid rgba(151, 151, 151, 0.45);
  min-height: 54px;
  max-height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.search-bar-dropdown {
  width: 100%;
  background-color: white;
  height: 50px;
  border: none;
}
.search-bar-dropdown:focus {
  outline: none;
}
.search-bar-line {
  border-top: 2px solid rgba(151, 151, 151, 0.45);
}
.search-bar-dropdown-container > div {
  min-height: 54px;
  max-height: 54px;
  width: 100%;
}
.search-bar-dropdown__control {
  border: none !important;
  min-height: 54px !important;
  max-height: 54px !important;
  width: 100%;
}
.search-bar-dropdown__control:hover {
  border: none !important;
  box-shadow: none !important;
}
.search-bar-dropdown__control--is-focused {
  border: none !important;
  box-shadow: none !important;
}
.search-bar-dropdown__placeholder,
.search-bar-dropdown__single-value {
  color: #d62b1e !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.0246945px;
  width: 100%;
}
.search-bar-dropdown__indicator-separator {
  display: none;
}
.tag-checkbox {
  width: 18px;
  height: 18px;
}
.tag-custom-label {
  margin-left: 5px;
}
#tags-collapse {
  padding: 10px;
}
.search-bar-button-caret {
  color: #d62b1e;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.0246945px;
  color: #4c4c4c;
}
.search-bar-search-input {
  background-color: #f3f3f3 !important;
}
.search-bar-search-input-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.0352779px;
  line-height: 54px;
  color: #4c4c4c;
}
.search-bar-dropdown-menu {
  padding: 12px;
}

@media only screen and (max-width: 990px) {
  .search-bar-dropdown-text {
    font-size: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .search-bar-search-input {
    margin-left: 10px;
    display: inline-block;
    width: 60%;
  }
  .search-input-container {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .search-bar-container {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .search-bar-dropdown-container {
    border-right: none;
  }
}
