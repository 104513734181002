@import "globals/sass/_mixin.scss";

#virtual-event-moderation {
  width: 98%;
  height: calc(100dvh - 90px);
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  overflow: hidden;
}

.moderation-modal {
  .loading {
    display: flex;
    width: 100%;
    height: 50vh;
    justify-content: center;
    align-items: center;
  }
  .moderation-input {
    border-width: 1px;
    border-radius: 4px;
  }
  .modal-content-section {
    &.desktop {
      //padding-top: 80px;
    }
    .navigation {
      width: 15%;
      border-right: 1px solid $liveto-medium-light-gray;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      gap: 0.5rem;
    }
    .moderation-content {
      width: 100%;
      //overflow-y: auto;
      .toggle {
        padding: 1rem;
        border-top: 1px solid $liveto-medium-light-gray;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        &.chatroom-settings {
          border-top: unset;
        }
        .header {
          font-weight: 700;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
        }
      }
      .moderation-section-header {
        padding: 1rem;
        width: 100%;
        border-bottom: 1px solid $liveto-medium-light-gray;
        color: $liveto-red;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-left-section {
          display: flex;
          gap: 1rem;
        }
        .close-button {
          border: unset;
          background: transparent;
        }
      }
      .chat-moderation-content {
        display: flex;
        flex-direction: column;
        .info-tooltip {
          border-top: unset;
          border-bottom: 1px solid $liveto-medium-light-gray;
          .info-content {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .info-group {
              display: flex;
              gap: 0.5rem;
              align-items: center;
            }
          }
        }
        .msg-card-button {
          border: unset;
          color: $liveto-white;
          display: flex;
          align-items: center;
          padding: 0 0.4rem 0.2rem 0.4rem;
          border-radius: 5px;
          justify-content: center;
          border: 1px solid $liveto-black;
          gap: 0.25rem;
          span {
            margin-top: 0.2rem;
          }
          &:hover {
            transform: scale(1.02);
          }
          &.confirm {
            background-color: $liveto-green;
            border-color: $liveto-green;
            &.submit {
              padding-bottom: 0;
            }
          }
          &.decline {
            background-color: $liveto-red;
            border-color: $liveto-red;
          }
          &.blacklist {
            background-color: $liveto-white;
            color: $liveto-black;
            &.active {
              background-color: $liveto-black;
              color: $liveto-white;
            }
          }
        }

        .chatroom-tabs {
          padding: 1rem;
          display: flex;
          gap: 1rem;
          border-bottom: 1px solid $liveto-medium-light-gray;
          .info-group {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }
          .selector-container {
            width: 100%;
            .room-setting-selector {
              width: 100%;
            }
          }
        }
        .moderation-main-section {
          overflow-y: auto;
          height: 100%;
          width: 100%;
          .moderation-info {
            padding: 1rem;
          }
          .chat-messages {
            padding: 1rem;
            width: 100%;
            max-height: calc(100dvh - 14rem);
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            overflow-y: auto;
            .message-card {
              padding: 0.5rem 0.75rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border: 1px solid $liveto-medium-light-gray;
              border-radius: 10px;
              box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
              gap: 0.5rem;
              width: 100%;
              .msg-data {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                gap: 0.5rem;
                .message-header {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  align-items: center;
                  .user-data {
                    display: flex;
                    align-content: center;
                    .username {
                      font-weight: 700;
                      display: flex;
                      align-content: center;
                      flex-direction: column;
                      justify-content: center;
                      word-break: break-word;
                    }
                    .message-icon {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 40px;
                      height: 40px;
                      min-width: 40px;
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: cover;
                      border-radius: 25px;
                      font-weight: bold;
                      margin-right: 10px;
                      max-height: 40px;
                      font-size: 15px;
                      color: #000;
                      border: solid 1px #ddd;
                      margin-right: 10px;
                      background-color: rgba(255, 255, 255, 0.5);
                      .crown {
                        position: absolute;
                        top: -20px;
                      }
                    }
                  }

                  .timestamp {
                    font-size: 12px;
                    color: $liveto-text-gray;
                    height: 100%;
                    white-space: nowrap;
                  }
                }

                .room-name {
                  font-size: 12px;
                  color: $liveto-black;
                  display: flex;
                  gap: 0.25rem;
                }
                .message {
                  font-size: 14px;
                  display: flex;
                  flex-wrap: wrap;
                  gap: 0.25rem;
                  .splitted-word {
                    border: 1px solid $liveto-medium-light-gray;
                    padding: 0 0.2rem;
                    border-radius: 0.25rem;
                    cursor: pointer;
                    &.added {
                      background-color: $liveto-red;
                      color: $liveto-white;
                      border-color: $liveto-red;
                    }
                  }
                  .message-word {
                    word-break: break-word;
                    &.triggered {
                      color: $liveto-red;
                    }
                  }
                }
              }
              .message-footer {
                display: flex;
                justify-content: space-between;
                gap: 1rem;
                width: 100%;
                .buttons {
                  display: flex;
                  gap: 0.75rem;
                  width: 100%;
                  align-items: center;
                  .button-wrapper {
                    display: flex;
                    gap: 0.25rem;
                    align-items: center;
                  }
                  .confirm {
                    border-radius: 5px;
                    &.blocklist-words {
                      padding: 0.1rem 0.5rem;
                    }
                  }
                }
                .info {
                  white-space: nowrap;
                  font-weight: 700;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  &.success {
                    color: $liveto-green;
                  }
                  &.error {
                    color: $liveto-red;
                  }
                }
              }
            }
          }
        }
      }
      .moderation-main-section {
        overflow-y: auto;
        &.settings {
          max-height: calc(100dvh - 65px);
        }
        .selector-container {
          width: 100%;
          .room-setting-selector {
            width: 100%;
          }
        }
        .moderation-settings-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          .description {
            font-size: 12px;
            color: $liveto-text-gray;
          }
          .moderation-general-settings {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 1rem;
            .moderation-single-setting {
              .values {
                display: flex;
                width: 100%;
                align-items: center;
                gap: 1rem;
                .setting-label,
                .setting-value {
                  display: flex;
                  width: 100%;
                  white-space: nowrap;
                  align-items: center;
                  &.selector {
                    gap: 0.5rem;
                  }
                  .mod-setting-selector {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        .mod-setting-array {
          .mod-setting-array-content {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .inputs {
              display: flex;
              gap: 1rem;
              .selector {
                width: 100%;
              }
            }
            .blocklisted-words-container {
              display: flex;
              flex-wrap: wrap;
              gap: 0.25rem;
              border: 1px solid $liveto-medium-light-gray;
              padding: 0.5rem;
              border-radius: 4px;
              max-height: 204px;
              overflow-y: auto;
              .word-container {
                display: flex;
                align-items: center;
                border: 1px solid $liveto-red;
                padding: 0rem 0.3rem;
                gap: 0.25rem;
                border-radius: 4px;
                .remove-button {
                  cursor: pointer;
                  i {
                    margin-bottom: 0.1rem;
                  }
                }
              }
            }
          }
        }
        .footer {
          display: flex;
          padding: 1rem;
          justify-content: space-between;
          .error,
          .failure {
            color: $liveto-red;
            font-weight: 700;
          }
          .success {
            color: $liveto-green;
            font-weight: 700;
          }
        }
      }
    }
  }
  &.mobile,
  &.tablet {
    .header-modal-inner {
      height: 100%;
      .header-modal-modal {
        height: 100%;
        .header-modal-body {
          height: 100%;
          .modal-content-section {
            padding: 0;
            height: 100%;
            #virtual-event-moderation {
              width: 100%;
              height: 100%;
              border-radius: 0;
              .navigation {
                padding: 0;
                gap: 0;
                width: auto;
                .liveto-side-nav-button {
                  border-radius: 0;
                  padding: 0.5rem 0.75rem;
                  margin: 0;
                }
              }
              .moderation-content {
                .moderation-main-section {
                  overflow-y: auto;
                  &.chat {
                    height: calc(100dvh - 65px - 190px);
                    .chat-messages {
                      height: auto;
                    }
                  }
                  &.settings {
                    height: calc(100dvh - 65px);
                  }
                  .moderation-settings-content {
                    .moderation-general-settings-mobile {
                      .global-settings {
                        border-top: unset;
                      }
                      .chatroom-settings {
                        .setting-value {
                          &.selector {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 1rem;
                            .selector-container {
                              width: 100%;
                            }
                            .selector-label {
                              white-space: nowrap;
                            }
                          }
                        }
                      }
                      .toggle {
                        .moderation-single-setting {
                          .description {
                            font-size: 12px;
                            color: $liveto-text-gray;
                          }
                        }
                      }
                    }
                    .mod-setting-array {
                      .mod-setting-array-content {
                        .inputs {
                          flex-direction: column;
                          gap: 0.25rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.tablet {
    #virtual-event-moderation {
      height: 100% !important;
      .moderation-main-section {
        &.chat {
          height: calc(100dvh - 190px) !important;
        }
        &.settings {
          height: calc(100dvh - 65px) !important;
        }
      }
    }
  }
}
