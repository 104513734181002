.settings-loading-screen {
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-popup {
  position: fixed;
  bottom: 10px;
  left: 40px;
  right: 40px;
  height: 60px;
  border-radius: 10px;
  border: solid 1px #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  &.success {
    background: rgba(0, 155, 0, 0.8);
    color: #fff;
  }
  &.failure {
    background: rgba(155, 0, 0, 0.8);
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .message-popup {
    bottom: 69px !important; //hehe 69
  }
}
