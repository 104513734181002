#react-jitsi-container {
    width: 100% !important;
    height: 100% !important;
    position: relative;
}

.jitsi-fallback {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.jitsi-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 60px;
    background: rgba( 0, 0, 0, 0.70 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    border-radius: 15px;

    .jitsi-form-heading {
        color: #fff;
        font-size: 25px;
    }
    .jitsi-form-description {
        font-size: 16px;
        font-style: italic;
        padding: 10px 0px;
        color: #fff;
    }
    .jitsi-form-button {
        background: transparent;
        border: solid 2px #fff;
        background-color: #C4281A;
        padding: 10px 20px;
        border-radius: 5px;
        color: #fff;
        box-shadow: rgba(0, 0, 0,.2) 0px 11px 14px 0px;
    }
}
.jitsi-loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.jitsi-loading-bg {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background-color: #131313;
    opacity: 0.5;
    z-index: 1;
    border-radius: 15px;
}
.jitsi-loading-content {
    color: #fff;
    font-weight: bold;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    .jitsi-loading-heading {
        font-size: 30px;
    }
}
