@import "globals/sass/_mixin.scss";

.image-gallery-modal {
    position: fixed;
    margin: auto;
    width: 75% !important;
    height: 90% !important;
    display: flex;
    flex-direction: row;
    left: 50%;
    transform: translateX(-50%);
}
.image-listing-container {
    background-color: #fff;
    height: 100%;
    width: 70%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .folder-navigation {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        .nav-button {
            width: auto;
            &:not(:disabled) {
                &:hover {
                    transform: scale(1.1);
                }
            }
            &:disabled {
                color: $liveto-medium-light-gray;
            }
        }
        .hierarchy-path {
            .path-button {
                cursor: pointer;
                &:hover {
                    color: $liveto-red;
                }
            }
        }
    }
    h1 {
        font-size: 15px;
        color: #c4281a;
        margin: 0;
    }
    p {
        font-size: 12px;
    }
    .list {
        display: flex;
        overflow-y: auto;
        border: solid 1px #ddd;
        margin: 5px 0px;
        background-color: #ddd;
        box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 10px inset;
        height: 100%;
        .list-content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            height: fit-content;
            .list-image-container {
                width: 200px;
                height: 200px;
                margin: 15px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 10px;
                padding: 10px;
                border: solid 2px transparent;
                .list-image {
                    max-width: 200px;
                    max-height: 180px;
                    height: auto;
                    width: auto;
                    margin: 5px;
                    border: solid 2px #ddd;
                }
                &.selected {
                    box-shadow: rgba(6, 182, 0, 0.5) 1px 1px 20px;
                    border: solid 2px rgba(6, 182, 0, 0.5);
                }
                &.folder {
                    cursor: pointer;
                }
                .folder-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .icon-container {
                        color: #42b3f5;
                    }
                }
            }
        }
    }

    .image-listing-select-container {
        min-height: 250px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;

        .preview-image-container {
            padding: 10px;
            box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 10px;
            background-color: #fff;
            .preview-image {
                width: 100%;
                max-width: 200px;
                height: auto;
                max-height: 200px;
            }
        }

        .image-actions {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
            flex-grow: 1;
            .folder-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 0.5rem;
                .folder-group {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    .error-message {
                        font-size: 12px;
                        color: $liveto-red;
                        margin-top: 1rem;
                    }
                    .folder-row {
                        display: flex;
                        width: 100%;
                        gap: 0.5rem;
                        justify-content: flex-end;
                        align-items: center;
                        .label-row {
                            white-space: nowrap;
                            margin: 0;
                            width: 50%;
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                        }
                        .select-group {
                            display: flex;
                            align-items: center;
                            width: 50%;
                            .folder-selector {
                                width: 100%;
                            }
                        }
                    }
                    .info {
                        font-size: 12px;
                    }
                }
            }
            .button-container {
                display: flex;
                justify-content: flex-end;
                gap: 0.5rem;
                .select {
                    border: none;
                    padding: 10px 5px;
                    width: -moz-fit-content;
                    width: fit-content;
                    min-width: 150px;
                    background-color: #c4281a;
                    color: #fff;
                    border-radius: 5px;
                    font-weight: bold;
                    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 10px;
                    &:disabled {
                        background-color: $liveto-gray;
                    }
                }
            }
        }
    }
}
.image-upload-container {
    border-left: solid 2px #ddd;
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fafafa;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 10px;

    .upload-heading-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .close-button {
            background: transparent;
            border: none;
            margin: 5px;
            color: red;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }

    .dropzone {
        padding: 20px;
        height: 50%;
        width: 100%;
        border: solid 2px #ddd;
        background-color: #fafafa;

        box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 10px inset;
        .dropzone-inner {
            height: 100%;
            width: 100%;
            border: dashed 2px #ddd;
            background-color: #fff;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: center;

            .dropzone-filesize {
                height: -moz-fit-content;
                height: fit-content;
                text-align: center;
                font-weight: bold;
            }
            .dropzone-description {
                height: -moz-fit-content;
                height: fit-content;
                text-align: center;
            }
        }
        &:hover {
            cursor: pointer;
        }
    }
    .image-preview {
        margin: 5px;
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .image-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;
            .image {
                border: solid 2px #ddd;
                max-width: 200px;
                max-height: 200px;
            }
            .folder-input-group {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                label {
                    margin: 0;
                }
            }
        }
    }
    .image-upload-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .upload-button {
            margin: 5px;
            padding: 10px;
            border-radius: 5px;
            width: -moz-fit-content;
            width: fit-content;
            background-color: #c4281a;
            color: #fff;
            &:disabled {
                background-color: #ddd;
            }
        }
    }
}

.confirm-modal {
    .modal-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border: none;
        .confirmation-content {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .type-group {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
        .buttons {
            display: flex;
            justify-content: flex-end;
            gap: 0.5rem;
        }
    }
}

@media screen and (max-height: 600px) {
    .image-upload-container {
        .dropzone {
            p {
                font-size: 14px;
            }
            height: 35%;

            svg {
                height: 40px;
                width: 40px;
            }
        }
        .image-preview {
            .image-container {
                .image {
                    border: solid 2px #ddd;
                    max-width: 200px;
                    max-height: 150px;
                }
                .folder-input-group {
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;
                    label {
                        margin: 0;
                    }
                }
            }
        }
        .image-upload-footer {
            .upload-button {
                margin: 5px;
                padding: 5px;
                border-radius: 5px;
                width: -moz-fit-content;
                width: fit-content;
                background-color: #c4281a;
                color: #fff;
                font-size: 14px;
                &:disabled {
                    background-color: #ddd;
                }
            }
        }
    }
}
