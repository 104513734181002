// Background grid variables
$--desktop-columns: 6;
$--desktop-max_width: 1152px;
$--desktop-step: 24px;

$--tablet-columns: 6;
$--tablet-max_width: 768px;
$--tablet-step: 16px;

$--mobile-columns: 4;
$--mobile-max_width: 384px;
$--mobile-step: 16px;

$rows: 20px;

// Token based variables

// Colors

$liveto-red: #c4281a;
$liveto-pink: #ee8e88;
$liveto-light-pink: #ffcdca;
$liveto-black: #252525;
$liveto-text-gray: #555;
$liveto-medium-gray: #929292;
$liveto-medium-light-gray: #e2e2e2;
$liveto-dark-gray: #484848;
$liveto-gray: #c4c4c4;
$liveto-light-gray: #f6f6f6;
$liveto-white: #ffffff;
$liveto-teal: #00a3a8;
$liveto-turquoise: #ccedee;
$liveto-light-blue: #a1c0c1;
$liveto-green: #8bc400;
$liveto-light-green: #5ee045;
$liveto-error-red: red;

// Border
$liveto-dark-border: rgba(187, 187, 187, 0.5);

// Shadow
$liveto-above-all: 11px 5px 34px rgba(0, 0, 0, 0.12), -10px 24px 60px rgba(0, 0, 0, 0.12);

// Typography

$fs-100: 12px;
$fs-150: 14px;
$fs-200: 16px;
$fs-300: 18px;
$fs-400: 20px;
$fs-500: 28px;
$fs-600: 36px;
$fs-700: 72px;
$fs-title: 48px;
$fs-body: 17px;

$size-100: 0.5em; // 8px
$size-150: 0.75em;
$size-200: 1em; // 16px
$size-250: 1.25em;
$size-300: 1.5em; // 24px
$size-350: 1.75em;
$size-400: 2em; // 32px
$size-450: 2.25em;
$size-500: 2.5em; // 40px
$size-550: 2.75em;
$size-600: 3em; // 48px

// Z-index
$z-bottom: 1;
$z-mid: 500;
$z-top: 1000;

$main-layout-shadow: 0px 0px 1rem #1313139f;

// Border radius
$pill-border-radius: 100dvh;

// Padding
$pill-padding: 0.25em 0.75em;