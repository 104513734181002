$liveto-red: #c4281a;
$liveto-above-all: 11px 5px 34px rgba(0, 0, 0, 0.12), -10px 24px 60px rgba(0, 0, 0, 0.12);

.invitation-modal-backdrop.show {
    //opacity: 0.8;
    background-color: rgba(0, 0, 0, 0.7) !important;
    opacity: unset !important;
}

.invite-modal-container {
    overflow: unset !important;
}

.invitation-modal-dialog {
    scrollbar-width: thin;
    ::-webkit-scrollbar {
        width: 5px;
    }
    &.mobile {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .invitation-modal {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 0;
            width: 100%;
            .modal-header {
                h4 {
                    font-size: 1.2em;
                    font-weight: 700;
                }
                font-size: 0.8em;
                height: auto;
                min-height: unset;
            }
            .modal-body {
                overflow: auto;
                width: 200%;
                &.invitation-form-view {
                    overflow:hidden;
                }
                .invitation-body-wrapper {
                    min-height: 100%;
                    .invitation-calendar-settings {
                        position: -webkit-sticky;
                        position: sticky;
                        top: 0px;
                        border-bottom: 1px solid #ddd;
                        background-color: #fff;
                        z-index: 20;
                    }
                    .invitation-modal-form-body {
                        flex-direction: column;
                        gap: 1rem;
                        min-height: 100%;
                        border: unset;

                        .day-timeline {
                            height: var(--timeline-height);
                            .selected-date-title {
                                position: -webkit-sticky;
                                position: sticky;
                                top: 41px;
                                border-top: 1px solid #ddd;
                                border-bottom: 1px solid #ddd;
                                background-color: #fff;
                                z-index: 20;
                            }
                        }
                    }
                    .invitation-data-modal {
                        .header {
                            position: sticky;
                            top: 0;
                            z-index: 50;
                            background-color: #fff;
                            padding: 0.5rem 1rem;
                            height: auto;
                        }
                        .body {
                            flex-direction: column;
                            height: auto;
                            overflow: auto;
                            .body-section {
                                height: auto;
                            }
                        }
                        .footer {
                            border: unset;
                            background-color: transparent;
                            position: fixed;
                            bottom: 4rem;
                            right: 0;
                        }
                    }
                }
            }
        }
    }

    &.tablet {
        max-width: 800px;
    }

    .invitation-modal {
        padding-top: 0.5rem;
        width: 800px;
        overflow: hidden;
        .modal-schedule-header {
            background-color: rgb(255, 255, 255);
            padding: 1rem;
            .inner-header {
                display: flex;
                flex-direction: column;
            }
        }
        .react-calendar {
            .react-calendar__tile--active {
                &:enabled {
                    background: $liveto-red;
                }
            }
            .react-calendar__tile--now {
                background: #ffcdca;
            }
        }

        .modal-body {
            width: 1600px;
            display: flex;
            overflow: hidden;
            position: relative;
            transition: transform 0.5s ease-in-out;
            &.invitation-form-view {
                transform: translateX(-50%);
            }
            .invitation-body-wrapper {
                width: 1600px;
                display: flex;
                .invitation-calendar {
                    width: 50%;
                    .invitation-calendar-settings {
                        width: 100%;
                        padding: 0.5rem 1rem;
                        display: flex;
                        .input-group {
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                            .color-code {
                                height: 1rem;
                                width: 1rem;
                                border-radius: 50vw;
                                &.myself,
                                &.attendee {
                                    display: flex;
                                    overflow: hidden;
                                    .half-1,
                                    .half-2 {
                                        width: 50%;
                                    }
                                }
                                &.myself {
                                    .half-1 {
                                        background-color: green;
                                    }
                                    .half-2 {
                                        background-color: #00a000;
                                    }
                                }
                                &.attendee {
                                    .half-1 {
                                        background-color: $liveto-red;
                                    }
                                    .half-2 {
                                        background-color: #ee8e88;
                                    }
                                }
                            }
                        }
                    }
                    .invitation-modal-form-body {
                        min-height: 400px;
                        border-top: 1px solid #ddd;
                        display: flex;

                        .calendar-wrapper {
                            display: flex;
                            height: 100%;
                            width: 100%;
                            align-items: center;
                            justify-content: center;

                            .react-calendar {
                                border: unset;
                            }
                        }

                        .day-timeline {
                            width: 100%;
                            border-left: 1px solid #ddd;
                            position: relative;
                            .selected-date-title {
                                border-bottom: 1px solid #ddd;
                                text-align: center;
                                width: 100%;
                                display: flex;
                                .change-day {
                                    width: 20%;
                                    border: unset;
                                    background-color: transparent;
                                    &:not(:disabled):hover {
                                        transform: scale(1.1);
                                    }
                                }
                                .date-title {
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                            .hours-container {
                                overflow: auto;
                                width: 100%;
                                display: flex;
                                position: relative;
                                overflow-x: hidden;
                                height: 90%;
                                .hours {
                                    position: absolute;
                                    height: 100%;
                                    width: 100%;
                                    .hour {
                                        height: 4em;
                                        border-bottom: 1px solid #ddd;
                                        cursor: pointer;
                                        display: flex;
                                        align-items: center;
                                        padding: 0 0.5rem;
                                        &.available {
                                            &:hover {
                                                box-shadow: $liveto-above-all;
                                                z-index: 2;
                                            }
                                        }
                                        &:not(.available) {
                                            color: #bbb;
                                            cursor: default;
                                        }
                                    }
                                }
                                .slots {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    padding: 0 1em;
                                    width: 100%;
                                    height: 100%;
                                    margin-left: 4rem;
                                    &.right {
                                        justify-content: flex-end;
                                    }
                                    .slot-section {
                                        width: 50%;
                                        height: 100%;
                                        position: relative;
                                        pointer-events: none;
                                        padding: 0 0.25rem;
                                        .slot {
                                            padding: 0.5em;
                                            border-radius: 10px;
                                            z-index: 2;
                                            pointer-events: auto;
                                            position: absolute;
                                            top: 0;
                                            width: 90%;
                                            display: flex;
                                            flex-direction: column;
                                            gap: 0.25rem;
                                            &.small {
                                                padding: 0 0.5em;
                                                justify-content: center;
                                                .slot-name {
                                                    font-size: 10px;
                                                }
                                            }
                                            &.myself {
                                                background-color: rgb(0, 128, 0);
                                                color: #fff;
                                                cursor: pointer;
                                                transition: z-index 0.3s ease-in-out;
                                                z-index: 1;
                                                &:nth-child(even) {
                                                    background-color: rgb(0, 160, 0);
                                                    z-index: 2;
                                                }
                                                &:hover {
                                                    z-index: 3;
                                                }
                                            }
                                            &.attendee-user {
                                                background-color: $liveto-red;
                                                color: #fff;
                                                z-index: 1;
                                                &:nth-child(even) {
                                                    background-color: #ee8e88;
                                                    z-index: 2;
                                                }
                                                &:hover {
                                                    z-index: 3;
                                                }
                                            }
                                            .slot-name,
                                            span {
                                                font-size: 12px;
                                                line-height: 1em;
                                                max-height: 70%;
                                                display: flex;
                                                align-items: flex-start;
                                                overflow-y: hidden;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .invitation-static-heght-tooltip {
                            height: 20px;
                        }
                    }
                }

                .invitation-data-modal {
                    height: 100%;
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    z-index: 30;
                    .header {
                        height: 10%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 1.5rem;
                        border-bottom: 1px solid #ddd;
                        font-weight: 700;
                        .close {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 0.25rem;
                            color: $liveto-red;
                            opacity: 1;
                        }
                    }
                    .body {
                        overflow-y: auto;
                        padding: 1.5rem;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        gap: 2rem;
                        .body-section {
                            width: 100%;
                            height: 100%;
                            .react-datepicker__time-list {
                                height: calc(100px + (1.7rem / 2));
                            }
                            .start-time-container {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                gap: 0.5rem;
                                .time-selector-container {
                                    width: 100%;
                                    .datepicker-time {
                                        padding: 0.2rem 0.5rem;
                                        border: 1px solid #bbb;
                                        border-radius: 5px;
                                        width: 100%;
                                    }
                                }
                                .status {
                                    .accepted {
                                        color: rgb(0, 128, 0);
                                    }
                                    .pending {
                                        color: rgb(200, 128, 0);
                                    }
                                }
                            }
                            .invitation-name-section {
                                display: flex;
                                flex-direction: column;
                                .invite-input {
                                    width: 100%;
                                    padding: 0.2rem 0.5rem;
                                    border: 1px solid #bbb;
                                    border-radius: 5px;
                                }
                            }
                            .invitation-msg-section {
                                .label {
                                    display: flex;
                                    justify-content: space-between;
                                }
                                .invite-textarea {
                                    width: 100%;
                                    height: 100%;
                                    padding: 0.2rem 0.5rem;
                                    border: 1px solid #bbb;
                                    border-radius: 5px;
                                }
                            }
                        }
                    }
                    .footer {
                        padding: 1rem 1.5rem;
                        border-top: 1px solid #ddd;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 1rem;
                        .buttons {
                            display: flex;
                            gap: 0.5rem;
                        }
                        .warning-message {
                            color: $liveto-red;
                            justify-content: flex-start;
                            align-items: center;
                            display: flex;
                            gap: 1rem;
                        }
                    }
                }
            }
        }

        .invite-msg-warning {
            display: flex;
            justify-content: flex-end;
        }
    }
}

@media (max-width: 768px) {
    .invitation-modal-dialog {
        margin: 0 !important;
        max-width: 768px;
        min-height: 100%;
        .invitation-modal {
            border-radius: 0%;
            min-height: 100%;
        }
    }
}

/* @media (min-width: 600px), (max-width: 1080px) {
    .invitation-modal-dialog {
        height: 100%;
        width: 100%;
        margin: auto;
        overflow-y: hidden;
    }
} */
