@import "globals/sass/_mixin.scss";

.error-page {
    height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $liveto-red;
    color: $liveto-white;
}