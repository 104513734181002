@import "globals/sass/_mixin.scss";

.notification-center {
    position: fixed;
    right: 0px;
    top: 5rem;
    width: 380px;
    background-color: #fafafa;
    z-index: 99;
    overflow-y: hidden;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.225);
    border-radius: 10px;
    max-height: calc(100dvh - 90px);
    transform: translate3d(100%, 0, 0);
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    display: flex;
    flex-direction: column;
    &.dark,
    &.transparent-dark {
        background: rgb(0, 0, 0);
        color: #fff;
        border: 1px solid #555;
        .notification-center-invite-type,
        .notification-center-other-type {
            border-color: #555 !important;
        }
        .when-row {
            color: #bbb !important;
        }
        .notification-center-header {
            background: rgb(0, 0, 0);
            border-bottom: 1px solid #555;
            .notification-center-title {
                color: #fff;
            }
        }
        .notification-header-buttons-container {
            .notification-center-more-button {
                border: unset;
                border-radius: 50px;
                background: transparent;
                color: #fff;
                &:hover {
                    background-color: rgb(50, 50, 50) !important;
                }
            }
            .close-notification-center-button {
                background: transparent;
                border: none;
                color: #fff;
            }
        }

        .notification-center-button {
            border: 1px solid #fff !important;
            color: #fff !important;
            background: transparent !important;
            &:hover {
                background: #555 !important;
            }
        }

        .notification-center-dismiss-all-container {
            background: #000;
            button {
                border-color: #fff;
                color: #fff;
                background: transparent;
                &:hover {
                    background: #555;
                }
            }
        }
        .notification-button {
            &.more {
                color: #FFF !important;
            }
            
        }
    }

    .notification-center-dismiss-all-container {
        position: absolute;
        top: 50px;
        right: 40px;
        border-radius: 5px;
        background-color: rgb(240, 240, 240);
        z-index: 1000;
        padding: 0.5rem;
        border: 1px solid #ccc;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        box-shadow: $liveto-above-all;
        &.close {
            display: none;
        }
        button {
            border: unset;
            background: transparent;
            padding: 0.1rem 0.5rem;
            border: 1px solid $liveto-red;
            border-radius: 100dvh;
            color: $liveto-red;
            background: #fff;
            font-size: 12px;
            &:hover {
                background: $liveto-red;
                color: #fff;
            }
        }
    }

    .notification-center-header {
        padding: 0.5em;
        width: 100%;
        z-index: 3;
        background-color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .notification-center-title {
            color: $liveto-red;
            font-size: 18px;
            margin: 0;
        }
        .notification-header-buttons-container {
            display: flex;
            .notification-center-more-button {
                border: unset;
                border-radius: 50px;
                background: transparent;
                &:hover {
                    background-color: #ccc;
                }
            }
            .close-notification-center-button {
                background: transparent;
                border: none;
            }
        }
    }

    .notification-center-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        overflow: auto;
        height: auto;
        max-height: calc(100dvh - 90px);

        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-thumb {
            background: $liveto-red;
        }

        .notification-center-other-type,
        .notification-center-invite-type {
            width: 100%;
            border-top: 1px solid #ccc;
            padding: 0.5em 1em;
            display: flex;
            justify-content: space-between;
            font-size: 14px;

            .notification-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                .notification-info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 8px;
                    .notification-timestamp {
                        text-align: left;
                        width: 100%;
                        .when-row {
                            margin: 0.1em 0;
                            font-size: 11px;
                            color: #555;
                            max-width: 150px;
                        }
                    }
                    .notification-body {
                        display: flex;
                        gap: 8px;
                        width: 100%;
                        align-items: center;
                        padding: 8px 0;
                        .notification-icon {
                            color: $liveto-red;
                        }
                        p {
                            line-height: 1.2;
                            font-size: 13px;
                            max-width: 180px;
                        }
                    }
                }
                .notification-more-info {
                    display: flex;
                    flex-direction: column;
                    transform: scaleY(0);
                    transition: transform .2s linear , height .2s linear;
                    opacity: 0;
                    height: 0;
                    transform-origin: top;
                    &.show {
                        transform: scaleY(1);
                        padding: 16px 0 24px 0;
                        opacity: 1;
                        height: 100%;

                    }
                    .more-info-row {
                        display: flex;
                        flex-direction: row;
                        gap: 16px;
                        font-size: 12px;
                        .label {
                            width: 120px;
                            font-weight: lighter;
                            margin: 0;
                        }
                        .value {
                            width: 100%;
                        }
                    }
                }
                .notification-actions {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .button-group {
                        display: flex;
                        gap: 10px;
                        width: fit-content;
                        &.engagement {
                            width: 100%;
                            justify-content: flex-end;
                        }
                        .accepted,
                        .rejected,
                        .cancelled {
                            font-weight: bold;
                            display: flex;
                            align-items: center;
                            gap: 4px;
                        }
                        .accepted {
                            color: #8bc400;
                        }
                        .rejected, .cancelled {
                            color: $liveto-red;
                        }
                        .accepted-button-group {
                            display: flex;
                            gap: 16px;
                        }
                        .notification-button {
                            border: unset;
                            border-radius: 50px;
                            font-size: 12px;
                            line-height: 1;
                            font-weight: 500;
                            padding: 0;

                            &.accept {
                                background: transparent;
                                color: #8bc400;
                            }
                            &.decline {
                                color: $liveto-red;
                                background: transparent;
                            }
                            &.more {
                                color: #000;
                                background: transparent;
                            }
                        }
                    }
                }
                .notification-engagement-achievement {
                    display: flex;
                    flex-direction: column;
                    .header {
                        font-weight: 700;
                    }
                    .name {
                        font-weight: 700;
                        font-size: 16px;
                        color: $liveto-red;
                    }
                }
                .notification-inner-content {
                    display: flex;
                    min-width: 100%;
                    justify-content: space-between;
                    .notification-icon {
                        min-height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        color: $liveto-red;
                        margin-left: 1em;
                    }
                }
                .button-row {
                    display: flex;
                    margin-top: 0.1em;

                    .notification-center-button {
                        padding: 0.1em 0.7em;
                        border: unset;
                        margin-right: 0.5em;
                        border-radius: 100px;
                        font-size: 12px;
                        &.red-outline {
                            border: 1px solid $liveto-red;
                            background-color: #fff;
                            color: $liveto-red;
                            &:hover {
                                background-color: $liveto-red;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .notification-icon-row {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: $liveto-red;
                .notification-close-button {
                    cursor: pointer;
                }
            }

            .invite-data-icon {
                margin-right: 0.5em;
            }

            .time-row {
                font-weight: 700;
            }

            .status-row {
                font-weight: 700;
                .status-accepted {
                    color: $liveto-green;
                }
                .status-rejected,
                .status-cancelled {
                    color: $liveto-red;
                }
                .status-pending {
                    color: rgb(245, 179, 66);
                }
            }
        }
    }

    &.mobile {
        &.notification-center-open {
            transform: translateX(0px);
        }
        border-radius: 0;
        max-height: calc(100dvh - 130px);
    }
    &.tablet {
        width: 50%;
        top: 5rem;
    }

    &.notification-center-open {
        transform: translateX(-10px);
    }
}

@media (max-width: 1475px) {
    .notification-center {
        width: 300px;
    }
    @media screen and (max-width: 768px) {
        .notification-center {
            width: 100%;
            top: 70px;

            .text-right {
                width: 100%;
                text-align: center !important;

                .accept {
                    margin-left: 0;
                    margin-right: auto;
                    width: 50%;

                    svg {
                        height: 30px;
                        width: 30px;
                    }
                }

                .decline {
                    margin-right: 0;
                    margin-left: auto;
                    width: 50%;

                    svg {
                        height: 30px;
                        width: 30px;
                    }
                }
            }
        }

        .notification-center-close {
            box-shadow: none;
        }
    }
}

.invitation-modal {
    .invitation-modal-header {
        position: relative;

        .invite-modal-close-button {
            position: absolute;
            right: 1em;
            background-color: white;
            border: unset;
            cursor: pointer;
            &:hover {
                color: #555;
            }
        }
    }

    .status-row {
        font-weight: 700;
        .rejected,
        .cancelled {
            color: $liveto-red;
        }
        .accepted {
            color: #8bc400;
        }
        .pending {
            color: #f5b342;
        }
    }

    .invitation-modal-button {
        color: white;
        border-radius: 50px;
        border: unset;
        min-height: 2.5rem;
        min-width: 100px;

        &.accept {
            background-color: $liveto-green;
            &:hover {
                border: 1px solid $liveto-green;
                background-color: white;
                color: $liveto-green;
            }
        }
        &.reject {
            background-color: $liveto-red;
            &:hover {
                border: 1px solid $liveto-red;
                background-color: white;
                color: $liveto-red;
            }
        }
    }
}
