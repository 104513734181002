@import "variables";

.editor-header-link {
  display: flex;
  font-size: 12px;
  color: $liveto-black;
  padding: $pill-padding;
  transition: all 0.3 ease-in;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    border-radius: $pill-border-radius;
    background-color: #fff;
  }
  &.dark {
    color: $liveto-light-blue;
    &:hover {
      color: $liveto-text-gray;
    }
    &.action {
      font-weight: 700;
      color: $liveto-teal !important;
    }
  }
}

.editor-menu-button {
  border: 1px solid #bbb;
  border-radius: 5px;
  color: $liveto-text-gray;
  min-width: 3.5em;
  &.active {
    color: $liveto-red;
  }
  &.disabled {
    background-color: $liveto-light-gray;
    color: #bbb;
    &:hover {
      cursor: default;
    }
  }
  &:not(.active):not(.disabled):hover {
    background-color: $liveto-text-gray;
    color: #fff;
  }
}

.modal-back-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  color: $liveto-text-gray;
  border: unset;
  background-color: transparent;
  width: 100px;
}

.liveto-side-nav-button {
  padding: 0.2em 1em;
  border: unset;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  &.active {
    background: $liveto-red;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }
}

.generic-liveto {
  border-radius: $pill-border-radius;
  padding: $pill-padding;
  &.accept {
    background: $liveto-green;
    color: $liveto-white;
    border: 1px solid $liveto-green;
    &:hover {
      background: $liveto-white;
      color: $liveto-green;
    }
  }
  &.decline {
    background: $liveto-red;
    color: $liveto-white;
    border: 1px solid $liveto-red;
    &:hover {
      background: $liveto-white;
      color: $liveto-red;
    }
  }
  &:disabled {
    background: $liveto-medium-light-gray;
    border-color: $liveto-medium-light-gray;
    cursor: default;
    pointer-events: none;
  }
}

.liveto-transparent {
  border: unset;
  background-color: transparent;
}

.tooltip-info-button {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  color: white;

  .tooltip-info-icon {
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background-color: rgb(0, 184, 49);
    font-size: 0.8rem;
    text-align: center;
  }
}
