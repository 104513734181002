@import "globals/sass/_mixin.scss";

.push-notification-wrapper {
    position: fixed;
    bottom: 100px;
    right: 10px;
    width: 300px;
    z-index: 20000;

    .push-notification-item {
        box-shadow: 0 0 5px rgba(0,0,0, 0.4);
        margin: 5px 0px;
        position: relative;
        animation: SlideLeft 0.4s;
        animation-fill-mode: forwards;
        width: 300px;

        .note-msg {
            text-align: center;
            padding: 10px;

            .engagement-achievement-title {
                color: #555;
                font-size: 20px;
                font-weight: 700;
            }
            .engagement-achievement-description {
                text-align: center;
            }
            .privete-msg-notification {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: flex-start;
                cursor: pointer;
                .title {
                    font-size: 14px;
                    font-weight: 700;
                    margin-bottom: 0.5rem;
                    border-bottom: 1px solid $liveto-gray;
                    width: 100%;
                    text-align: left;
                }
                .sender {
                    font-weight: 700;
                }
                .message,
                .sender {
                    font-size: 12px;
                }
            }
        }

        .close-button {
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
        }
    }

    .push-notification-item.exit {
        animation: SlideRight 0.4s;
        animation-fill-mode: forwards;
        width: 300px;
    }

    @keyframes SlideLeft {
        0% {
            margin-left: 120%;
        }

        100% {
            margin-left: 0;
        }
    }

    @keyframes SlideRight {
        0% {
            margin-left: 0;
        }

        100% {
            margin-left: 120%;
        }
    }

    .info {
        background-color: rgb(242, 255, 255);

        .bar {
            background-color: blue;
            height: 3px;
        }
    }

    .success {
        background-color: rgb(242, 255, 242);

        .bar {
            background-color: green;
            height: 3px;
        }
    }

    .error {
        background-color: rgb(255, 188, 188);

        .bar {
            background-color: red;
            height: 3px;
        }
    }

    .notification-event-link {
        cursor: pointer;
        font-size:16px;
        text-decoration: underline;

        &:visited {
            color: rgb(0, 0, 155);
        }

        &:hover {
            color: rgb(0, 0, 85);
        }

        &:active {
            color: rgb(0, 0, 60);
        }
    }

    .user-time-wrapper {
        text-align: left;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.7rem;
        padding-top: 0.7rem;
        border-top: 1px solid #DDD;
        display: flex;
        flex-direction: column;
        align-items: center;
        .user-wrapper {
            display: flex;
            width: 100%;
        }
        .time-wrapper {
            display: flex;
            width: 100%;
        }
        .push-notification-icon {
            margin-right: 1rem;
        }

        .proceed-to-profile-button {
            border-radius: 50px;
            margin-top: 10px;
            width: 80%;

            &.proceed {
                border-color: #C4281A;
                background-color: #C4281A;
                font-weight: 700;
                color: white;
                font-size: 18px;
                &:hover {
                    background-color: white;
                    color: #C4281A;
                }
            }
        }
    }

    .notification-slot-time {
        color: #555;
        font-weight: bold;
        font-size: 18px;
    }
}