@import "globals/sass/_mixin.scss";
.lvt {
    &-modal {
        border-radius: 8px;
        .lvt-modal-inner {
            border-radius: 8px;
            padding: 1rem;
            .lvt-modal-modal {
                border-radius: 8px;
            }
        }
        &-modal {
        }
        &-body {
        }
    }
    &-multiselect,
    &-select {
        .mantine-InputWrapper-label {
            margin: 0;
            font-weight: 700;
        }
    }
    &-btn {
        background-color: $liveto-red;
        color: #fff;
        border-radius: 5px;
        padding: 4px 8px;
        border: 1px solid $liveto-red;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        transition: all 0.2s ease-in-out;
        &:hover {
            color: $liveto-red;
            background-color: #fff;
            border-color: $liveto-red;
        }
        &.sm {
            font-size: 12px;
        }
        &:disabled {
            background-color: #bbb;
            border-color: #bbb;
            cursor: default;
            color: #fff;
            &:hover {
                background-color: #bbb;
                border-color: #bbb;
                cursor: default;
                color: #fff;
            }
        }
    }
    @include responsive(phone) {
        &-modal .lvt-modal-inner {
            padding: 0;
            border-radius: 0;
        }
    }
}
