#virtual-event-poll-listing {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 10px 10px 6rem 10px;
    max-width: 600px;
}

.poll-element {
    .poll-list-item {
        max-width: 500px;
    }
}

.poll-list-item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #fafafa;
    color: #000;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    .poll-starts {
        display: flex;
        flex-direction: row;
        padding: 10px;
        border-radius: 5px;
        .timer {
            font-size: 20px;
            color: #555;
        }
    }
    .poll-heading {
        color: #000;
        font-weight: bold;
        font-size: 23px;
        padding: 20px;
        border: none;
        border-radius: 5px;
        text-align: center;
        background: #fff;
        border: solid 1px #ddd;
        width: 100%;
    }
    .poll-content {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.voted {
            flex-direction: column;
            justify-content: space-evenly;
        }
        .poll-users-vote {
            font-size: 15px;
            font-weight: bold;
            margin: 0 .4em;
            .vote-answer {
                border: solid 2px #00afa5;
                border-radius: 5px;
                padding: .5em;
                font-weight: 700;
                color: #555;
                background-image: linear-gradient(transparent, #00afa57d);
            }
        }
        
        .poll-timer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 20px;
            h2 {
                text-align: center;
                font-size: 15px;
                max-width: 300px;
                font-weight: bold;
            }
        }
    }
    .poll-answers {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 200px;
        .option-button {
            display: flex;
            flex-direction: column;
            border-radius: 5px;
            padding: 10px 15px;
            margin: 5px 0px;
            background: #fafafa;
            border: solid 2px #ee8e88; //solid 2px #00afa5;
            color: #000;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
            justify-content: flex-start;
            width: 100%;
            &:hover {
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
                .option-row {
                    .circle-dot {
                        background: #00afa5;
                    }
                }
            }
            &.voted {
                border: none;
                padding: 5px;
                box-shadow: none;
                background: transparent;
                color: #555;
                .option-row {
                    justify-content: space-between;
                }
            }
            &.my-vote {
                border: solid 2px #00afa5;
            }
            .option-row {
                text-align: left;
                display: flex;
                font-size: 14px;
                font-weight: bold;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                gap: 1rem;
                .label-row {
                    display: flex;
                    align-items: center;
                }
                .circle-dot {
                    width: 15px;
                    min-width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    border: solid 2px #ee8e88;
                    margin-right: 10px;
                }
                &.space-between {
                    justify-content: space-between;
                }
                .percentage-value {
                    font-weight: bold;
                }
                .visualization {
                    background: #e2e2e2;
                    margin: 0.4em 0;
                    width: 100%;
                    height: 12px;
                    .percentage {
                        background-image: linear-gradient(
45deg, green, limegreen);
                        background-size: 100%, 100%;
                        height: 100%;
                        width: 100%;
                        transform: scaleX(0);
                        transform-origin: left;
                        transition: transform 0.4s ease-in;
                    }
                }
            }
        }
        &.before-start {
            .option-button {
                box-shadow: none;
                .option-row {
                    .circle-dot {
                        display: none;
                    }
                }
                &:hover {
                    box-shadow: none;
                }
            }
        }
    }
    .poll-times {
        display: flex;
        flex-direction: column;
        .time {
            font-weight: bold;
        }
    }
}
.pie-chart {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 4px solid #c5c5c5;
    margin: 1em auto;
    transition: all 0.2s ease;
}

