.virtual-event-carousel-content {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.virtual-event-component-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.virtual-event-component-overlay {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
a.virtual-event-component,
a.text-element-wrapper {
    text-decoration: none;
    color: inherit;
    &:hover {
        color: inherit;
    }
}

.carousel-item-wrapper {
    width: 100%;
    height: 100%;
}

// Default button hovers etc.

.button-element {
    margin: 0.5em;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease-in-out;
    width: max-content;
    text-decoration: none;
    will-change: transform;
    transform: scale(1);
    &:hover {
        transform: scale(1.05);
    }
    &:active {
        transition: transform 0.2s linear;
        transform: scale(0.95);
    }
}
.cloudflare-player {
    width: 100%;
    height: 100%;
    position: initial !important;
}
.vod-selector-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    align-items: center;
    .close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: unset;
        color: #fff;
        background: transparent;
        &:hover {
            transform: scale(1.05);
        }
    }

    .vod-selector-container-inner {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        flex-wrap: wrap;
        max-height: 80%;
        overflow-y: auto;
        .vod-card {
            position: relative;
            flex-basis: auto;
            background-color: rgba(255, 255, 255, 0.3);
            background-size: contain;
            aspect-ratio: 16/9;
            cursor: pointer;
            border-radius: 5px;
            overflow: hidden;
            &:hover {
                transform: scale(1.05);
            }
            color: white;
            border: 1px solid #bbb;
            .vod-card-content {
                padding: 0.5rem;
                position: absolute; /* Sit on top of the page content */
                width: 100%; /* Full width (cover the whole page) */
                height: 100%; /* Full height (cover the whole page) */
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
                z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
                cursor: pointer; /* Add a pointer on hover */
            }
        }
    }
    &.desktop {
        .vod-selector-container-inner {
            .vod-card {
                max-width: calc(25% - 1.5rem);
                position: relative;
                .vod-card-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    @media screen and (max-width: 1400px) {
                        font-size: 12px;
                    }
                    .vod-card-data {
                        padding: 0;
                    }
                    .footer {
                        display: flex;
                        justify-content: space-between;
                        .live {
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                            font-weight: 700;
                            span {
                                display: flex;
                                width: 20px;
                                height: 20px;
                                background-color: red;
                                border-radius: 100dvh;
                            }
                        }
                    }
                    .button-container {
                        display: flex;
                        justify-content: flex-end;
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 0.5rem;
                        .download {
                            cursor: pointer;
                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }
    &.mobile {
        padding-right: 1rem;
        .close-button {
            z-index: 10;
            top: 0.5rem;
            right: 0.5rem;
        }
        .vod-selector-container-inner {
            gap: 0.5rem;
            max-height: 100%;
            .vod-card {
                max-width: calc(50% - 0.5rem);
                position: relative;
                .vod-card-content {
                    font-size: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .footer {
                        display: flex;
                        justify-content: space-between;
                        .live {
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                            font-weight: 700;
                            span {
                                display: flex;
                                width: 15px;
                                height: 15px;
                                background-color: red;
                                border-radius: 100dvh;
                            }
                        }
                    }
                    .vod-card-data {
                        padding: 0;
                    }
                    .button-container {
                        display: flex;
                        justify-content: flex-end;
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 0.5rem;
                        .download {
                            cursor: pointer;
                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
                &:hover {
                    transform: unset;
                }
            }
        }
    }
    &.tablet {
        padding-right: 1rem;
        .close-button {
            z-index: 10;
            top: 0.5rem;
            right: 0.5rem;
        }
        .vod-selector-container-inner {
            gap: 0.5rem;
            max-height: 90%;

            .vod-card {
                max-width: calc(30% - 0.5rem);
                justify-content: space-between;
                position: relative;
                .vod-card-content {
                    font-size: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .footer {
                        display: flex;
                        justify-content: space-between;
                        .live {
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                            font-weight: 700;
                            span {
                                display: flex;
                                width: 15px;
                                height: 15px;
                                background-color: red;
                                border-radius: 100dvh;
                            }
                        }
                    }
                    .vod-card-data {
                        padding: 0;
                    }
                    .button-container {
                        display: flex;
                        justify-content: flex-end;
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 0.5rem;
                        .download {
                            cursor: pointer;
                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
                &:hover {
                    transform: unset;
                }
            }
        }
    }
}
.player-buttons {
    display: flex;
    gap: 0.5rem;
    position: absolute;
    top: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
    padding: 1rem;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 3;
    height: 20%;
    .show-vod-selector-button {
        border: unset;
        color: #fff;
        background: transparent;
        &:hover {
            transform: scale(1.05);
        }
        z-index: 10;
    }
}

.element-hilight-wrapper {
    overflow: hidden;
}

.shaka-video-container {
    width: 100%;
    aspect-ratio: 16/9;
}
