.help-tooltip-icon {
    svg {
        fill: white;
        transition: fill 0.2s ease;
    }
    &:hover {
        svg {
            fill: #ddd;
        }
    }
}