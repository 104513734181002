@import "react-datepicker/dist/react-datepicker.css";
@import "react-big-calendar/lib/sass/styles";
@import "react-big-calendar/lib/addons/dragAndDrop/styles";
@import "globals/sass/_mixin.scss";

$modalHeight: 70vh;
$liveto-dark-green: #8bc400;

.rbc-toolbar {
    background-color: $liveto-red;
    padding: 0.5rem;
    color: #fff;
    .rbc-toolbar-label {
        font-size: 20px;
        font-weight: 500;
    }
    button {
        color: #fff;
        &.rbc-active {
            background-color: pink;
            color: $liveto-red;
        }
    }
}

.cal-big {
    height: $modalHeight;
}

.pending {
    color: red;
}

.accepted {
    color: green;
}

.cursor-pointer-hover:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.3);
}

.cursor-pointer:hover {
    cursor: pointer;
}

.timeslot {
    transition: all 0.2s ease-in-out;
    //border-top: 0.3px solid rgba(255,255,255,0.5)
}

.timeslot:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.2);
}

.timeslot:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.3);
}

.border-left {
    border-left: 0.25px solid rgba(0, 0, 0, 0.2);
}

.border-right {
    border-right: 0.25px solid rgba(0, 0, 0, 0.2);
}

.border-y {
    border-top: 0.25px solid rgba(0, 0, 0, 0.2);
    border-bottom: 0.25px solid rgba(0, 0, 0, 0.2);
}

.border-y-medium {
    border-top: 0.5px solid rgba(0, 0, 0, 0.2);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

.weekday-col {
    max-width: 14%;
    min-height: 10vh;
}

.weekday-col-active {
    border: 1px solid rgba(255, 255, 255, 0.8);
}

.event_slot {
    background-color: rgba(193, 66, 66, 0.1);
}

.personal_slot {
    background-color: rgba(50, 180, 70, 0.1);
}

.personal_slot:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

.close:hover {
    background-color: rgba(0, 0, 0, 1);
}

.close:hover {
    color: black;
    background-color: transparent;
}

.invites {
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    z-index: 2;
    display: inline-block;
    margin-right: 7vh;
    top: 5.5vh;
    right: 0vh;
    max-height: 30vh;
}

.small-font {
    font-size: 0.1rem !important;
}

.invites-link:hover {
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

.form-control {
    background-color: rgb(255, 255, 255);
}

.form-control:focus {
    //background-color: rgba(255, 255, 255, 0.6);
    border-color: rgba(255, 255, 255, 0.7);
    box-shadow:
        0 1px 1px rgba(0, 0, 0, 0.075) inset,
        0 0 8px rgba(255, 255, 255, 0.8);
    outline: 0 none;
}

.decline {
    color: rgb(255, 0, 0);
}
.accept {
    color: rgb(0, 175, 0);
}

.decline:hover {
    color: rgb(175, 0, 0);
    cursor: pointer;
}
.accept:hover {
    color: rgb(0, 100, 0);
    cursor: pointer;
}

.invites-close {
    margin-right: 0.01vh !important;
}

.dropdown-up {
    position: absolute;
    inset: auto auto 0px 0px;
    margin: 0px;
    transform: translate3d(15vh, -8vh, 0px);
}

.z-index2 {
    z-index: 2;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.available-slots {
    max-height: 18vh;
    border: 1px solid #ced4da;
    border-radius: 0 !important;
}

.bg-transparent-04 {
    background-color: rgba(255, 255, 255, 0.4);
}

.available-slot:hover {
    background-color: rgb(200, 200, 200);
    cursor: pointer;
}

.available-slot-active {
    background-color: #ddd;
}

.available-slots-selected {
    background-color: rgb(255, 255, 255) !important;
    max-height: 26.3vh;
}

.available-slot-selected:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

.modal-backdrop {
    width: 100%;
}

.sidebar-slot-description-multilingual {
    text-align: initial;
}

::-webkit-scrollbar {
    @include responsive(phone) {
        display: none !important;
    }
}

.lvt-modal-inner {
    .rbc-calendar {
        height: 200dvh;
        @media (min-width: 689px) {
            .rbc-agenda-table {
                .rbc-header {
                    font-size: 15px;
                    > * {
                        font-weight: 700;
                    }
                }
            }
            .rbc-header {
                font-size: 20px;
                min-height: 30px;
                > * {
                    font-weight: 700;
                }
            }
            .rbc-row-resource {
                .rbc-header {
                    font-size: 16px;
                    height: auto;
                    white-space: normal;
                    border-bottom: unset;
                }
                border-bottom: unset;
            }
            .rbc-day-slot {
                &:nth-child(even) {
                    background-color: #fafafa;
                }
            }
            .rbc-time-content {
                .rbc-day-slot {
                    &.rbc-today {
                        background-color: #eaf6ff;
                    }
                }
            }
            .rbc-allday-cell {
                height: calc(100% - 30px);
            }
        }

        .rbc-event {
            color: #000 !important;
            font-weight: 500;
        }
        .rbc-agenda-table {
            td[rowspan]:not([rowspan="1"]) {
                // Your styles here
                background-color: white; // Example style
                //color: #000;
            }
            tbody {
                tr {
                    > * {
                        //color: #000;
                    }
                }
            }
        }
        .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
            border-left: unset;
        }
    }
    @include responsive(tablet) {
        .schedule-modal-header {
            .lvt-modal-title {
                .schedule-header {
                    flex-direction: column;
                    gap: 0.5rem;
                    align-items: flex-start;
                    .schedule-title-container {
                        width: 100%;
                        justify-content: space-between;
                    }
                    .actions {
                        flex-direction: column;
                        > * {
                            min-width: 100%;
                        }
                    }
                }
            }
        }
    }
    @include responsive(laptop) {
        .schedule-modal-header {
            .lvt-modal-title {
                .schedule-header {
                    flex-direction: column;
                    gap: 0.5rem;
                    align-items: flex-start;
                    .schedule-title-container {
                        width: 100%;
                        justify-content: space-between;
                    }
                    .actions {
                        flex-direction: column;
                        gap: 0.5rem;
                        > * {
                            min-width: 100%;
                        }
                    }
                }
            }
        }
    }
    @include responsive(phone) {
        padding: 0;
        .schedule-modal-header {
            align-items: flex-start;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
            position: relative;
            .mantine-Modal-close {
                position: absolute;
                right: 1rem;
                top: 1rem;
            }
            .schedule-header {
                .schedule-title-container {
                    flex-direction: column;
                    width: 100%;
                    align-items: flex-start !important;
                    .navigation-buttons {
                        width: 100% !important;
                        .schedule-header-button {
                            width: 100%;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }

            .actions {
                flex-direction: column;
                align-items: flex-start !important;

                > * {
                    min-width: 100%;
                }
            }
            .filters {
                flex-direction: column;
                > * {
                    min-width: 100%;
                }
            }
            .lvt-modal-title {
                margin: 0;
                .schedule-header {
                    flex-direction: column;
                    gap: 0.5rem;
                    align-items: flex-start;
                    .schedule-multiselect {
                        min-width: 100%;
                    }
                }
            }
        }
        .lvt-modal-body {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            .rbc-toolbar {
                .rbc-btn-group {
                    width: 100%;
                    display: flex;
                    > * {
                        width: 100%;
                    }
                }
                :nth-child(3) {
                    flex-direction: column;
                }
            }
        }
        .rbc-calendar {
            .rbc-agenda-view {
                .rbc-agenda-table {
                    .rbc-header {
                        &:first-child,
                        &:nth-child(2) {
                            max-width: 20dvw;
                            min-width: 20dvw;
                        }
                    }
                }
                .rbc-agenda-content {
                    .rbc-agenda-table {
                        .rbc-agenda-date-cell,
                        .rbc-agenda-time-cell {
                            max-width: 20dvw;
                            min-width: 20dvw;
                            white-space: normal;
                            padding: 2px 4px;
                        }
                    }
                }
            }
        }
    }
    height: 100%;
    .lvt-modal-modal {
        padding: 0;
        margin: 0;
        height: 100%;
        //border-radius: 10px;
    }
    .schedule-modal-header {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 1rem;

            .filters {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                gap: 1rem;
            }
        }
    }
    .lvt-modal-body {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    .schedule-modal-header,
    .lvt-modal-body {
        padding: 1rem;
        margin: 0;
        background-color: #fff;
    }
    .lvt-modal-title {
        width: 100%;
        .schedule-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            gap: 1rem;
            .schedule-filters-accordion {
                width: 100%;
                &-control {
                    background-color: $liveto-red;
                    color: #fff;
                    border-radius: 5px;
                    padding: 4px 8px;
                }
                .mantine-Accordion-item {
                    border-bottom: unset;
                }
            }
            .schedule-title-container {
                display: flex;
                align-items: flex-end;
                gap: 1rem;
                .schedule-title-wrapper {
                    display: flex;
                    gap: 0.5rem;
                    .schedule-title {
                        font-size: 24px;
                        font-weight: 700;
                        margin: 0;
                        word-break: keep-all;
                    }
                    .schedule-info-icon {
                        width: 20px;
                        min-width: 20px;
                        height: 20px;
                        min-height: 20px;
                        border-radius: 25px;
                        background-color: rgb(0, 184, 49);
                        font-size: 0.8rem;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .navigation-buttons {
                    display: flex;
                    width: auto;
                    gap: 0.5rem;
                    .schedule-header-button {
                        word-break: keep-all;
                    }
                }
            }
            .schedule-multiselect {
                max-width: 100%;
                min-width: 30ch;
                .mantine-MultiSelect-input {
                    max-height: 80px;
                    overflow-y: auto;
                }
                .schedule-multiselect-dropdown {
                    display: flex;
                    flex-direction: column;
                    gap: 0.25rem;
                }
            }
        }
    }

    .schedule-title-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .schedule-title {
            font-size: 24px;
            font-weight: 700;
            margin: 0;
        }
        .schedule-info-icon {
            width: 20px;
            height: 20px;
            border-radius: 25px;
            background-color: rgb(0, 184, 49);
            font-size: 0.8rem;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.drawer-lvt-drawer-root {
    .timeslot-drawer-inner {
        border-radius: 10px;
        box-shadow: unset;
        padding: 1rem;
        background-color: transparent;
        > * {
            background-color: #fafafa;
            margin: 0;
        }
        .drawer-lvt-drawer-header {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 1rem;
        }
        .drawer-lvt-drawer-body {
            height: calc(100dvh - 2rem - 60px);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            .side-menu-slot {
                z-index: 99;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 2em;

                &.mobile {
                    border-radius: unset !important;
                    top: 70px !important;
                    &.side-menu-open {
                        transform: translateX(0) !important;
                    }
                }

                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */

                .sidebar-handle-favourites {
                    width: 100%;
                    background-color: $liveto-red;
                    color: white;
                    border: unset;
                    box-shadow: 3px 3px 3px rgb(200, 200, 200);
                    padding: 5px;
                    border-radius: 50px;
                    min-height: 40px;
                    font-weight: 700;
                    display: flex;
                    justify-content: center;
                    margin: 0.5em 0;
                    align-items: center;
                    &:hover {
                        background-color: white;
                        color: $liveto-red;
                    }
                }

                .sidemenu-header {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    padding: 0.5rem;
                    .close-virtual-schedule-button-right {
                        background: transparent;
                        border: none;
                    }
                }

                .slot-details-sidebar {
                    width: 100%;
                    overflow-y: auto;
                    padding: 1rem 2rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    > * {
                        width: 100%;
                    }
                    > h4,
                    strong {
                        text-align: center;
                    }

                    .sidebar-description-and-tags-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .sidebar-slot-description {
                            margin-top: 1em;
                            width: 100%;
                        }
                        .sidebar-tags-container {
                            margin-top: 1em;
                            display: flex;
                            width: 100%;
                            justify-content: center;
                            align-items: center;
                            flex-wrap: wrap;
                            font-size: 14px;
                            .sidebar-tag {
                                margin: 0.3em;
                                padding: 0.2em 0.8em;
                                background-color: $liveto-red;
                                border-radius: 5000px;
                                color: #fff;
                                flex-basis: 30%;
                                white-space: nowrap;
                            }
                        }
                    }

                    .performer-image {
                        background-color: #fafafa;
                        border: solid 2px #ddd;
                        border-radius: 50%;
                        height: 9rem;
                        width: 9rem;
                        margin-left: 1rem;
                        margin-right: auto;
                        margin-top: auto;
                        color: #555;
                        background-size: auto;
                        background-repeat: no-repeat;
                        background-position: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .sidebar-button-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        align-items: center;
                        .sidebar-proceed-to-profile-button,
                        .sidebar-cancel-invite-button {
                            border-radius: 50px;
                            margin-top: 10px;
                            width: 80%;

                            &.cancel {
                                border-color: $liveto-red;
                                background-color: $liveto-red;
                                color: white;

                                &:hover {
                                    background-color: darkred;
                                }
                            }
                            &.proceed {
                                border-color: $liveto-dark-green;
                                background-color: green;
                                color: white;
                                &:hover {
                                    background-color: darkgreen;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include responsive(phone) {
        .timeslot-drawer-inner {
            padding: 0;
            .drawer-lvt-drawer-body {
                height: calc(100dvh - 60px);
                border-radius: 0;
            }
            .drawer-lvt-drawer-header {
                border-radius: 0;
            }
        }
    }
}
