#editor-context-menu {
    .react-contexify {
        position: absolute!important;
        left: 10px !important;
        top: 10px!important;
        padding: 0px;
        border-radius: 0px;
        z-index: 10000000;
    }
    .react-contexify__separator {
        margin: 0px;
    }
    .react-contexify__item__content {
        padding: 5px;
    }
}
