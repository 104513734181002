@import "globals/sass/_mixin.scss";

.agenda-event {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  .actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    .more-info-btn {
      background-color: #fff;
      color: #000;
      border-radius: 5px;
      padding: 4px 8px;
      border: 1px solid #555;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      transition: all 0.2s ease-in-out;
      .icon {
        * {
          color: #bbb;
        }
      }
      &:hover {
        color: #000;
        background-color: #fff;
        border-color: #000;
        .icon {
          * {
            color: #000;
          }
        }
      }
    }
    .agenda-badge {
      display: flex;
      align-items: center;
      .live-icon {
        height: 15px;
        width: 15px;
        border: 1px solid #000;
        background-color: red;
        border-radius: 100vw;
      }
    }
  }

  @include responsive(phone) {
    flex-direction: column;
    gap: 0.25rem;
    align-items: flex-start;
    justify-content: flex-start;
    .actions {
      flex-direction: column;
      gap: 0.25rem;
      width: 100%;
      align-items: flex-start;
      button {
        width: 100%;
        white-space: wrap;
        word-break: keep-all;
        text-align: left;
      }
    }
  }
  @include responsive(tablet) {
    flex-direction: column;
    gap: 0.25rem;
    align-items: flex-start;
    .actions {
      gap: 0.5rem;
      width: 100%;
      justify-content: flex-end;

      > * {
        width: 50%;
        white-space: nowrap;
        word-break: keep-all;
      }
    }
  }
}
