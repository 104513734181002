@import "globals/sass/_mixin.scss";

.loading-screen {
    width: 100%;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ql-align-right {
    text-align: right;
}
.ql-align-center {
    text-align: center;
}

.authenticate-screen {
    padding: 10px;
    width: 100%;
    min-height: 100dvh;
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    place-items: center;
    overflow-x: auto;
    background-color: black;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .liveto-logo {
        max-width: 250px;
    }
    .event-info {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        background: rgba(255, 255, 255, 0.8);
        padding: 0.5em;
        align-items: center;
        border-radius: 5px;
        margin-bottom: 1em;
        top: 1em;
        display: flex;
        flex-direction: row;
        min-width: 300px;
        .event-image {
            border: 1px solid white;
            width: 100px;
            min-width: 100px;
            height: 100px;
            background-size: cover;
        }
        .info {
            padding: 0.5em;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .event-name {
            font-size: 2rem;
            font-weight: bold;
        }
        .event-description {
        }
    }
    .auth {
        display: flex;
        flex-direction: row;
        min-width: 300px;
        max-width: calc(900px + 2rem);
        overflow-x: hidden;
        padding: 0.5rem;
        .login-container {
            width: 200%;
            display: flex;
            transform: translateX(0);
            transition: transform 0.5s ease-in-out;
            gap: 1rem;
            .signin-button {
                padding: 5px 5px;
                width: 100%;
                color: #000;
                display: flex;
                align-items: center;
                background: #fff;
                border-radius: 5px;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
                font-weight: bold;
                text-align: center;
                display: flex;
                justify-content: center;
                &.guest {
                    border: none;
                    font-weight: normal;
                    padding: 5px 10px;
                    background: $liveto-red;
                    color: #fff;
                    &:disabled {
                        background: #ddd;
                        color: #fff;
                    }
                }
                .icon {
                    margin: 0px 5px;
                }
                &.google {
                    color: #000;
                }
                &.facebook {
                    color: #4267ba;
                }
                &.twitter {
                    color: #1da1f2;
                }
                &.linkedin {
                    color: #0e76a8;
                }
            }
            &.extra-form {
                transform: translateX(calc(-50% - 1.5rem));
            }
            .extra-field-form-container {
                width: 900px;
                max-height: 400px;
                background: rgba(255, 255, 255, 0.8);
                border-radius: 5px;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .required-star {
                    color: rgb(255, 0, 0);
                }
                .component-label {
                    font-weight: 700;
                    margin-bottom: 0.25rem;
                }
                .form-content {
                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                    padding: 1rem;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    overflow-y: auto;
                    hr {
                        width: 100%;
                        margin: 0;
                    }
                    a {
                        text-decoration: underline;
                        width: fit-content;
                    }
                    .input-group {
                        display: flex;
                        gap: 0.25rem;
                        .input-label {
                            a {
                                text-decoration: underline;
                            }
                        }
                        &.select {
                            flex-direction: column;
                            border: unset;
                        }
                        &.checkbox {
                            align-items: center;
                            gap: 1rem;
                            .input-label {
                                max-width: 80%;
                                display: flex;
                                gap: 0.25rem;
                            }
                        }
                        &.text-input {
                            flex-direction: column;
                            .generic-input,
                            .PhoneInputInput {
                                min-height: 38px;
                                padding: 2px 8px;
                                border-radius: 4px;
                                border: none;
                                &:active,
                                &:focus {
                                    outline-color: #2684ff;
                                }
                            }
                            .PhoneInput {
                                gap: 0.5rem;
                            }
                        }
                        &.rating {
                            flex-direction: column;
                            .rating-label {
                                font-weight: 700;
                            }
                            .rating-questions {
                                padding: 0 0.5rem;
                                display: flex;
                                flex-direction: column;
                                .rating-question-container {
                                    display: flex;
                                    flex-direction: column;
                                    .question-label {
                                        font-size: 14px;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                        .select-container {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            .select-group {
                                width: 100%;
                                border: unset;
                                border-radius: 4px;
                            }
                        }
                        .generic-input {
                            border: solid 1px #ddd;
                            &.warning {
                                border: 1px solid rgb(255, 0, 0);
                                box-shadow: 2px 2px 5px rgba(255, 0, 0, 0.5);
                            }
                        }
                        .input-tooltip {
                            font-size: 12px;
                            color: $liveto-red;
                        }
                    }
                }
                .header,
                .footer {
                    padding: 1rem;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .header {
                    border-bottom: 1px solid #ddd;
                    .title {
                        margin: 0;
                        font-size: 20px;
                        color: $liveto-text-gray;
                    }
                }
                .footer {
                    border-top: 1px solid #ddd;
                    .buttons {
                        display: flex;
                        gap: 0.5rem;
                    }
                    .error-msg {
                        color: rgba(255, 0, 0, 1);
                        font-weight: 700;
                    }
                }
            }
            .login-view {
                display: flex;
                gap: 10px;
                .event-type {
                    font-size: 20px;
                    margin: 0 0 0.5rem 0;
                }
                .guest-signin {
                    display: flex;
                    flex-direction: column;
                    border-radius: 5px;
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
                    background: rgba(255, 255, 255, 0.8);
                    padding: 1rem;
                    width: 300px;
                    align-items: center;
                    color: #555;
                    align-items: center;
                    justify-content: space-between;
                    gap: 0.5rem;
                    &-info {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .auth-type-guest {
                            font-size: 20px;
                            padding: 5px;
                        }
                        h1 {
                            font-size: 25px;
                        }
                    }

                    .social-signins {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        align-items: center;
                        justify-content: space-between;
                        gap: 0.5rem;
                        .guest-input {
                            padding: 5px 10px;
                            border: solid 1px #ddd;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }

                .auth-description {
                    font-size: 12px;
                    text-align: center;
                    font-style: italic;
                    max-width: 250px;
                }
                .auth-form {
                    display: flex;
                    flex-direction: column;
                    border-radius: 5px;
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
                    background: rgba(255, 255, 255, 0.8);
                    padding: 1rem;
                    width: 300px;
                    align-items: center;
                    color: #555;
                    gap: 0.5rem;
                    justify-content: space-between;

                    &-info {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    &-actions {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;
                        .auth-button {
                            width: 100%;
                        }
                        width: 100%;
                    }

                    .auth-group {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        .auth-label {
                            margin: 0;
                            font-weight: normal;
                            font-size: 12px;
                        }
                        .auth-input {
                            padding: 5px 10px;
                            width: 250px;
                            border: solid 1px #ddd;
                            width: 100%;
                        }
                    }
                    .button-row {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        gap: 0.5rem;
                        margin: 0.5rem 0;
                    }

                    .auth-button {
                        background-color: #d62b1e;
                        color: #fff;
                        border: none;
                        padding: 5px 10px;
                        border-radius: 5px;
                        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
                        width: 100%;
                        text-align: center;
                        &:disabled {
                            background: #ddd;
                        }
                    }
                }
            }
        }
    }
}
.auth-info-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.auth-info-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &-section {
        display: flex;
        flex-direction: column;
    }
}

.additional-links-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    button {
        color: $liveto-red;
        padding: 0;
        border: unset;
        background-color: transparent;
    }
}

@media screen and (max-width: 600px) {
    .authenticate-screen {
        height: 100%;
        overflow-y: scroll;
        min-height: 100dvh;
        .auth {
            flex-direction: column;
            width: calc(100% + 0.5rem);
            max-width: 400px;
            position: relative;
            padding: 0;
            margin-left: 0.5rem;
            .login-container {
                width: calc(100% - 10px);
                &.extra-form {
                    transform: translateX(calc(-50% - 0.5rem));
                }
                .login-view {
                    flex-direction: column;
                    width: 100%;
                    .auth-form {
                        width: 100%;
                    }
                    .guest-signin {
                        width: 100%;
                    }
                }
                .extra-field-form-container {
                    width: 100%;
                    height: 100%;
                    max-height: 100%;
                    .footer {
                        flex-direction: column;
                        gap: 1rem;
                        .buttons {
                            justify-content: flex-end;
                            width: 100%;
                        }
                    }
                }
            }
        }
        .event-info {
            position: static;
            top: 0;
            width: 100%;
            max-width: 400px;
            .event-name {
                font-size: 1.5rem;
                color: #000;
            }
        }
    }
}

.expandable-info-button {
    font-size: 12px;
    background-color: transparent;
    border: unset;
}
